import { datadogRum } from "@datadog/browser-rum";

/**
 * Logs custom event to Datadog RUM.
 *
 * @param {String} actionName name of the custom event
 * @param {Object} attributes additional attributes if needed for a custom event
 */
export const logDatadogEvent = (actionName, attributes = {}) => {
  if (!datadogRum || !actionName) {
    return;
  }
  datadogRum.addAction(actionName, attributes);
};

/**
 * Logs an error to Datadog RUM.
 *
 * @param {string|Error} error - The error message or Error object to log.
 * @param {Object} [context={}] - Additional context to include with the error.
 * @param {string} [source="custom"] - The source of the error (e.g., "custom", "network", "console").
 */
export const logDatadogError = (error, context = {}, source = "custom") => {
  if (!datadogRum) {
    console.warn("Datadog RUM is not initialized");
    return;
  }

  try {
    datadogRum.addError(error, context, source);
    console.info("Error logged to Datadog RUM:", { error, context, source });
  } catch (e) {
    console.error("Failed to log error to Datadog RUM:", e);
  }
};

/**
 * Sets a custom attribute that will be added to the datadog view
 *
 * @param {String} attributeName - name of the custom attribute
 * @param {String|Integer|Boolean} value - value of the custom attribute
 */
export const setDatadogViewAttribute = (attributeName, value) => {
  if (!datadogRum || !attributeName || !value) {
    return;
  }
  datadogRum.setViewContextProperty(attributeName, value?.toString() ?? value);
};
