export default Object.freeze({
  RECORDING_PARAMS: {
    SET: "set_recording",
    EDIT: "edit",
    RECORD: "record",
    RECORDING: "recording",
    RECORDINGS: "recordings",
    RECORD_SERIES: "record_series",
    EDIT_SERIES: "edit_series",
    EDIT_RECORDINGS: "edit_recordings",
    DELETE_RECORDING: "delete_recording",
    CANCEL: "cancel",
    CANCEL_REC: "cancel_rec",
    DELETE: "delete",
    EVENT: "event",
    EPISODE: "episode",
    KEEP_EPISODES: "keep_episodes",
    SERIES: "series",
    NO: "No",
    EDIT_REC: "action_edit_pvr_program",
    CONFLICT: "conflict",
    RESOLVE_CONFLICT: "resolve_conflict_for_me",
    DONT_RECORD: "dont_record",
    RECORDING_SET: "recording_set",
    RECORDING_NOT_SET: "recording_not_set",
    APPLY: "apply",
    KEEP_RECORDING: "keep_recording",
    CANCEL_RECORDING: "cancel_recording",
    CANCEL_SURE: "cancel_are_you_sure",
    RECORDING_CONFLICT: "recording_conflict",
    RECORDING_CANCELLED: "recording_cancelled",
    RECORDING_DELETED: "recording_deleted",
    COULD_NOT_DELETE_RECORDING: "could_not_delete_recording",
    SERIES_RECORDING_NOT_DELETED: "series_recording_cannot_be_deleted",
    COULD_NOT_CANCEL_RECORDING: "could_not_cancel_recording",
    RECORDING_CANCEL_MESSAGE: "cancel_recording_program",
    SERIES_CANCEL_MESSAGE: "cancel_recording_series",
    CANCEL_SERIES: "cancel_series",
    KEEP_SERIES: "keep_series",
    RECORDED: "recorded",
    SCHEDULED: "scheduled",
    NO_RECORDED_CONTENT: "no_recorded_content",
    MANAGE_RECORDINGS: "manage_recordings",
    ACTION_REQUIRED: "recordings_action_required",
    SERIES_TEXT: "series_text",
    OK: "ok",
    CONFLICT_SERIES: "recordings_conflicts_series",
    CONFLICT_MESSAGE: "recordings_conflicts_message",
    CONFLICT_EPISODES: "recordings_conflicts_episodes",
    CONFLICTS_RESCHEDULE: "recordings_conflicts_reschedule",
    ADDED_WAITLIST: "recordings_add_waitlist",
    RECORDINGS_HOURS: "recordings_40hours",
    PVR_SPACE: "recordings_pvr_space",
    RESTRICTION_OPTIK: "recordings_restriction_optik",
    LIMITATION: "recordings_limitation",
    RECORDINGS_WAITLIST: "recordings_waitlist",
    RECORDING_DELETE_MESSAGE: "delete_recording_confirmation",
    SERIES_RECORDING_SET: "series_recording_set",
    RECORDING_UPDATED: "recording_updated",
    SERIES_RECORDING_UPDATED: "series_recording_updated",
    SERIES_RECORDING_NOT_UPDATED: "series_recording_cannot_be_updated",
    SERIES_RECORDING_CANNOT_BE_UPDATED_FIRST_RUN: "series_recording_cannot_be_updated_first_run",
    RECORDING_NOT_UPDATED: "recording_not_updated",
    NEWEST_FIRST: "newest_first",
    OLDEST_FIRST: "oldest_first",
    A_Z: "alphabetical",
    Z_A: "alphabetical_reverse",
    SORT: "sort_by",
    FILTER: "filter_by",
    ALL: "all",
    REC_SERIES: "action_record_pvr_group",
    SERIES_RECORDING_CANCELLED: "series_recording_cancelled",
    SERIES_RECORDINGS_DELETED: "series_recordings_deleted",
    SINGLE_PROGRAMS: "single_programs",
    MOVIES: "parental_movies",
    PENDING: "pending",
    MR_GET_CALL_PARAMS: {
      MAX_ATTEMPTS: 3,
      ATTEMPT_DELAY_SECS: 4,
    },
  },
  RECORDING_SETTINGS: {
    KEEP_UNTIL: {
      KEY: "keepUntil",
      TITLE_KEY: "keep_until",
      OPTIONS: [
        { value: "true", label: "space_is_needed", desc: "space_needed_desc" },
        { value: "false", label: "recordings_conflicts_delete", desc: "prevents_recording" },
      ],
    },
    STOP_RECORDING: {
      KEY: "stopRecording",
      TITLE_KEY: "stop_recording",
      OPTIONS: [
        { value: 0, label: "stop_recording_at_scheduled_time" },
        { value: 300, label: "stop_recording_5_mins" },
        { value: 900, label: "stop_recording_15_mins" },
        { value: 1800, label: "stop_recording_30_mins" },
        { value: 3600, label: "stop_recording_1_hour" },
        { value: 7200, label: "stop_recording_2_hours" },
      ],
    },
    SHOW_TYPE: {
      KEY: "showType",
      TITLE_KEY: "show_type",
      MR_OPTIONS: [
        { value: "ALL", label: "showtype_first_runs_and_reruns" },
        { value: "FirstRun", label: "showtype_first_runs_only" },
      ],
      CPVR_OPTIONS: [
        { value: "ALL", label: "showtype_first_runs_and_reruns" },
        { value: "NEW", label: "showtype_first_runs_only" },
      ],
    },
    SHOW_TIME: {
      KEY: "time",
      TITLE_KEY: "time",
      OPTIONS: [
        { value: 1, label: "airtime_anytime" },
        { value: 0, label: "airtime_any_day_at_time" },
        { value: 2, label: "airtime_any_time_once_per_day" },
      ],
    },
    CHANNEL: {
      KEY: "channel",
      TITLE_KEY: "recordings_time_and_channel",
      TITLE_KEY_SERIES: "channel",
      OPTIONS: [],
    },
  },
  RECORDING_REFINEMENTS: {
    SORT_OPTIONS: [
      {
        key: "recordingSort",
        label: "sort_by",
        values: [
          { label: "newest_first", value: "" },
          { label: "oldest_first", value: "orderBy=startTime&sortOrder=asc" },
          { label: "alphabetical", value: "orderBy=title&sortOrder=asc" },
          { label: "alphabetical_reverse", value: "orderBy=title&sortOrder=desc" },
        ],
      },
    ],
    FILTER_OPTIONS: [
      {
        key: "recordingFilter",
        label: "filter_by",
        values: [
          { label: "all", value: "" },
          { label: "series", value: "filterBy=TVSHOW" },
          { label: "parental_movies", value: "filterBy=MOVIE" },
        ],
      },
    ],
  },
  MR_RECORDING_FILTER_OPTIONS: {
    PVR_MANAGER: {
      SCHEDULED_FILTERS: ["Scheduled", "Conflicted"],
      RECORDED_FILTERS: ["Recorded", "Recording"],
    },
    SCHEDULED_PAGE_FILTERS: ["Scheduled", "Conflicted", "Recording"],
    ALL_FILTERS: ["Recording", "Scheduled", "Conflicted", "Recorded", "Cancelled"],
  },
  MR_RECORDING_DEFINITION_STATUS: {
    INVALID: "Invalid",
    UNKNOWN_SCHEDULE: "UnknownSchedule",
    KNOWN_SCHEDULE: "KnownSchedule",
    TENTATIVE_SCHEDULE: "TentativeSchedule",
    CANCELLED: "Cancelled",
    COMPLETED: "Completed",
    DELETED: "Deleted",
  },
  MR_RECORDING_STATUS: {
    INVALID: "Invalid",
    CONFLICTED: "Conflicted",
    CANCELLED: "Cancelled",
    RECORDING: "Recording",
    SCHEDULED: "Scheduled",
    RECORDED: "Recorded",
    UNAVAILABLE: "Unavailable",
    DELETED: "Deleted",
  },
  CPVR_INTERNAL_RECORDING_STATUS: {
    SERIES_EPISODE_RECORDING_RECORDED: "series_episode_recorded",
    SERIES_EPISODE_RECORDING_IN_PROGRESS: "series_episode_recording_in_progress",
    SERIES_EPISODE_RECORDING_SCHEDULED: "series_episode_recording_scheduled",
    SERIES_RECORDING_SCHEDULED: "series_program_scheduled",
    EVENT_RECORDING_RECORDED: "event_recorded",
    EVENT_RECORDING_IN_PROGRESS: "event_recording_in_progress",
    EVENT_RECORDING_SCHEDULED: "event_scheduled",
    SERIES_DOES_NOT_EXIST: "series_does_not_exist",
    EVENT_DOES_NOT_EXIST: "event_does_not_exist",
  },
  CPVR_EXTERNAL_RECORDING_STATUS: {
    SCHEDULING: "Scheduling",
    RESCHEDULING: "Rescheduling",
    SCHEDULE_SUCCESS: "ScheduleSuccess",
    RESCHEDULE_SUCCESS: "RescheduleSuccess",
    RECORD_SUCCESS: "RecordSuccess",
    INCOMPLETE: "Incomplete",
    RECORD_FAILED: "RecordFailed",
    FAILED_NO_SPACE: "FailedDueToNoSpace",
    SCHEDULE_FAILED: "ScheduleFailed",
    SCHEDULE_CANCELLED: "ScheduleCancelled",
  },
  RECORDING_PACKAGES: {
    PACKAGE_TYPE: {
      NPVR: "NPVR",
    },
    PACKAGE_NAME: {
      LPVRMediaroom_TP: "LPVRMediaroom_TP",
      CPVR_TP: "CPVR_TP",
    },
  },
  RECORDING_FEATURE_NAMES: {
    CPVR: "CloudPVR",
    MR: "RemoteRecordings",
  },
  PVR_CAPABILITY: {
    NONE: "none",
    CPVR: "cpvr",
    LPVR: "lpvr",
  },
});
