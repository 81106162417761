import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PopUpOutline from "../PopUpOutline";
import ImageButton from "../ImageButton";
import { useTranslation } from "react-i18next";
import { getPurchaseTransactionType } from "../../shared/utils";
import { getFormattedPrice, getPackagePrice } from "../../shared/utils/feedHelper";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import constants from "../../shared/constants";
import { NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_PAGE_ACTIONS } from "../../shared/constants/datadog";
import { logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogEvent } from "../../shared/analytics/datadog";
import "./style.scss";

const { TRANSACTION_TYPES, DEFAULT_RENTAL_DURATION } = constants;

/**
 * Ways to order prompt component
 * @component
 * @param {Object} props
 */
const WaysToOrderPrompt = ({ orderOptions = [], onClosePrompt = () => {}, onOptionClick = () => {} }) => {
  const { t: translate } = useTranslation();
  const { appLanguage } = useAppLanguage();

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  return (
    <>
      <div className="ways-to-order-backdrop"></div>
      <div className="ways-to-order-prompt-container">
        <div className="ways-to-order-container">
          <PopUpOutline className="ways-to-order">
            <div className="ways-to-order-label-container">
              <div className="ways-to-order-label">{translate("order_options")}</div>
              <ImageButton
                src={process.env.PUBLIC_URL + "/images/playback-close.svg"}
                alt={translate("close")}
                onClickHandler={() => {
                  logNREvent(NR_PAGE_ACTIONS.PURCHASE_CANCELLED);
                  logDatadogEvent(DD_PAGE_ACTIONS.PURCHASE_CANCELLED);
                  onClosePrompt();
                }}
              />
            </div>
            {orderOptions.map((purchasePackage) => {
              const transactionType = getPurchaseTransactionType(purchasePackage.name);
              const isRentTransaction = transactionType === TRANSACTION_TYPES.RENT;
              const price = getPackagePrice(purchasePackage) ?? 0;
              return (
                <div
                  key={purchasePackage.id}
                  className="order-option-wrap"
                  onClick={() => onOptionClick(purchasePackage)}
                >
                  <div className="details-wrapper">
                    <div className="order-option-action">
                      {translate(isRentTransaction ? "rent_hd_stb" : "buy_hd_stb").replace(
                        "%s",
                        getFormattedPrice(price, appLanguage)
                      )}
                    </div>
                    <div className="order-option-description">
                      {isRentTransaction
                        ? translate("order_available_hours").replace("<duration>", DEFAULT_RENTAL_DURATION)
                        : translate("order_title")}
                    </div>
                  </div>
                  <div className="action-wrapper">
                    <ImageButton
                      src={process.env.PUBLIC_URL + "/images/order.svg"}
                      alt={translate(transactionType)}
                      testID="orderIcon"
                    />
                  </div>
                </div>
              );
            })}
          </PopUpOutline>
        </div>
      </div>
    </>
  );
};

WaysToOrderPrompt.propTypes = {
  orderOptions: PropTypes.array.isRequired,
  onClosePrompt: PropTypes.func.isRequired,
  onOptionClick: PropTypes.func.isRequired,
};

export default WaysToOrderPrompt;
