export const DD_PAGE_ACTIONS = Object.freeze({
  SEARCH: "search",
  GUIDE_SEARCH: "Guide filter",
  SWIMLANE_SEARCH: "view_all_filter",
  ERROR: "general_error",
  LOGIN: "login",
  LOGIN_START: "login_start",
  LOGIN_COMPLETE: "login_complete",
  LOGIN_ERROR: "login_error",
  EXIT_CLICK: "Exit link",
  ADD_TO_CART: "purchase_opened",
  PURCHASE: "purchase_success",
  PURCHASE_ERROR: "purchase_error",
  PURCHASE_CANCELLED: "purchase_cancelled",
  FAVOURITE: "add_favourite",
  UNFAVOURITE: "remove_favourite",
  TOGGLE_CC: "closed_caption_toggle",
  TOGGLE_DV: "described_video_toggle",
  SEASON_SELECT: "season_select",
  TRAILER_STARTED: "trailer_started",
  PLAY_START: "play_started",
  PLAY_END: "play_end",
  PLAY_ERROR: "play_error",
  RECORDING_START: "RecordingStart",
  RECORDING_STOP: "RecordingStop",
  RECORDING_DELETE: "RecordingDelete",
  RECORDING_EDIT: "RecordingEdit",
  RECORDING_FILTER: "RecordingFilter",
  RECORDING_SORT: "RecordingSort",
});

export const DD_CUSTOM_ATTRIBUTES = Object.freeze({
  IS_LOGGED_IN: "isLoggedIn",
  IS_IN_HOME: "isInHome",
  SESSION_ID: "telusSessionId",
  DEVICE_ID: "telusDeviceId",
  UUID: "uuid",
  PROGRAM_NAME: "programName",
  SERIES_NAME: "seriesName",
  TOGGLE_ON: "on",
  USER_ID: "userId",
  CUSTOMER_TYPE: "customerType",
  DRM_TOKEN: "authToken",
  LICENSE_URL: "licenseURL",
  PVR_CAPABILITY: "pvrCapability",
});
