import {
  setRecordings,
  getRecordings,
  deleteRecordings,
  getRecordingsBookmarks,
} from "../../../shared/middleware/recordings";

// As of Sept 2, 2022, setRequestLoading is only necessary for the loading spinner on the Recordings page and its used to determine
// if we've refreshed the recordingsList
import { setRequestLoading } from "../../../App/state/actions";
import recordingConstants from "../../../shared/constants/recordingConstants";
import moment from "moment";
import errors from "../../../shared/constants/error";
import { sleep } from "../../../shared/utils";
import { getFeatureProperties } from "../../../shared/utils";
import { logNREvent } from "../../../shared/analytics/newRelic";
import { logDatadogEvent } from "../../../shared/analytics/datadog";
import { NR_PAGE_ACTIONS } from "../../../shared/constants/newRelic";
import { DD_PAGE_ACTIONS } from "../../../shared/constants/datadog";

const { RECORDING_PARAMS, MR_RECORDING_STATUS, MR_RECORDING_DEFINITION_STATUS, CPVR_EXTERNAL_RECORDING_STATUS } =
  recordingConstants;
const { AVS_ERROR_CODES } = errors;

export const SET_RECORDINGS = "SET_RECORDINGS";
export const GET_RECORDINGS = "GET_RECORDINGS";
export const CONFLICT_RECORDINGS = "CONFLICT_RECORDINGS";
export const DELETE_RECORDINGS = "DELETE_RECORDINGS";
export const EDIT_RECORDINGS = "EDIT_RECORDINGS";
export const TOGGLE_SETTINGS_PANEL = "TOGGLE_SETTINGS_PANEL";
export const MANIPULATE_RECORDING_ACTION_TRIGGERED = "MANIPULATE_RECORDING_ACTION_TRIGGERED";
export const GET_RECORDINGS_BOOKMARKS = "GET_RECORDINGS_BOOKMARKS";

function setRecordingSuccess(content, recordingParams) {
  return { type: SET_RECORDINGS, content: { content, recordingParams } };
}
function setRecordingFailure(error) {
  return { type: SET_RECORDINGS, content: error };
}
function getRecordingSuccess(content) {
  return { type: GET_RECORDINGS, content };
}
function getRecordingFailure(error) {
  return { type: GET_RECORDINGS, error };
}
function editRecordingSuccess(content, recordingParams) {
  return { type: EDIT_RECORDINGS, content: { content, recordingParams } };
}
function editRecordingFailure(error) {
  return { type: EDIT_RECORDINGS, content: error };
}
function deleteRecordingSuccess(content, recordingParams) {
  return { type: DELETE_RECORDINGS, content: { content, recordingParams } };
}
function deleteRecordingFailure(error) {
  return { type: DELETE_RECORDINGS, content: error };
}
function getRecordingsBookmarkSuccess(content) {
  return { type: GET_RECORDINGS_BOOKMARKS, content };
}

function getRecordingsBookmarkFailure(error) {
  return { type: GET_RECORDINGS_BOOKMARKS, error };
}

export function toggleSettingsPanelAction(toggle) {
  return { type: TOGGLE_SETTINGS_PANEL, toggle };
}

/**
 * This state object is used to keep track of the selected asset, API params, and recording information for
 * create, update, and delete actions. This helps us provide the appropriate UI feedback for users such
 * as loading spinners and toast notifications.
 *
 * @param {String} actionType - Recording CrUD action type. Can be "set", "edit", or "delete"
 * @param {Object} targetAssetInfo - Dynamic object with target program/recording asset information
 * @param {String} recordingType - Type of recording (event or series)
 */
function manipulateActionTriggered(actionType, targetAssetInfo, recordingType) {
  return {
    type: MANIPULATE_RECORDING_ACTION_TRIGGERED,
    recordingParams: { actionType, targetAssetInfo, recordingType },
  };
}

/**
 * @param {Object} assetToRecord - The program used to schedule the recording
 * @param {Boolean} isCPVREdit - If true, the action is being used as part of the CPVR edit flow, not the schedule flow
 * Refer to setRecordings() in shared/middleware/recordings.js for the other input parameter documentation
 */
export const setRecordingAction = (appProvider, isMR, recordingParams, assetToRecord, isCPVREdit = false) => {
  return async (dispatch) => {
    if (!isCPVREdit) dispatch(manipulateActionTriggered("set", assetToRecord, recordingParams.recordingType));
    try {
      const feedContent = await setRecordings(appProvider, isMR, recordingParams);
      if (feedContent) {
        if (isCPVREdit) {
          dispatch(editRecordingSuccess(feedContent, recordingParams));
        } else {
          dispatch(setRecordingSuccess(feedContent, recordingParams));
        }
      }
    } catch (error) {
      if (isCPVREdit) {
        if (
          recordingParams.recordingType === RECORDING_PARAMS.SERIES &&
          recordingParams["episodeScope"] === "NEW" &&
          error.code === AVS_ERROR_CODES.SERIES_METADATA_NOT_FOUND_ON_NPVR
        ) {
          recordingParams["episodeScope"] = "ALL"; // We reset the episodeScope to ALL
          try {
            // Then we try to set new series recording with episodeScope set to ALL - restore user's deleted series recording
            await setRecordings(appProvider, false, recordingParams);
            console.error("Error occurred while trying to edit a recording. Error = ", error);
          } catch (resetSeriesError) {
            // Note: We are intentionally dispatching error and not resetSeriesError when this scenario occurs to trigger the series metadata not found toast notification (we still log
            // the resetSeriesError to help with debugging)
            console.error(
              "Error occurred while trying to reset the series recording episodeScope to 'ALL' resulting in the series recording being deleted. Error = ",
              resetSeriesError
            );
          }
        } else {
          console.error("Error occurred while trying to edit a recording. Error = ", error);
        }
        dispatch(editRecordingFailure(error));
      } else {
        console.error("Error occurred while trying to set a recording. Error = ", error);
        dispatch(setRecordingFailure(error));
      }
      dispatch(setRequestLoading(false));
    }
  };
};

/**
 * @param {Object} recordingPayload - set/edit recording action payload used for MR
 * For other details, refer to getRecordings() in shared/middleware/recordings.js for input parameter documentation
 */
export const getRecordingAction = (
  appProvider,
  isMR,
  includeMetadata = true,
  queryParams,
  recordingTypes = [RECORDING_PARAMS.EVENT],
  mrFilterParams,
  recordingPayload
) => {
  return async (dispatch) => {
    dispatch(setRequestLoading(true));
    if (isMR) {
      // MR GET strategy is determined via a feature toggle property
      const useFilteredGet = getFeatureProperties(appProvider, "RemoteRecordings")?.useFilteredGet ?? false;
      let getAttemptCount = 0;
      const getMRRecordings = async () => {
        let content = [];
        if (useFilteredGet) {
          // the filtered strategy makes several API calls using various status filters and combines the results
          const filterParams = mrFilterParams?.slice();
          let firstResult;
          let foundRecording = -1;
          if (filterParams.length) {
            foundRecording = filterParams.indexOf(MR_RECORDING_STATUS.RECORDING);
            const firstPromise = getRecordings(
              appProvider,
              true,
              false,
              null,
              queryParams,
              foundRecording !== -1 ? MR_RECORDING_STATUS.RECORDING : filterParams.shift()
            );
            if (foundRecording !== -1) {
              filterParams.splice(foundRecording, 1);
            }
            firstResult = await Promise.allSettled([firstPromise]);
          }

          await sleep(appProvider.config.LPVR_DELAY_GET ?? 4000);

          const apiPromises = filterParams?.map((filterParam) => {
            if (filterParam !== MR_RECORDING_STATUS.RECORDING) {
              return getRecordings(appProvider, true, false, null, queryParams, filterParam);
            }
          });

          let results = await Promise.allSettled(apiPromises);
          if (firstResult) {
            results = results.concat(firstResult);
          }

          for (let i = 0; i < results.length; i++) {
            if (results[i].status === "fulfilled") {
              content = content.concat(results[i].value.recordingDefinitions || []);
            } else {
              console.error("Error occurred while trying to get MR recordings. Error = ", results[i].reason);
              // If one call fails, consider it a complete failure
              content = results[i].reason;
              break;
            }
          }

          if (!content.code) {
            /**
             * Since we are making multiple GET calls for getting Scheduled, Conflicted etc. recordings
             * There might be instances when the recording with the same 'recordingDefinitionId' comes up twice once in Scheduled call and once in Conflicted
             * So to club all the recordings inside a single object and to keep the 'recordingDefinitionId' unique we are using this method
             */
            const definitionMap = new Map();
            content.forEach((definition) => {
              const existingDefinition = definitionMap.get(definition.recordingDefinitionId);
              if (existingDefinition) {
                definitionMap.set(definition.recordingDefinitionId, {
                  ...existingDefinition,
                  recordings: existingDefinition.recordings.concat(definition.recordings),
                });
              } else {
                definitionMap.set(definition.recordingDefinitionId, definition);
              }
            });

            content = Array.from(definitionMap, ([key, value]) => value); // Since 'value' is the 2nd argument.
          }
        } else {
          // the unfiltered strategy makes one API call (filters are still supported via the queryParams, but not mandatory)
          const result = await getRecordings(appProvider, true, false, null, queryParams);
          content = result?.recordingDefinitions;
        }

        if (content && !content.code) {
          const filteredDefinitions = content
            .filter((definition) => {
              return !(
                definition.status === MR_RECORDING_DEFINITION_STATUS.INVALID ||
                definition.status === MR_RECORDING_DEFINITION_STATUS.DELETED ||
                definition.status === MR_RECORDING_DEFINITION_STATUS.UNKNOWN_SCHEDULE
              );
            })
            .map((definition) => {
              const filteredRecordings = definition.recordings.filter((recording) => {
                return !(
                  recording.status === MR_RECORDING_STATUS.INVALID ||
                  recording.status === MR_RECORDING_STATUS.DELETED ||
                  recording.status === MR_RECORDING_STATUS.UNAVAILABLE ||
                  recording.status === MR_RECORDING_STATUS.CANCELLED
                );
              });

              return { ...definition, recordings: filteredRecordings };
            });

          content = filteredDefinitions;

          if (recordingPayload) {
            const isAfterSetCall = !recordingPayload.recordId && !recordingPayload.action;
            const isAfterEditCall = recordingPayload.recordId && !recordingPayload.action;
            const isAfterCancelCall = recordingPayload.action === RECORDING_PARAMS.CANCEL;
            const isAfterDeleteCall = recordingPayload.action === RECORDING_PARAMS.DELETE;
            let assetExistsAfterSet = false,
              isAssetUpdatedAfterEdit = false,
              isAssetCancelledAfterCancel = false,
              isAssetRemovedAfterDelete = false;

            // [July 26, 2022] The error code values used below aren't important, but the presence of a code is required to indicate an error
            if (isAfterSetCall) {
              assetExistsAfterSet = content.some((definition) => {
                return (
                  definition.title === recordingPayload.title &&
                  definition.channelId === recordingPayload.channelId &&
                  definition.utcStartTime === recordingPayload.programStartTime &&
                  definition.recordingDefinitionStatus === MR_RECORDING_DEFINITION_STATUS.KNOWN_SCHEDULE &&
                  definition.recordingType.toLowerCase() === recordingPayload.recordingType &&
                  definition.recordings?.length > 0
                );
              });

              if (!assetExistsAfterSet) {
                if (getAttemptCount < RECORDING_PARAMS.MR_GET_CALL_PARAMS.MAX_ATTEMPTS - 1) {
                  content = [];
                } else {
                  console.error(
                    "Error occurred while trying to schedule a MR recording. Error = The set call succeeded but the recording is not present in the get response."
                  );
                  content = {
                    code: "MR_SET_FAIL",
                  };
                }
              }
            } else if (isAfterEditCall) {
              isAssetUpdatedAfterEdit = content.some((recording) => {
                if (recordingPayload.recordId === recording.recordingDefinitionId && recording.recordings?.[0]) {
                  let returnValue =
                    recordingPayload.isAutoDeletionEnabled === recording.isAutoDeletionEnabled &&
                    recordingPayload.postPadding === recording.recordings[0].postPadding &&
                    recordingPayload.channelId === recording.channelId;

                  if (recordingPayload.recordingType === RECORDING_PARAMS.SERIES) {
                    return (
                      returnValue &&
                      recording.recordings[0].schedulingTime === recordingPayload.schedulingTime &&
                      recording.recordings[0].episodeScope === recordingPayload.episodeScope
                    );
                  } else {
                    return (
                      returnValue && recordingPayload.programStartTime === recording.recordings[0].programStartTime
                    );
                  }
                } else {
                  return (
                    recording.recordings?.length > 0 &&
                    recording.recordings.some((response) => {
                      return (
                        response.recordGUID === recordingPayload.recordId &&
                        response.isAutoDeletionEnabled === recordingPayload.isAutoDeletionEnabled &&
                        response.postPadding === recordingPayload.postPadding
                      );
                    })
                  );
                }
              });

              if (!isAssetUpdatedAfterEdit) {
                if (getAttemptCount < RECORDING_PARAMS.MR_GET_CALL_PARAMS.MAX_ATTEMPTS - 1) {
                  content = [];
                } else if (!recordingPayload.updatedToFirstRun) {
                  // If a series recording episode scope is updated to FirstRun and the series doesn't have any new episodes airing,
                  // the existing scheduled episode recordings will be put into a Deleted state and no new episode recordings will be
                  // scheduled. We don't have any way to identify that the series doesn't have any new episodes to display a proper
                  // message to the user, but as per TCDWC-1519, we don't want to consider this as an edit failure
                  console.error(
                    "Error occurred while trying to edit a MR recording. Error = The edit call succeeded but the recording in the get response has not been updated."
                  );
                  content = {
                    code: "MR_EDIT_FAIL",
                  };
                }
              }
            } else if (isAfterCancelCall) {
              const recordingDefinition = content.find((definition) => {
                return definition.recordingDefinitionId === recordingPayload.recordingInfo.recordingDefinitionId;
              });

              if (
                recordingDefinition?.recordingDefinitionStatus === MR_RECORDING_DEFINITION_STATUS.CANCELLED ||
                recordingDefinition?.recordingDefinitionStatus === MR_RECORDING_DEFINITION_STATUS.DELETED
              ) {
                isAssetCancelledAfterCancel = true;
              } else {
                if (recordingDefinition?.recordings?.length > 0) {
                  if (recordingPayload.recordingType === RECORDING_PARAMS.EVENT) {
                    // if the recording definition isn't cancelled, we need to check for the individual recording we intended to cancel
                    // if it exists and its status isn't "Cancelled", consider the cancel a failure
                    const recordId = recordingPayload.recordingInfo.isProgramOfSeries
                      ? recordingPayload.recordingInfo.recordGUID
                      : recordingPayload.recordings[0].recordingGuid;

                    isAssetCancelledAfterCancel = !recordingDefinition.recordings.find((recording) => {
                      return recording.recordGUID === recordId && recording.status !== MR_RECORDING_STATUS.CANCELLED;
                    });
                  }
                } else {
                  // If using the filtered GET strategy, the recording definition should be missing from our combined list of definitions
                  isAssetCancelledAfterCancel = useFilteredGet;
                }
              }

              if (!isAssetCancelledAfterCancel) {
                if (getAttemptCount < RECORDING_PARAMS.MR_GET_CALL_PARAMS.MAX_ATTEMPTS - 1) {
                  content = [];
                } else {
                  console.error(
                    "Error occurred while trying to cancel a MR recording. Error = The cancel call succeeded but the recording is not cancelled in the get response."
                  );
                  content = {
                    code: "MR_CANCEL_FAIL",
                  };
                }
              }
            } else if (isAfterDeleteCall) {
              const recordingDefinition = content.find((definition) => {
                return (
                  definition.recordingDefinitionId === recordingPayload.recordingInfo.recordingDefinitionId &&
                  definition.recordingDefinitionStatus !== MR_RECORDING_DEFINITION_STATUS.DELETED
                );
              });

              if (recordingDefinition?.recordings?.length > 0) {
                if (recordingPayload.recordingType === RECORDING_PARAMS.EVENT) {
                  // if the recording definition still exists, we need to check for the individual recording we intended to delete
                  // if it exists and its status isn't "Deleted", consider the delete a failure
                  const recordId = recordingPayload.recordingInfo.isProgramOfSeries
                    ? recordingPayload.recordingInfo.recordGUID
                    : recordingPayload.recordings[0].recordingGuid;

                  isAssetRemovedAfterDelete = !recordingDefinition.recordings.find((recording) => {
                    return recording.recordGUID === recordId && recording.status !== MR_RECORDING_STATUS.DELETED;
                  });
                } else {
                  // for series recording deletions, we need to check that all of the recordings that we wanted to delete were deleted
                  const deletedRecordingIds = new Set(
                    recordingPayload.recordings.map((recording) => recording.recordGuid)
                  );

                  let deletedRecordingFound = false;
                  for (let i = 0; i < recordingDefinition.recordings.length; i++) {
                    if (
                      deletedRecordingIds.has(recordingDefinition.recordings[i].recordingGUID) &&
                      recordingDefinition.recordings[i].status !== MR_RECORDING_STATUS.DELETED
                    ) {
                      deletedRecordingFound = true;
                      break;
                    }
                  }

                  isAssetRemovedAfterDelete = !deletedRecordingFound;
                }
              } else {
                // recording definition not found or found without any recordings, assuming this means the delete was a success
                isAssetRemovedAfterDelete = true;
              }

              if (!isAssetRemovedAfterDelete) {
                if (getAttemptCount < RECORDING_PARAMS.MR_GET_CALL_PARAMS.MAX_ATTEMPTS - 1) {
                  content = [];
                } else {
                  console.error(
                    "Error occurred while trying to delete a MR recording. Error = The delete call succeeded but the recording is still present in the get response."
                  );
                  content = {
                    code: "MR_DELETE_FAIL",
                  };
                }
              }
            }
          }
        }

        return content;
      };

      let recordingsMR = await getMRRecordings();

      if (recordingsMR.length === 0 && recordingPayload) {
        // Error or pending state
        let zeroTimestamp = moment();
        while (recordingsMR.length === 0 && getAttemptCount < RECORDING_PARAMS.MR_GET_CALL_PARAMS.MAX_ATTEMPTS) {
          const timeDiff = moment().diff(zeroTimestamp, "seconds");
          if (timeDiff === RECORDING_PARAMS.MR_GET_CALL_PARAMS.ATTEMPT_DELAY_SECS) {
            recordingsMR = await getMRRecordings();
            getAttemptCount++;
            zeroTimestamp = moment();
          }
        }
      }

      if (recordingsMR.code) {
        // Some error occurred
        dispatch(getRecordingFailure(recordingsMR));
      } else {
        dispatch(getRecordingSuccess(recordingsMR));
      }
      dispatch(setRequestLoading(false));
    } else {
      const pageSize = 50; // Apparently 50 is the maximum page size
      // Create promises for each recording type provided, where each promise is responsible for fetching all pages of recording data for its
      // specific recording type
      const apiPromises = recordingTypes.map((recordingType) => {
        return new Promise(async (resolve, reject) => {
          let containers = [],
            pageNumber = 0,
            containersTotal = -1,
            error;
          let queryParamsByType = queryParams?.[recordingType] ? queryParams[recordingType] : queryParams;

          // Fetch all pages of recordings. Not ideal for every situation, but can be refactored for certain situations only in the future
          while (containersTotal === -1 || containersTotal === pageSize) {
            try {
              const newStartIndex = pageNumber * pageSize;
              const newQueryParams = {
                ...queryParamsByType,
                from: newStartIndex,
                to: newStartIndex + pageSize - 1,
              };

              const feedContent = await getRecordings(
                appProvider,
                false,
                includeMetadata,
                recordingType,
                newQueryParams
              );
              if (feedContent) {
                if ((feedContent.total || feedContent.total === 0) && feedContent.containers) {
                  let filteredContainers = feedContent.containers;
                  if (recordingType === RECORDING_PARAMS.EVENT) {
                    filteredContainers = feedContent.containers.filter((recording) => {
                      // Filtering out any recordings that failed or were cancelled by the backend for whatever reason (such as channels being disabled)
                      const masterAsset = recording.assets?.find((asset) => asset.assetType === "MASTER");
                      return (
                        masterAsset &&
                        !(
                          masterAsset.status === CPVR_EXTERNAL_RECORDING_STATUS.RECORD_FAILED ||
                          masterAsset.status === CPVR_EXTERNAL_RECORDING_STATUS.FAILED_NO_SPACE ||
                          masterAsset.status === CPVR_EXTERNAL_RECORDING_STATUS.SCHEDULE_FAILED ||
                          masterAsset.status === CPVR_EXTERNAL_RECORDING_STATUS.SCHEDULE_CANCELLED
                        )
                      );
                    });
                  }
                  containers = containers.concat(filteredContainers);
                  // containersTotal is used to determine whether or not to fetch another page of data, so it should not reference the filtered container list
                  containersTotal = feedContent.containers.length;
                  pageNumber++;
                } else {
                  if (!feedContent.total) {
                    throw new Error("total is missing from GET recordings response");
                  } else {
                    throw new Error("containers is missing from GET recordings response");
                  }
                }
              } else {
                throw new Error("No response from GET recordings call");
              }
            } catch (e) {
              error = e;
              break;
            }
          }

          if (error) {
            reject(error);
          } else {
            resolve(containers);
          }
        });
      });

      // For now, assuming that if any promise fails, we consider it a complete failure to fetch recordings
      // We can enhance this in the future when there is time
      Promise.all(apiPromises)
        .then((results) => {
          // NOTE: We don't do any extra client side validation for CPVR like we do for MR, so if the server tells us a set/edit/delete/cancel action
          // was successful, then we're telling the user it was successful. If it turns out that we need extra validation that the backend will not be doing,
          // we should implement it here in a way similar to MR above (using internal errors for the sake of toast notifications)
          const feedContent = [];
          for (let i = 0; i < results.length; i++) {
            feedContent.push({ containers: results[i], recordingType: recordingTypes[i] });
          }
          dispatch(getRecordingSuccess(feedContent));
          dispatch(setRequestLoading(false));
        })
        .catch((error) => {
          console.error("Error occurred while trying to get CPVR recordings. Error = ", error);
          dispatch(getRecordingFailure(error));
          dispatch(setRequestLoading(false));
        });
    }
  };
};

/**
 * @param {Object} CPVREditParams - API request inputs necessary to "edit" a CPVR recording, since for CPVR we need to delete the existing recording and create
 *                                  a new one with the updated recording settings. If not provided, this action is treated as a normal delete action.
 * Refer to deleteRecordings() in shared/middleware/recordings.js for the other input parameter documentation
 */
export const deleteRecordingAction = (appProvider, isMR, recordingParams, CPVREditParams) => {
  return async (dispatch) => {
    dispatch(setRequestLoading(true));
    if (CPVREditParams) {
      dispatch(manipulateActionTriggered("edit", CPVREditParams.assetToRecord, recordingParams.recordingType));
    } else {
      const recordingInfo = isMR ? recordingParams.recordingInfo.recordingInfo : recordingParams.recordingInfo;
      dispatch(
        manipulateActionTriggered("delete", recordingInfo.assetToRecord || recordingInfo, recordingParams.recordingType)
      );
    }
    try {
      if (CPVREditParams?.assetToRecord?.onlyEpisodeScopeUpdated) {
        // TCDWC-2896: If only episode scope is updated we do not need to perform DELETE + POST
        CPVREditParams.setRecordingAction(appProvider, false, CPVREditParams, CPVREditParams.assetToRecord, true);
      } else {
        const feedContent = await deleteRecordings(appProvider, isMR, recordingParams);
        if (feedContent) {
          if (CPVREditParams) {
            CPVREditParams.setRecordingAction(appProvider, false, CPVREditParams, CPVREditParams.assetToRecord, true);
          } else {
            logNREvent(NR_PAGE_ACTIONS.RECORDING_DELETE);
            logDatadogEvent(DD_PAGE_ACTIONS.RECORDING_DELETE);
            dispatch(deleteRecordingSuccess(feedContent, recordingParams));
          }
        }
      }
    } catch (error) {
      if (CPVREditParams) {
        console.error("Error occurred while trying to edit a recording. Error = ", error);
        dispatch(editRecordingFailure(error));
      } else {
        console.error("Error occurred while trying to delete/cancel a recording. Error = ", error);
        dispatch(deleteRecordingFailure(error));
      }
      dispatch(setRequestLoading(false));
    }
  };
};

/**
 * @param {Boolean} isCPVRCancel - Flag indicating if this edit action is being used to cancel a CPVR recording
 * Refer to setRecordings() in shared/middleware/recordings.js for the other input parameter documentation
 */
export const editRecordingAction = (appProvider, isMR, recordingParams, isCPVRCancel = false) => {
  return async (dispatch) => {
    dispatch(setRequestLoading(true));
    if (isCPVRCancel) {
      dispatch(
        manipulateActionTriggered(
          "delete",
          recordingParams.recordingInfo.assetToRecord || recordingParams.recordingInfo,
          recordingParams.recordingType
        )
      );
    } else {
      dispatch(
        manipulateActionTriggered(
          "edit",
          recordingParams.recordingInfo.assetToRecord,
          isMR && recordingParams.isProgramOfSeries ? RECORDING_PARAMS.EVENT : recordingParams.recordingType
        )
      );
    }

    try {
      const feedContent = await setRecordings(appProvider, isMR, recordingParams);
      if (feedContent) {
        if (isCPVRCancel) {
          logNREvent(NR_PAGE_ACTIONS.RECORDING_DELETE);
          logDatadogEvent(DD_PAGE_ACTIONS.RECORDING_DELETE);
          dispatch(deleteRecordingSuccess(feedContent, recordingParams));
        } else {
          dispatch(editRecordingSuccess(feedContent, recordingParams));
        }
      }
      return feedContent;
    } catch (error) {
      if (isCPVRCancel) {
        console.error("Error occurred while trying to cancel a recording. Error = ", error);
        dispatch(deleteRecordingFailure(error));
      } else {
        console.error("Error occurred while trying to edit a recording. Error = ", error);
        dispatch(editRecordingFailure(error));
      }
      dispatch(setRequestLoading(false));
    }
  };
};

export const loadRecordingsBookmark = (appProvider) => {
  return async (dispatch) => {
    try {
      const bookmarks = await getRecordingsBookmarks(appProvider);
      if (bookmarks) {
        dispatch(getRecordingsBookmarkSuccess(bookmarks));
      }
    } catch (error) {
      console.error("error", error);
      dispatch(getRecordingsBookmarkFailure(error));
    }
  };
};
