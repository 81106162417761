import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import "./style.scss";

import CheckBox from "../../components/CheckBox";
import RadioButton from "../../components/RadioButton";
import Button from "../../components/Button";
import { getLocalStorage, setLocalStorage } from "../../shared/utils/localStorage";
import constants from "../../shared/constants";
import routes from "../../shared/constants/routes";
import SeoPageTags from "../../components/SeoPageTags";
import middleware from "../../shared/middleware";
import { showModalPopup } from "../../App/state/actions";
import errorConstants from "../../shared/constants/error";
import { trackWebAction, trackGenericAction } from "../../shared/analytics/dataLayer";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import { PIN_MODAL_TYPES } from "../../components/PinModal";
import {
  ANALYTICS_EVENT_TYPES,
  ACTION_VALUES,
  ANALYTICS_STORAGE_KEYS,
  LINK_INFO,
  ANALYTICS_ERROR_NAMES,
  ANALYTICS_ERROR_INFO,
} from "../../shared/constants/analytics";
import { capitalize } from "../../shared/utils";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import { logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogEvent } from "../../shared/analytics/datadog";
import { NR_CUSTOM_ATTRIBUTES, NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_CUSTOM_ATTRIBUTES, DD_PAGE_ACTIONS } from "../../shared/constants/datadog";
import useAppLanguage from "../../shared/hooks/useAppLanguage";

const { USER_PROPERTY_NAMES } = constants;
const { MODAL_TYPES } = constants;

const { SETTINGS_CONFIG } = constants;
const { BASICS, ACCOUNT, SETTING_DETAIL, PARENTAL_PIN, PURCHASE_PIN } = routes;
const { ERROR_TYPES } = errorConstants;
const { logout } = middleware;

const viewAllIcon = `${process.env.PUBLIC_URL}/images/white-chevron.svg`;
const infoIcon = `${process.env.PUBLIC_URL}/images/info-icon-green.svg`;
const externalIcon = `${process.env.PUBLIC_URL}/images/external-icon.svg`;

/**
 * Settings page component
 * @component
 * @param {object} props
 */
function SettingsPage(props) {
  const [isAuthUser, setIsAuthUser] = useState(false);
  const { i18n, t: translate } = useTranslation();
  const { trackPageView } = useTrackPageView();
  const { isAppLanguageFrench, appLanguage } = useAppLanguage();
  const [language, setLanguage] = useState(appLanguage); // value is either en or fr

  const history = useHistory();
  const { userProfile, appProvider, showModalPopup, featureToggles } = props;
  const { isUserProfilesEnabled, isPurchasePINEnabled, isParentalPINEnabled } = featureToggles;
  const isMasterProfile = userProfile?.user?.profile?.profileData?.isMasterAccount === "Y";
  const isKidsProfile = isUserProfilesEnabled && userProfile?.user?.profile?.profileData?.kidsProfile;
  const isSplus = appProvider.userPropertyName === USER_PROPERTY_NAMES.SPLUS;

  const [caption, setCaption] = useState(getLocalStorage(SETTINGS_CONFIG.CLOSED_CAPTION));
  const [described, setDescribed] = useState(getLocalStorage(SETTINGS_CONFIG.DESCRIBED_VIDEO));

  const [PCFeatureEnabled, setPCFeatureEnabled] = useState(false);
  const [purchasePinFeatureEnabled, setPurchasePinFeatureEnabled] = useState(false);
  const [isPCPinEnabled, setIsPCPinEnabled] = useState(false);
  const [isPurchasePinEnabled, setIsPurchasePinEnabled] = useState(false);

  useEffect(() => {
    setLocalStorage(SETTINGS_CONFIG.CLOSED_CAPTION, caption);
  }, [caption]);

  useEffect(() => {
    setLocalStorage(SETTINGS_CONFIG.DESCRIBED_VIDEO, described);
  }, [described]);

  useEffect(() => {
    let isLoggedIn = false,
      isPCPinEnabled = false,
      isPurchasePinEnabled = false;
    if (userProfile) {
      isLoggedIn = userProfile.isLoggedIn;
      const userProfileData = userProfile.user?.profile?.profileData;
      if (userProfileData) {
        isPCPinEnabled = userProfileData.parentalControlPinEnabled === "Y";
        isPurchasePinEnabled = userProfileData.purchasePinEnabled === "Y";
      }
    }
    setIsAuthUser(isLoggedIn);
    setIsPCPinEnabled(isPCPinEnabled);
    setIsPurchasePinEnabled(isPurchasePinEnabled);
  }, [userProfile, userProfile.isLoggedIn]);

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  useEffect(() => {
    setPCFeatureEnabled(isParentalPINEnabled);
    setPurchasePinFeatureEnabled(isPurchasePINEnabled);
  }, [isParentalPINEnabled, isPurchasePINEnabled]);

  const changeLanguage = () => {
    trackWebAction(
      ANALYTICS_EVENT_TYPES.SETTINGS_INTERACT,
      isAppLanguageFrench ? ACTION_VALUES.SETTINGS_TOGGLE_LANG_EN : ACTION_VALUES.SETTINGS_TOGGLE_LANG_FR
    );
    window.location.reload();
    const newLanguage = isAppLanguageFrench ? "en" : "fr";
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  const closeCaptionHandler = () => {
    trackWebAction(
      ANALYTICS_EVENT_TYPES.SETTINGS_INTERACT,
      caption ? ACTION_VALUES.SETTINGS_TOGGLE_CC_OFF : ACTION_VALUES.SETTINGS_TOGGLE_CC_ON
    );
    logNREvent(NR_PAGE_ACTIONS.TOGGLE_CC, { [NR_CUSTOM_ATTRIBUTES.TOGGLE_ON]: !caption });
    logDatadogEvent(DD_PAGE_ACTIONS.TOGGLE_CC, { [DD_CUSTOM_ATTRIBUTES.TOGGLE_ON]: !caption });
    setCaption(!caption);
  };

  const describedVideoHandler = () => {
    trackWebAction(
      ANALYTICS_EVENT_TYPES.SETTINGS_INTERACT,
      described ? ACTION_VALUES.SETTINGS_TOGGLE_DV_OFF : ACTION_VALUES.SETTINGS_TOGGLE_DV_ON
    );
    logNREvent(NR_PAGE_ACTIONS.TOGGLE_DV, { [NR_CUSTOM_ATTRIBUTES.TOGGLE_ON]: !described });
    logDatadogEvent(DD_PAGE_ACTIONS.TOGGLE_DV, { [DD_CUSTOM_ATTRIBUTES.TOGGLE_ON]: !described });
    setDescribed(!described);
  };
  const logoutClickHandler = () => {
    if (isAuthUser) {
      setIsAuthUser(false);
      trackWebAction(ANALYTICS_EVENT_TYPES.LOGOUT);
      logout(appProvider).catch((err) => {
        trackWebAction(ANALYTICS_EVENT_TYPES.ERROR, {
          name: ANALYTICS_ERROR_NAMES.SERVER_ERROR,
          details: ANALYTICS_ERROR_INFO.LOGOUT_FAILURE,
          message: err.message,
          code: err.code,
        });
        setIsAuthUser(true);
        console.error(err);
        showModalPopup(MODAL_TYPES.ERROR, {
          title: ERROR_TYPES.ERROR,
          message: "error_logout_message",
          isCloseable: true,
        });
      });
    }
  };

  /**
   * Opens appropriate page component depending on pinType.
   * @param {String} pinType
   */
  const openPINPage = (pinType) => {
    if (pinType === PIN_MODAL_TYPES.PARENTAL) {
      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.PARENTAL_PIN};${LINK_INFO.SETTINGS}`);
      history.push(PARENTAL_PIN.route);
    } else {
      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.PURCHASE_PIN};${LINK_INFO.SETTINGS}`);
      history.push(PURCHASE_PIN.route);
    }
  };

  const getPINHint = (isEnabled) => {
    return isEnabled ? translate("on") : translate("off");
  };

  return (
    <React.Fragment>
      <SeoPageTags title={translate("settings")} keywords={["optik", "telus"]} />
      <div className="settings-page">
        <div className="settings-heading">
          <h1 className="settings-label">{translate("settings")}</h1>
          {!appProvider.panicMode && !isUserProfilesEnabled && userProfile?.isLoggedIn && (
            <Button
              label={translate("logout")}
              buttonStyles="settings-logout-button"
              onClickHandler={logoutClickHandler}
            />
          )}
        </div>
        {appProvider.panicMode && (
          <div id="serviceStatusRow" className="settings-row">
            <div id="serviceTitle" className="settings-options">
              {translate("service_status")}
            </div>
            <div id="serviceMessageContainer">
              <div id="status-message">
                <div className="panic-indicator" />
                <span>{translate("temporary_service_disruption")}</span>
              </div>
              <div className="status-description">{translate("service_status_body")}</div>
            </div>
          </div>
        )}
        {isAuthUser && (
          <div className="settings-row">
            <div className="settings-options">{translate("settings_accessibility")}</div>
            <div>
              <div className="settings-option-detail text-white">
                <CheckBox
                  title={translate("settings_closed_captioning")}
                  checked={caption}
                  onChangeHandler={closeCaptionHandler}
                />
              </div>
              <div className="settings-option-detail text-white">
                <CheckBox
                  title={translate("settings_described_video")}
                  checked={described}
                  onChangeHandler={describedVideoHandler}
                />
              </div>
            </div>
          </div>
        )}

        {isAuthUser && (PCFeatureEnabled || purchasePinFeatureEnabled) ? (
          <div className="settings-row">
            <div className="settings-options">{translate("security")}</div>
            <div>
              {PCFeatureEnabled && !isKidsProfile ? (
                <div className="security-option">
                  <div
                    className="set-pin-container"
                    onClick={() => {
                      if (isMasterProfile) {
                        openPINPage(PIN_MODAL_TYPES.PARENTAL);
                      } else {
                        showModalPopup(MODAL_TYPES.ERROR, {
                          className: "pin-info-modal",
                          title: "parental_pin",
                          message: "pin_profile_parental",
                          isCloseable: true,
                          endButtonLabelOverride: translate("close"),
                          endButtonClassOverride: "error-modal-btn-gray",
                        });
                      }
                    }}
                  >
                    <div className="text-white union-icon">
                      {translate("parental_pin")}
                      {isMasterProfile ? (
                        <img src={viewAllIcon} alt="" className="ml-10" />
                      ) : (
                        <img src={infoIcon} alt="" className="info-icon" />
                      )}
                    </div>
                    <div className="hint-text">{capitalize(getPINHint(isPCPinEnabled))}</div>
                  </div>
                </div>
              ) : null}
              {purchasePinFeatureEnabled ? (
                <div className="security-option">
                  <div
                    className="set-pin-container"
                    onClick={() => {
                      if (isMasterProfile) {
                        openPINPage(PIN_MODAL_TYPES.PURCHASE);
                      } else {
                        showModalPopup(MODAL_TYPES.ERROR, {
                          className: "pin-info-modal",
                          title: "purchase_pin",
                          message: "purchase_lock_for_standard_&_kid",
                          isCloseable: true,
                          endButtonLabelOverride: translate("close"),
                          endButtonClassOverride: "error-modal-btn-gray",
                        });
                      }
                    }}
                  >
                    <div className="text-white union-icon">
                      {translate("purchase_pin")}
                      {isMasterProfile ? (
                        <img src={viewAllIcon} alt="" className="ml-10" />
                      ) : (
                        <img src={infoIcon} alt="" className="info-icon" />
                      )}
                    </div>
                    <div className="hint-text">{capitalize(getPINHint(isPurchasePinEnabled))}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className="settings-row">
          <div className="settings-options">{translate("language")}</div>
          <div>
            <div className="settings-option-detail text-white d-inline-block">
              <RadioButton
                title={translate("english_app_language")}
                checked={language === "en"}
                onChangeHandler={changeLanguage}
                className="settings-radio-container"
              />
            </div>
            <div className="settings-option-detail text-white d-inline-block">
              <RadioButton
                title={translate("french_app_language")}
                checked={language === "fr"}
                onChangeHandler={changeLanguage}
                className="settings-radio-container"
              />
            </div>
          </div>
        </div>

        {!isKidsProfile && (
          <>
            <div className="settings-row">
              <div className="settings-options">{translate("tutorials_help")}</div>
              <div>
                <div className="settings-option-detail">
                  <Link
                    to={BASICS.route}
                    className="supportLink basicLink text-white cursor"
                    onClick={() => {
                      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.BASICS};${LINK_INFO.SETTINGS}`);
                    }}
                  >
                    {translate("settings_basics")}
                    <img src={viewAllIcon} alt="" className="ml-10" />
                  </Link>
                </div>
                <div className="settings-option-detail">
                  <a
                    className="supportLink settingsPageSupport union-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(event) =>
                      trackGenericAction(ANALYTICS_EVENT_TYPES.EXIT_CLICK, {
                        URL: event.currentTarget.href,
                        name: translate("optik_support_text"),
                      })
                    }
                    href={
                      isSplus
                        ? getSettingsLinkByCategory(props.appProvider, "StreamPlusSupport", isAppLanguageFrench)
                        : getSettingsLinkByCategory(props.appProvider, "support", isAppLanguageFrench)
                    }
                  >
                    {translate("optik_support_text")}
                    <img src={externalIcon} alt="" className="external-icon" />
                  </a>
                </div>
              </div>
            </div>

            <div className="settings-row">
              <div className="settings-options">{translate("settings_about")}</div>
              <div>
                <div className="settings-option-detail">
                  <Link
                    to={SETTING_DETAIL.route}
                    className="text-white cursor"
                    onClick={() => {
                      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.APP_INFO};${LINK_INFO.SETTINGS}`);
                    }}
                  >
                    {translate("app_version_info")}
                    <img src={viewAllIcon} alt="" className="ml-10" />
                  </Link>
                </div>
                {isAuthUser && (
                  <div className="settings-option-detail">
                    <Link
                      to={ACCOUNT.route}
                      className="text-white cursor"
                      onClick={() => {
                        setSessionStorage(
                          ANALYTICS_STORAGE_KEYS.LINK,
                          `${LINK_INFO.ACCOUNT_INFO};${LINK_INFO.SETTINGS}`
                        );
                      }}
                    >
                      {translate("settings_account_info")}
                      <img src={viewAllIcon} alt="" className="ml-10" />
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="settings-row">
              <div className="settings-options">{translate("legal_privacy")}</div>
              <div>
                <div className="settings-option-detail">
                  <a
                    className="union-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(event) =>
                      trackGenericAction(ANALYTICS_EVENT_TYPES.EXIT_CLICK, {
                        URL: event.currentTarget.href,
                        name: translate("optik_privacy_text"),
                      })
                    }
                    href={getSettingsLinkByCategory(props.appProvider, "legal", isAppLanguageFrench)}
                  >
                    {translate("optik_privacy_text")}
                    <img src={externalIcon} alt="" className="external-icon" />
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
}

function mapStateToProps({ app }) {
  return {
    userProfile: app.userProfile,
    appProvider: app.provider,
    featureToggles: app.featureToggles,
  };
}

const mapDispatchToProps = {
  showModalPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);

function getSettingsLinkByCategory(appProvider, category, isAppLanguageFrench) {
  if (appProvider?.config?.settings?.length > 0) {
    const settingObj = appProvider.config.settings.find((setting) => {
      return setting.name.toLowerCase() === category.toLowerCase();
    });
    if (settingObj) {
      return isAppLanguageFrench ? settingObj.value_fr : settingObj.value;
    }
  }
  return "";
}
