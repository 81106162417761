import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  loadSimilarItems,
  LOAD_SIMILAR_SERIES,
  LOAD_PROGRAM_DETAILS,
  loadPlayOptions,
  LOAD_PLAY_OPTIONS,
  LOAD_SERIES_CONTENT,
  loadSeriesContent,
  loadSchedules,
  LOAD_EPISODE_SCHEDULES,
  loadEpisodeDetailItems,
  LOAD_EPISODE_PAGE_CONTAINERS,
  LOAD_ON_DEMAND_OPTIONS,
  LOAD_LIST_OF_TEAMS,
} from "./state/actions";
import {
  resetAction,
  loadChannels,
  showToastNotification,
  loadUserSubscribedChannels,
  showModalPopup,
  showRecordingSettingsPanel,
  setConvivaContentSubType,
} from "../../App/state/actions";
import OptikButton from "../../components/OptikButton";
import constants from "../../shared/constants/index";
import recordingConstants from "../../shared/constants/recordingConstants";
import errors from "../../shared/constants/error";
import Swimlane from "../../components/Swimlane";
import swimlaneConstants from "../../shared/constants/swimlane";
import SwimlaneItem from "../../components/SwimlaneItem";
import CastSwimlaneItem from "../../components/SwimlaneItem/CastSwimlaneItem";
import Schedules from "../../components/Schedules";
import PurchaseModal from "../../components/PurchaseModal";
import OrderCTA from "../../components/OrderCTA";
import WaysToOrderPrompt from "../../components/WaysToOrderPrompt";
import { PIN_MODAL_MODES, PIN_MODAL_TYPES } from "../../components/PinModal";
import WaysToWatchPrompt from "../../components/WaysToWatchPrompt";
import { useTranslation } from "react-i18next";
import createSwimlaneModels from "../../shared/utils/dataFilters";
import { getAVSKeyArtImage, getAVSPosterArtImage, handleImageError } from "../../shared/utils/image";
import {
  getEpisodeInfoLine1,
  getItemLiveAvailability,
  getAssetExpiryString,
  prioritizeTvodCompareFunction,
  retrieveMasterAsset,
  getFirstInHomeLiveSchedule,
  getFirstOutOfHomeLiveSchedule,
  getPurchaseOptions,
} from "../../shared/utils/feedHelper";
import {
  getRecordingCTAs,
  getRecordingInfo,
  getRecordingSystemType,
  setRecording,
  getCPVRRecordingStatus,
  doesCPVRSeriesRecordingExist,
  doesCPVREventRecordingExist,
  isCPVRRecordingInProgress,
  getRecordablePrograms,
  navigateToPVRManager,
  showCPVRConflictModal,
  checkCPVRConflicts,
  isRecordingPendingStateCheck,
} from "../../shared/utils/recordingHelper";
import { showPurchaseAcknowledgement } from "../PlayerPage/state/actions";
import {
  setRecordingAction,
  getRecordingAction,
  toggleSettingsPanelAction,
  SET_RECORDINGS,
  GET_RECORDINGS,
  EDIT_RECORDINGS,
  DELETE_RECORDINGS,
  MANIPULATE_RECORDING_ACTION_TRIGGERED,
} from "../RecordingsPage/state/actions";
import BackButton from "../../components/ImageButton/index";

import routeConstants from "../../shared/constants/routes";
import SeoPageTags from "../../components/SeoPageTags";
import RecordingModal from "../../components/RecordingModal";
import middleware from "../../shared/middleware";

import "./style.scss";
import {
  convertToPercentageString,
  isEllipsisActive,
  getPcLevelRestriction,
  calculateLookbackHoursLeft,
  getNewestLookbackProgram,
  checkIfAnyScheduleIsSubscribed,
  getAutoGeneratedObject,
  getAutogeneratedEpisodeString,
  isPinMaxLimitReach,
} from "../../shared/utils";
import ImageButton from "../../components/ImageButton";
import playerConstants from "../../shared/constants/player";
import { handleLogin } from "../../shared/utils/login";
import { getRegionalChannelNumber } from "../../shared/utils/epg";
import { trackGenericAction, trackRecordingError } from "../../shared/analytics/dataLayer";
import { getWatchOptions, getSeriesInfo, getLookbackAvailabilityString } from "../../shared/utils/feedHelper";
import {
  getSwimlaneRowNum,
  getEpisodeInfo,
  getAAVideoPlayType,
  getGenericErrorEventHandler,
  mapRecordingSeriesMediaContent,
} from "../../shared/analytics/helpers";
import { setSessionStorage } from "../../shared/utils/sessionStorage";
import moment from "moment";
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_STORAGE_KEYS,
  LINK_INFO,
  EXTRA_METADATA_TYPES,
  ACTION_VALUES,
  WEB_ACTION_EVENT_NAMES,
  MAPPED_CONTENT_TYPES,
} from "../../shared/constants/analytics";
import { useConvivaContentSubType } from "../../shared/hooks/useConvivaContentSubType";
import Wall from "../../components/Wall";
import { setNRAttribute, logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogEvent, setDatadogViewAttribute } from "../../shared/analytics/datadog";
import { NR_CUSTOM_ATTRIBUTES, NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_CUSTOM_ATTRIBUTES, DD_PAGE_ACTIONS } from "../../shared/constants/datadog";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import useCancelTokenSource from "../../shared/hooks/useCancelTokenSource";
import useTrackPageView from "../../shared/hooks/useTrackPageView";
import usePlaybackChecks from "../../shared/hooks/usePlaybackChecks";
import useDetailsPageCompositor from "../../shared/hooks/useDetailsPageCompositor";
import { getCustomContextMetadata, trackConvivaCustomEvent } from "../../shared/analytics/media";

const outHomeIcon = process.env.PUBLIC_URL + "/images/Out_of_home.svg";
const unSubscribedIcon = process.env.PUBLIC_URL + "/images/key-icon.svg";
const playIcon = process.env.PUBLIC_URL + "/images/play-icon.svg";
const VIEW_ALL_ICON = process.env.PUBLIC_URL + "/images/white-chevron.svg";
const icon = process.env.PUBLIC_URL + "/images/swimlane-landscape-324px.jpg";
const {
  IMAGES,
  CONTENT_ITEM_TYPES,
  WATCH_OPTIONS,
  PAGE_CONTENT_ITEM_TYPES,
  CONTAINER_TYPES,
  CONTAINER_LAYOUTS,
  ACTION_KEYS,
  MODAL_TYPES,
  UA_STANDALONE,
  LOGIN_BRANDS,
  SPORTEVENT,
} = constants;
const { RECORDING_PARAMS, RECORDING_PACKAGES, MR_RECORDING_FILTER_OPTIONS } = recordingConstants;
const { ITEM_TYPES, SWIMLANE_TITLES } = swimlaneConstants;
const { SIMILAR_ITEMS, ON_DEMAND_PLAYER, LIVE_PLAYER } = routeConstants;
const { ASSET_TYPES, PLAYBACK_TYPES } = playerConstants;
const { AVS_ERROR_CODES } = errors;
const { makePurchase, getContentUserData } = middleware;
/**
 * Episode Detail page component
 *
 * @component
 * @param {Object} props
 */

function EpisodeDetailPage(props) {
  const {
    userProfile,
    appProvider,
    resetAction,
    similarItems,
    loadSimilarItems,
    loadEpisodeDetailItems,
    channelMapInfo,
    loadChannels,
    showToastNotification,
    loadSeriesContent,
    isInHome,
    contentUserData,
    loadPlayOptions,
    onDemandOptions,
    subscribedChannels,
    loadUserSubscribedChannels,
    loadSchedules,
    schedules,
    showPurchaseAcknowledgement,
    showModalPopup,
    setRecordingAction,
    getRecordingAction,
    showRecordingSettingsPanel,
    setConvivaContentSubType,
    totalSimilarItems,
    toggleSettingsPanelAction,
    recordingsList,
    getRecordingError,
    setRecordingResponse,
    editRecordingResponse,
    deleteRecordingResponse,
    manipulatedRecordingParams,
    featureToggles,
    listOfTeams,
  } = props;
  const match = useRouteMatch();
  const { t: translate } = useTranslation();
  const { isAppLanguageFrench } = useAppLanguage();
  const {
    isLookbackEnabled,
    isRecordingEnabled,
    isRecommendationMLTEnabled: isRecommendationEnabled,
    isUserProfilesEnabled,
    isParentalPINEnabled,
    isConvivaAppTrackerEnabled,
    isKoodoLoginEnabled,
  } = featureToggles;

  const cancelTokenSource = useCancelTokenSource(); // cancelTokenSource ref for requests unmount clean up
  const { performPlaybackChecks } = usePlaybackChecks();
  const { contentMetadata, isUnifiedAsset } = useDetailsPageCompositor(CONTENT_ITEM_TYPES.episode);

  const [catalogInfo, setCatalogInfo] = useState(null);
  const [progressTime, setProgressTime] = useState(null);
  const [castRoles, setCastRoles] = useState(null);
  const [loadComponent, setLoadComponent] = useState(true);
  const [stationId, setStationId] = useState(0);
  const [isItemLive, setIsItemLive] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [purchasePackages, setPurchasePackages] = useState(null);
  const [selectedPurchasePackage, setSelectedPurchasePackage] = useState(null);
  const [showWaysToOrder, setShowWaysToOrder] = useState(null);
  const [showRecordingModal, setShowRecordingModal] = useState(false);
  const [watchOptions, setWatchOptions] = useState(null);
  const [isAssetRecordable, setIsAssetRecordable] = useState(false);
  const [playbackId, setPlaybackId] = useState(0);
  const [hasCC, setHasCC] = useState(null);
  const [hasDV, setHasDV] = useState(null);
  const [hasNew, setHasNew] = useState(null);
  const [seriesInfo, setSeriesInfo] = useState(null);
  const [isPlayableOnDemand, setIsPlayableOnDemand] = useState(null);
  const [isPlayableLive, setIsPlayableLive] = useState(null);
  const [viewAllUrl, setViewAllUrl] = useState("");
  const productDetails = useRef(null);
  const isSubscribed = useRef(null);
  const [recordingSystemType, setRecordingSystemType] = useState(null);
  const [selectedAssetRecordingInfo, setSelectedAssetRecordingInfo] = useState(null);
  const [showWaysToWatch, setShowWaysToWatch] = useState(false);
  const [svodWatchOptions, setSvodWatchOptions] = useState(null);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [recordingActionError, setRecordingActionError] = useState(null);
  const isRecordingActionInProgress = useRef(false);
  const convivaContSubType = useRef(useConvivaContentSubType(setConvivaContentSubType));
  const regionID = appProvider?.channelMapID;
  const recordingEventTypeRef = useRef(null);
  const recordingEventCallbackFuncRef = useRef(null);
  const areGenreBasedSimilarItemsFetched = useRef(false); // ref to check if call was made to fetch similar items using client(genre-based) logic
  const contentMetadataId = useRef(null);
  const isLiveProgramOOH = useRef(false); // ref to check for OOH restricted live program for primary(watch live) CTA

  const {
    params: { uriType, uriSubType, itemType, contentId, preferredScheduleId },
  } = match;
  const detailUri = `/${uriType}/${uriSubType}/${itemType}/${contentId}`;
  const contentType = itemType;
  const isUserLoggedIn = userProfile?.isLoggedIn ? true : false;
  const { trackPageView, resetIsPageViewTracked } = useTrackPageView();
  const lookbackProgram = useMemo(() => {
    return (isLookbackEnabled && getNewestLookbackProgram(schedules, subscribedChannels, isInHome, regionID)) || null;
  }, [isInHome, isLookbackEnabled, schedules, subscribedChannels, regionID]);
  const lookbackHoursLeft = useMemo(
    () => (lookbackProgram ? calculateLookbackHoursLeft(lookbackProgram) : 0),
    [lookbackProgram]
  );

  /** isMR - type: boolean - check if a login user has Media Room recording package, this constant is crucial for recording functions*/
  const isMR = recordingSystemType === RECORDING_PACKAGES.PACKAGE_NAME.LPVRMediaroom_TP;

  const mediaNodeMetaData = useMemo(() => {
    return {
      isItemLive: false,
      mappedContentType: MAPPED_CONTENT_TYPES.TVOD,
      ...contentMetadata,
    };
  }, [contentMetadata]);

  const currentAndUpcomingSchedules = useMemo(() => {
    if (schedules?.length) {
      const currentTime = moment().valueOf();
      return schedules.filter((schedule) => {
        return schedule.metadata.airingEndTime >= currentTime;
      });
    }
  }, [schedules]);

  /** we need to create a recordable program list from schedules, this list can be used on recording functions */
  const recordablePrograms = useMemo(() => {
    if (isRecordingEnabled && currentAndUpcomingSchedules && subscribedChannels) {
      return getRecordablePrograms(currentAndUpcomingSchedules, subscribedChannels);
    }
  }, [currentAndUpcomingSchedules, isRecordingEnabled, subscribedChannels]);

  const pcLevel = getPcLevelRestriction(userProfile, isUserProfilesEnabled, isParentalPINEnabled);

  // list of recording info objects for every program schedule that the user is subscribed to and is airing on a recordable channel
  const programsRecordingInfoList = useMemo(() => {
    if (recordingsList && recordablePrograms) {
      return recordablePrograms.map((schedule) => {
        return getRecordingInfo(isMR, schedule, recordingsList, regionID);
      });
    }

    return null;
  }, [recordingsList, isMR, recordablePrograms, regionID]);

  // Recording info that is associated with the primary recording button
  const primaryRecordingInfo = useMemo(() => {
    if (programsRecordingInfoList) {
      let preferredScheduleRecordingInfo;
      if (preferredScheduleId) {
        preferredScheduleRecordingInfo = programsRecordingInfoList.find(
          (recordingInfo) => recordingInfo.assetToRecord.id === preferredScheduleId
        );
      }

      // Try to find the first schedule that has a recording associated with it already
      let firstExistingRecordingAsset;
      if (isMR) {
        firstExistingRecordingAsset = programsRecordingInfoList.find((recordingInfo) => {
          return (
            recordingInfo.recordingEventScheduledCheck ||
            recordingInfo.recordingSeriesScheduledCheck ||
            recordingInfo.recordingEventConflictCheck ||
            recordingInfo.recordingSeriesConflictCheck
          );
        });
      } else {
        firstExistingRecordingAsset = programsRecordingInfoList.find((recordingInfo) => {
          const assetRecordingStatus = getCPVRRecordingStatus(recordingInfo);
          return (
            doesCPVREventRecordingExist(assetRecordingStatus) || doesCPVRSeriesRecordingExist(assetRecordingStatus)
          );
        });
      }

      return firstExistingRecordingAsset || preferredScheduleRecordingInfo || programsRecordingInfoList[0];
    }

    return null;
  }, [isMR, preferredScheduleId, programsRecordingInfoList]);

  // Used to display the recording now badge as appropriate
  const isRecordingNow = useMemo(() => {
    let result = false;
    if (primaryRecordingInfo) {
      if (isMR) {
        result =
          primaryRecordingInfo.recordingNowEventCheck || primaryRecordingInfo.recordingNowSeriesCheck ? true : false;
      } else {
        result = isCPVRRecordingInProgress(getCPVRRecordingStatus(primaryRecordingInfo));
      }
    }

    return result;
  }, [isMR, primaryRecordingInfo]);

  // Primary recording button CTA details
  const primaryRecordingCTA = useMemo(() => {
    if (primaryRecordingInfo) {
      if (isMR) {
        let isSeriesRecordingSet = false,
          isEventRecordingSet = false;
        if (primaryRecordingInfo.recordingSeriesScheduledCheck) {
          isSeriesRecordingSet = true;
        } else if (primaryRecordingInfo.recordingEventScheduledCheck) {
          isEventRecordingSet = true;
        }

        return getRecordingCTAs(
          true,
          primaryRecordingInfo,
          recordablePrograms,
          primaryRecordingInfo.recordingEventConflictCheck,
          isEventRecordingSet,
          primaryRecordingInfo.recordingSeriesConflictCheck,
          isSeriesRecordingSet
        );
      } else {
        return getRecordingCTAs(false, primaryRecordingInfo, recordablePrograms);
      }
    }
    return null;
  }, [isMR, primaryRecordingInfo, recordablePrograms]);

  const uaEpisodeObject = catalogInfo && getAutoGeneratedObject(catalogInfo);
  const isStandAloneEpisode = catalogInfo?.extendedMetadata?.dlum?.layoutHint === UA_STANDALONE;
  const isSportsEventPage = contentType === SPORTEVENT;

  // Used to display the loading spinner on the primary recording button, as appropriate
  const showRecordingCTALoading =
    manipulatedRecordingParams &&
    primaryRecordingInfo &&
    manipulatedRecordingParams.targetAssetInfo.id === primaryRecordingInfo.assetToRecord.id;

  // Reset the recordings params associated with an outgoing recording manipulation action (set, edit, delete)
  const resetManipulatedRecordingParams = useCallback(() => {
    resetAction(MANIPULATE_RECORDING_ACTION_TRIGGERED, "recordingParams");
  }, [resetAction]);

  // Reset the response data from setting a recording
  const resetSetRecordingResponse = useCallback(() => {
    resetAction(SET_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response error from fetching recordings
  const resetGetRecordingError = useCallback(() => {
    resetAction(GET_RECORDINGS, "error");
  }, [resetAction]);

  // Reset the response data from editing a recording
  const resetEditRecordingResponse = useCallback(() => {
    resetAction(EDIT_RECORDINGS, "content");
  }, [resetAction]);

  // Reset the response data from deleting a recording
  const resetDeleteRecordingResponse = useCallback(() => {
    resetAction(DELETE_RECORDINGS, "content");
  }, [resetAction]);

  /**
   * Resets the values used to support recording CRUD action flows
   */
  const resetRecordingActionValues = useCallback(() => {
    isRecordingActionInProgress.current = false;
    resetSetRecordingResponse();
    resetEditRecordingResponse();
    resetDeleteRecordingResponse();
    resetManipulatedRecordingParams();
    resetGetRecordingError();
    setRecordingActionError(null);
  }, [
    resetDeleteRecordingResponse,
    resetEditRecordingResponse,
    resetGetRecordingError,
    resetManipulatedRecordingParams,
    resetSetRecordingResponse,
  ]);

  /**
   * Determines the appropriate recording toast message to display
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {String} actionType - type of recording action that occurred (set/edit/delete)
   * @param {String} recordingType - type of the recording that is associated with the action
   * @returns {String} recordings-specific toast message
   */
  const getRecordingToastMessage = useCallback(
    (recordingInfo, actionType, recordingType) => {
      let toastMsg;
      if (recordingInfo.code) {
        if (actionType === "set") {
          toastMsg = translate(RECORDING_PARAMS.RECORDING_NOT_SET);
        } else if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_NOT_UPDATED" : "RECORDING_NOT_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          toastMsg = translate(RECORDING_PARAMS.COULD_NOT_CANCEL_RECORDING);
        }
      } else {
        if (actionType === "set") {
          toastMsg = translate(
            RECORDING_PARAMS[recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_SET" : "RECORDING_SET"]
          );
        } else if (actionType === "edit") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_UPDATED" : "RECORDING_UPDATED"
            ]
          );
        } else if (actionType === "delete") {
          toastMsg = translate(
            RECORDING_PARAMS[
              recordingType === RECORDING_PARAMS.SERIES ? "SERIES_RECORDING_CANCELLED" : "RECORDING_CANCELLED"
            ]
          );
        }
      }

      return toastMsg;
    },
    [translate]
  );

  /**
   * Triggers a recording analytics event based on a toast message passed
   * @param {String} toastMsg
   */
  const triggerRecordingAnalyticsEvent = useCallback(
    (toastMsg) => {
      if (recordingEventTypeRef.current) {
        const isSeriesEvent = toastMsg === translate(RECORDING_PARAMS.SERIES_RECORDING_SET);
        if (isSeriesEvent || toastMsg === translate(RECORDING_PARAMS.RECORDING_SET)) {
          const selectedAssetSchedule = {
            ...selectedAssetRecordingInfo?.assetToRecord,
            channelNumber: getRegionalChannelNumber(
              selectedAssetRecordingInfo?.assetToRecord.channel,
              appProvider?.channelMapID
            ),
          };
          trackGenericAction(recordingEventTypeRef.current, {
            isSeriesEvent,
            isItemLive: true,
            ...(isSeriesEvent ? mapRecordingSeriesMediaContent(selectedAssetSchedule) : selectedAssetSchedule),
          });
          logNREvent(NR_PAGE_ACTIONS.RECORDING_START);
          logDatadogEvent(DD_PAGE_ACTIONS.RECORDING_START);
        } else if (
          toastMsg === translate(RECORDING_PARAMS.RECORDING_UPDATED) ||
          toastMsg === translate(RECORDING_PARAMS.SERIES_RECORDING_UPDATED)
        ) {
          logNREvent(NR_PAGE_ACTIONS.RECORDING_EDIT);
          logDatadogEvent(DD_PAGE_ACTIONS.RECORDING_EDIT);
          recordingEventCallbackFuncRef.current();
        } else {
          trackRecordingError(recordingEventTypeRef.current, toastMsg, {
            errorCode: setRecordingResponse?.code,
            errorMessage: setRecordingResponse?.message,
          });
        }
      }
    },
    [setRecordingResponse, selectedAssetRecordingInfo, translate, appProvider]
  );

  /**
   * Displays the appropriate recording toast message (if any)
   *
   * @param {Object} recordingInfo - recording information used to determine the toast message
   * @param {Object} manipulatedRecordingParams - refer to manipulateActionTriggered() in RecordingsPage/state/actions.js
   */
  const displayRecordingToast = useCallback(
    (recordingInfo, manipulatedRecordingParams) => {
      if (recordingInfo && manipulatedRecordingParams) {
        const toastMsg = getRecordingToastMessage(
          recordingInfo,
          manipulatedRecordingParams.actionType,
          manipulatedRecordingParams.recordingType
        );
        if (toastMsg) {
          showToastNotification(toastMsg);
          triggerRecordingAnalyticsEvent(toastMsg);
          recordingEventTypeRef.current = null;
        }
      }
    },
    [getRecordingToastMessage, showToastNotification, triggerRecordingAnalyticsEvent]
  );

  const featurePlayAvailability = useCallback(
    (preferredOnDemandOption) => {
      if (watchOptions?.length > 0) {
        let firstPlayableAsset;
        if (preferredOnDemandOption) {
          const preferredWatchOption = watchOptions.find(
            (watchOption) => watchOption?.contentId === preferredOnDemandOption.id
          );
          if (
            preferredWatchOption?.assetType?.toLowerCase() === ASSET_TYPES.MASTER &&
            preferredWatchOption?.rights?.toLowerCase() === WATCH_OPTIONS.watch
          ) {
            firstPlayableAsset = preferredWatchOption;
          }
          if (firstPlayableAsset) {
            setShowPlayButton(true);
            setPlaybackId(firstPlayableAsset.contentId);
            convivaContSubType.current.setPlaybackId(firstPlayableAsset.contentId);
            return true;
          } else {
            firstPlayableAsset = retrieveMasterAsset(watchOptions, WATCH_OPTIONS.watch);
            if (firstPlayableAsset) {
              !showPlayButton && setShowPlayButton(true);
              !playbackId && setPlaybackId(firstPlayableAsset.contentId);
              return true;
            }
          }
        } else {
          firstPlayableAsset = retrieveMasterAsset(watchOptions, WATCH_OPTIONS.watch);
          if (firstPlayableAsset) {
            !showPlayButton && setShowPlayButton(true);
            !playbackId && setPlaybackId(firstPlayableAsset.contentId);
            return true;
          }
        }
      } else {
        return false;
      }
    },
    [playbackId, showPlayButton, watchOptions]
  );

  /**
   * A callback function which will be triggered after a recording has been updated
   * @param {Object} recordingItem
   * @param {Boolean} isItemLive
   * @param {Boolean} isSeriesEvent
   * @param {String} mappedContentType live|svod|tvod|cpvr|lpvr
   * @param {String} analyticsEventType analytics event type
   * @param {String} toolSelections a toolSelection string with format: 'key:{selection},key:{selection}...'
   * @param {Object} error an error object
   * @param {Object} recordingInfo
   */
  const trackRecordingUpdateEventCallback = useCallback(
    ({
      recordingItem,
      isItemLive,
      isSeriesEvent,
      mappedContentType,
      analyticsEventType,
      toolSelections,
      error,
      recordingInfo,
    }) => {
      recordingEventCallbackFuncRef.current = () => {
        trackGenericAction(analyticsEventType, {
          isItemLive,
          isSeriesEvent,
          toolSelections,
          mappedContentType,
          ...(isSeriesEvent
            ? mapRecordingSeriesMediaContent(recordingItem || primaryRecordingInfo.assetToRecord)
            : recordingInfo?.assetToRecord || recordingItem),
        });
      };
      if (error) {
        // Triggers a stop|delete recording error event.
        // The error of start recording event comes from setRecordingResponse data which is handled via useEffect.
        trackRecordingError(analyticsEventType, error?.message, {
          errorCode: error?.code,
        });
      } else {
        if (
          analyticsEventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_COMPLETE ||
          analyticsEventType === ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_COMPLETE
        ) {
          // At this moment, the result of editing a recording is unknown, but a toastMessage will show up on the screen according to the result of editing a recording.
          // Thus set the recordingEventType here, and then trigger the event based on the toastMessage later.
          recordingEventTypeRef.current = analyticsEventType;
        } else {
          // The result of stop|delete a recording is ready at this moment
          recordingEventCallbackFuncRef.current();
        }
      }
    },
    [primaryRecordingInfo]
  );

  /**
   * Opens the modal to cancel in progress episode recordings. Currently used for CPVR only.
   *
   * @param {Object} recordingInfo Recording information
   * @param {Object} programDetails Program information
   */
  const openCancelRecordingModal = useCallback(
    (recordingInfo, programDetails) => {
      const modalContent = {
        recordingInfo,
        programDetails,
        isRecordingRecorded: false,
        recordingType: RECORDING_PARAMS.EVENT,
        isMR: false,
      };
      showModalPopup(MODAL_TYPES.RECORDING, modalContent);
    },
    [showModalPopup]
  );

  const showRecordingSettingHandler = useCallback(
    (recordingInfo, typeOfRecording) => {
      const isSeriesEvent = typeOfRecording === RECORDING_PARAMS.SERIES;
      trackRecordingUpdateEventCallback({
        isSeriesEvent,
        isItemLive: true,
        recordingItem: recordingInfo?.assetToRecord,
        mappedContentType: MAPPED_CONTENT_TYPES.LIVE,
        analyticsEventType: isSeriesEvent
          ? ANALYTICS_EVENT_TYPES.VIDEO_RECORD_SERIES_EDIT_START
          : ANALYTICS_EVENT_TYPES.VIDEO_RECORD_EPISODE_EDIT_START,
      });

      showRecordingSettingsPanel(
        typeOfRecording,
        recordingInfo,
        !isMR ? recordablePrograms : null,
        false,
        (recordingData) => trackRecordingUpdateEventCallback({ recordingInfo, ...recordingData })
      );
      toggleSettingsPanelAction(true);
    },
    [trackRecordingUpdateEventCallback, showRecordingSettingsPanel, recordablePrograms, toggleSettingsPanelAction, isMR]
  );

  const openRecordingModal = useCallback(
    (event, recordingInfo) => {
      event.stopPropagation();
      if (recordingInfo) {
        setSelectedAssetRecordingInfo(recordingInfo);
        if (isMR) {
          const hasEventRecording =
            recordingInfo.recordingEventScheduledCheck && !recordingInfo.recordingSeriesScheduledCheck;
          if (hasEventRecording) {
            // If the recording has been set as a single EVENT recording, don't need to show the recording modal.
            showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
          } else {
            setShowRecordingModal(true);
          }
        } else {
          const cpvrRecordingStatus = getCPVRRecordingStatus(recordingInfo);
          if (doesCPVREventRecordingExist(cpvrRecordingStatus)) {
            if (isCPVRRecordingInProgress(recordingInfo.eventRecordingItem)) {
              // event recording is in progress, show the event cancel modal
              openCancelRecordingModal(recordingInfo, recordingInfo.assetToRecord, false);
            } else {
              // event recording is scheduled but not in progress, show the event settings panel
              showRecordingSettingHandler(recordingInfo, RECORDING_PARAMS.EVENT);
              toggleSettingsPanelAction(true);
            }
          } else {
            // If no series recording, no episode recording, and no single event recording exists, we show the recording modal
            // so the user can schedule either a single event recording or series recording (which should create an episode recording)
            // for this episode.
            // If a series recording exists for the show that this episode belongs to but there is no episode recording
            // for this specific episode, we do not allow the user to edit the series recording from this episode detail page.
            // Instead, we show the recording modal so the user can schedule a recording for this episode as a single event or
            // schedule the series recording.
            // TODO: trying to schedule the series recording in this last scenario will do nothing since the series recording already
            // exists, so we will need some guidance on what can be done for this (should we remove the series button in the modal? is
            // there a way to add episode recordings to an existing series recording?)
            setShowRecordingModal(true);
          }
        }
      }
    },
    [isMR, openCancelRecordingModal, showRecordingSettingHandler, toggleSettingsPanelAction]
  );

  const getRecordingButton = useCallback(() => {
    if (primaryRecordingCTA) {
      return (
        <ImageButton
          src={primaryRecordingCTA.recordingIcon}
          className="wall-content-icon wall-recording-icon"
          onClickHandler={(event) => openRecordingModal(event, primaryRecordingInfo)}
          testID="wallRecordIcon"
          buttonContainerStyles="button-container"
          alt={primaryRecordingCTA.altHead}
          tooltipDirection="top"
        />
      );
    }

    return null;
  }, [primaryRecordingCTA, openRecordingModal, primaryRecordingInfo]);

  /**
   * Fetch the user's recordings and save the response in redux
   *
   * @param {Object} recordingParamsMR - MR specific recording params used for set/edit/delete recording when initial attempt reports PENDING status
   * @param {Boolean} fetchingAfterAction - flag used to differentiate between initial fetch and post-action fetches
   */
  const fetchRecordings = useCallback(
    (recordingParamsMR = null, fetchingAfterAction = false) => {
      isRecordingActionInProgress.current = fetchingAfterAction;
      if (isMR) {
        getRecordingAction(
          appProvider,
          true,
          null,
          null,
          null,
          MR_RECORDING_FILTER_OPTIONS.SCHEDULED_PAGE_FILTERS,
          recordingParamsMR
        );
      } else {
        getRecordingAction(appProvider, false, true, null, [RECORDING_PARAMS.EVENT, RECORDING_PARAMS.SERIES]);
      }
    },
    [appProvider, getRecordingAction, isMR]
  );

  /**
   * Schedule a recording and save the response in redux
   *
   * @param {String} typeOfRecording - the recording type (event vs series)
   */
  const scheduleRecording = useCallback(
    (typeOfRecording) => {
      if (selectedAssetRecordingInfo) {
        recordingEventTypeRef.current = ANALYTICS_EVENT_TYPES.VIDEO_RECORD_START;
        setRecording(typeOfRecording, appProvider, selectedAssetRecordingInfo.assetToRecord, setRecordingAction, isMR);
      }
    },
    [appProvider, isMR, selectedAssetRecordingInfo, setRecordingAction]
  );

  const closeRecordingModal = () => {
    setShowRecordingModal(false);
  };

  /**
   * @param {Object} recordingInfo - Object containing response of particular asset from recordingsList object to compare and edit the recordings.
   * @param {String} typeOfRecording - EVENT/SERIES
   */
  const editRecordingHandler = (recordingInfo, typeOfRecording) => {
    showRecordingSettingHandler(recordingInfo, typeOfRecording);
  };

  useEffect(() => {
    if (watchOptions?.length > 0 && !purchasePackages) {
      const purchaseOptions = getPurchaseOptions(watchOptions);
      if (purchaseOptions?.length > 0) {
        setPurchasePackages(purchaseOptions);
        convivaContSubType.current.updateContSubType(purchaseOptions);
      }
    }
    convivaContSubType.current.updateWatchAsset(watchOptions);
  }, [purchasePackages, watchOptions]);

  useEffect(() => {
    return () => {
      setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, null);
      setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.PROGRAM_NAME, null);
      setWatchOptions(null);
      setCurrentTabIndex(0);
      setProgressTime(null);
      setHasCC(null);
      setHasDV(null);
      setSeriesInfo(null);
      setIsPlayableOnDemand(null);
      setIsPlayableLive(null);
      setPurchasePackages(null);
      setSelectedPurchasePackage(null);
      setShowWaysToOrder(false);
      setPlaybackId(0);
      setSvodWatchOptions(null);
      setShowWaysToWatch(false);
      setShowPlayButton(false);
      setIsItemLive(false);
      setStationId(0);
      setViewAllUrl("");
      resetAction(LOAD_EPISODE_PAGE_CONTAINERS, "content");
      resetAction(LOAD_PLAY_OPTIONS, "content");
      resetAction(LOAD_PROGRAM_DETAILS, "content");
      resetAction(LOAD_EPISODE_SCHEDULES, "content");
      resetAction(LOAD_SIMILAR_SERIES, "content");
      resetAction(LOAD_SERIES_CONTENT, "content");
      resetAction(LOAD_ON_DEMAND_OPTIONS, "content");
      resetAction(LOAD_LIST_OF_TEAMS, "content");
      areGenreBasedSimilarItemsFetched.current = false; // re-setting on change of content
      resetRecordingActionValues();
    };
  }, [contentId, resetAction, resetRecordingActionValues]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadEpisodeDetailItems(appProvider, detailUri, CONTAINER_TYPES.CONTAINERS);
  }, [appProvider, detailUri, loadEpisodeDetailItems]);

  useEffect(() => {
    if (contentMetadata?.metadata) {
      setCatalogInfo(contentMetadata.metadata);
      if (contentMetadata.metadata.extendedMetadata?.dlum?.people) {
        setCastRoles(contentMetadata.metadata.extendedMetadata.dlum.people);
      } else {
        setCastRoles(null);
      }

      if (isUserLoggedIn && !isUnifiedAsset) {
        if (contentMetadata.metadata.title.length > 0) {
          loadSchedules(appProvider, contentMetadata.metadata.title, cancelTokenSource);
        }
      }
    }
  }, [appProvider, contentMetadata, isUnifiedAsset, loadSchedules, isUserLoggedIn, cancelTokenSource]);

  useEffect(() => {
    if (!isUnifiedAsset) {
      !hasCC && setHasCC(contentMetadata.metadata?.extendedMetadata?.hasCC);
      !hasDV && setHasDV(contentMetadata.metadata?.extendedMetadata?.hasDV);
    }
  }, [contentMetadata, hasCC, hasDV, isUnifiedAsset]);

  useEffect(() => {
    if (contentMetadata && contentMetadataId.current !== contentMetadata.id) {
      contentMetadataId.current = contentMetadata.id;
      loadPlayOptions(appProvider, contentMetadata.id, PAGE_CONTENT_ITEM_TYPES.movie);
      if (isUserLoggedIn) {
        if (!channelMapInfo && appProvider) loadChannels(appProvider);
      }
    }
  }, [
    appProvider,
    channelMapInfo,
    contentType,
    contentMetadata,
    getRecordingAction,
    isMR,
    isRecordingEnabled,
    loadChannels,
    loadPlayOptions,
    isUserLoggedIn,
  ]);

  useEffect(() => {
    if (catalogInfo) {
      if (!isRecommendationEnabled && catalogInfo.extendedMetadata?.dlum?.displayGenres?.length > 0) {
        loadSimilarItems(
          appProvider,
          catalogInfo.contentType?.toLowerCase(),
          catalogInfo.extendedMetadata.dlum.displayGenres[0],
          catalogInfo.contentSubtype || catalogInfo.uaType,
          userProfile,
          pcLevel,
          cancelTokenSource
        );
      }
      if (isUserLoggedIn) {
        setLoadComponent(true);
        if (catalogInfo && catalogInfo.SeriesId) {
          Promise.allSettled([loadSeriesContent(appProvider, catalogInfo.SeriesId)]).then(() => setLoadComponent(true));
        }
        if (contentMetadata && contentMetadataId.current !== contentMetadata.id) {
          contentMetadataId.current = contentMetadata.id;
          loadPlayOptions(appProvider, contentMetadata?.id, PAGE_CONTENT_ITEM_TYPES.movie);
        }
      }
    }
  }, [
    appProvider,
    catalogInfo,
    contentType,
    contentMetadata,
    isRecommendationEnabled,
    loadPlayOptions,
    loadSeriesContent,
    loadSimilarItems,
    pcLevel,
    isUserLoggedIn,
    cancelTokenSource,
    userProfile,
  ]);

  useEffect(() => {
    if (onDemandOptions && !watchOptions) {
      if (onDemandOptions.length === 0) {
        isPlayableOnDemand === null && setIsPlayableOnDemand(false);
        return;
      }

      const watchOptionsAssets = [];
      const onDemandChannelAssets = [];

      onDemandOptions.forEach((onDemandOption) => {
        let onDemandChannelData;
        const onDemandOptionExtendedMetadata = onDemandOption?.metadata?.extendedMetadata;
        if (onDemandOptionExtendedMetadata?.source) {
          onDemandChannelData = { channel: onDemandOptionExtendedMetadata.source, id: onDemandOption.id };
        }
        onDemandChannelAssets.push(onDemandChannelData);
        watchOptionsAssets.push(
          onDemandOption.id
            ? getContentUserData(appProvider, onDemandOption.id, PAGE_CONTENT_ITEM_TYPES.vod, cancelTokenSource)
            : Promise.resolve()
        );
      });

      Promise.allSettled(watchOptionsAssets).then((res) => {
        const onDemandData = res?.reduce((onDemandAssets, currentAsset) => {
          if (currentAsset?.status === "fulfilled" && currentAsset?.value) {
            const data = currentAsset.value.containers?.find(
              (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_ITEM
            );
            if (!isAssetRecordable) {
              setIsAssetRecordable(data?.entitlement?.isRecordable);
            }
            const { assets, ...entitlement } = data?.entitlement ?? {};
            const masterAssets =
              assets?.length > 0 &&
              assets
                .filter(
                  (asset) =>
                    asset?.assetType?.toLowerCase() === ASSET_TYPES.MASTER &&
                    asset?.rights?.toLowerCase() !== WATCH_OPTIONS.none
                )
                .map((asset) =>
                  Object.assign(asset, {
                    contentId: data?.id,
                    bookmarks: data?.metadata?.bookmarks,
                    entitlement,
                  })
                );
            const assetsChannelData =
              onDemandChannelAssets?.length > 0 &&
              onDemandChannelAssets.find((channelAsset) => parseInt(channelAsset?.id) === parseInt(data?.id));
            const assetsWithChannelData =
              masterAssets?.length > 0 &&
              masterAssets.map((asset) => Object.assign(asset, { channel: assetsChannelData?.channel }));
            onDemandAssets = onDemandAssets.concat(assetsWithChannelData);
          }
          return onDemandAssets;
        }, []);
        onDemandData.sort(prioritizeTvodCompareFunction);
        setWatchOptions(onDemandData);

        if (onDemandOptions.length > 0) {
          productDetails.current = {
            ...productDetails.current,
            isItemVod: true,
            isItemLive: false,
          };
        }
      });
    }
  }, [appProvider, isAssetRecordable, isPlayableOnDemand, onDemandOptions, watchOptions, cancelTokenSource]);

  useEffect(() => {
    if (onDemandOptions) {
      let preferredOnDemandOption;
      const setBookmark = async (preferredOnDemandOption) => {
        const preferredOnDemandOptionUserContent = preferredOnDemandOption?.metadata?.contentId
          ? await getContentUserData(
              appProvider,
              preferredOnDemandOption?.metadata?.contentId,
              PAGE_CONTENT_ITEM_TYPES.vod,
              cancelTokenSource
            )
          : {};
        const preferredOnDemandOptionUserContentContainer = preferredOnDemandOptionUserContent?.containers?.find(
          (container) => container?.layout?.toLowerCase() === CONTAINER_LAYOUTS.CONTENT_ITEM
        );
        const bookmark = preferredOnDemandOptionUserContentContainer?.metadata?.bookmarks?.[0];
        if (bookmark && !bookmark.isComplete) {
          setProgressTime(bookmark.startDeltaTime);
        }
      };
      const setFirstOnDemandOptionMetadata = () => {
        if (watchOptions) {
          const firstPlayableAsset = retrieveMasterAsset(watchOptions, WATCH_OPTIONS.watch);
          preferredOnDemandOption =
            onDemandOptions.find((onDemandOption) => onDemandOption?.id === firstPlayableAsset?.contentId) ||
            onDemandOptions[0];
          const isFeaturePlayable = featurePlayAvailability(preferredOnDemandOption);
          !isFeaturePlayable && setIsPlayableOnDemand(false);
          if (preferredOnDemandOption) {
            const preferredOnDemandOptionExtendedMetadata = preferredOnDemandOption?.metadata?.extendedMetadata;
            setHasCC(preferredOnDemandOptionExtendedMetadata?.hasCC);
            setHasDV(preferredOnDemandOptionExtendedMetadata?.hasDV);
            setBookmark(preferredOnDemandOption);
          }
        }
      };
      if (contentUserData) {
        const latestBookmark = contentUserData.metadata?.bookmarks?.[0];
        if (latestBookmark) {
          preferredOnDemandOption = onDemandOptions.find(
            (onDemandOption) => onDemandOption?.metadata?.contentId === latestBookmark.bookmarkSet?.contentId
          );
          if (preferredOnDemandOption) {
            const preferredOnDemandOptionExtendedMetadata = preferredOnDemandOption?.metadata?.extendedMetadata;
            setHasCC(preferredOnDemandOptionExtendedMetadata?.hasCC);
            setHasDV(preferredOnDemandOptionExtendedMetadata?.hasDV);
            if (!latestBookmark.isComplete) {
              setProgressTime(latestBookmark.startDeltaTime);
            }
            /**
             * this is a temporary fix to avoid up-sell string from rendering when a vod play option is present,
             * will refactor the up-sell string logic for episode details page later as part of the optimization work
             * */
            if (watchOptions) {
              const isFeaturePlayable = featurePlayAvailability(preferredOnDemandOption);
              !isFeaturePlayable && setIsPlayableOnDemand(false);
            }
          } else {
            setFirstOnDemandOptionMetadata();
          }
        } else {
          setFirstOnDemandOptionMetadata();
        }
      }
    }
  }, [appProvider, cancelTokenSource, featurePlayAvailability, onDemandOptions, contentUserData, watchOptions]);

  useEffect(() => {
    if (schedules?.length === 0) {
      isPlayableLive === null && setIsPlayableLive(false);
      return;
    }
    let priorityLiveProgram;

    if (schedules?.length > 0) {
      const currentTime = moment().valueOf();
      if (isUnifiedAsset) {
        // Checking for in-home live assets before using an out-of-home live asset for live CTA
        const firstLiveSchedule =
          getFirstInHomeLiveSchedule(schedules, currentTime, channelMapInfo, subscribedChannels) ||
          getFirstOutOfHomeLiveSchedule(schedules, currentTime, channelMapInfo, subscribedChannels);
        priorityLiveProgram = lookbackProgram || firstLiveSchedule;
        if (priorityLiveProgram) {
          !isItemLive && setIsItemLive(true);
          !stationId &&
            setStationId(priorityLiveProgram.channel?.metadata?.channelId || priorityLiveProgram.channel?.channelId);
          if (priorityLiveProgram.isNotAvailableOutOfHome) isLiveProgramOOH.current = true;
          productDetails.current = {
            ...productDetails.current,
            isItemLive: true,
            channel: {
              ...priorityLiveProgram.channel,
              channelNumber: getRegionalChannelNumber(priorityLiveProgram.channel, appProvider?.channelMapID),
            },
          };
          !hasNew && setHasNew(priorityLiveProgram.metadata?.extendedMetadata?.epg?.isNew);
          !hasCC && setHasCC(priorityLiveProgram.metadata?.extendedMetadata?.hasCC);
          !hasDV && setHasDV(priorityLiveProgram.metadata?.extendedMetadata?.hasDV);
        } else if (!checkIfAnyScheduleIsSubscribed(schedules, subscribedChannels) && isPlayableLive === null) {
          setIsPlayableLive(false);
        }
      } else if (!isUnifiedAsset && contentMetadata?.id) {
        const liveItemDetail = getItemLiveAvailability(schedules, contentMetadata.id, channelMapInfo, currentTime);
        priorityLiveProgram = lookbackProgram || liveItemDetail;
        if (priorityLiveProgram) {
          setIsItemLive(true);
          setStationId(
            lookbackProgram ? lookbackProgram.channel?.metadata?.channelId : contentMetadata.channel?.channelId
          );
          if (priorityLiveProgram.isNotAvailableOutOfHome) isLiveProgramOOH.current = true;
          productDetails.current = {
            ...productDetails.current,
            isItemLive: true,
            channel: {
              ...contentMetadata.channel,
              channelNumber: getRegionalChannelNumber(contentMetadata.channel, appProvider?.channelMapID),
            },
          };
        } else if (!checkIfAnyScheduleIsSubscribed(schedules, subscribedChannels) && isPlayableLive === null) {
          setIsPlayableLive(false);
        }
      }
      setPlaybackId(priorityLiveProgram?.id);
    }
  }, [
    appProvider,
    contentMetadata,
    hasCC,
    hasDV,
    isItemLive,
    isPlayableLive,
    isUnifiedAsset,
    schedules,
    stationId,
    channelMapInfo,
    lookbackProgram,
    subscribedChannels,
    hasNew,
  ]);

  /**
   * This useEffect is used to deal with errors that occur during recording CRUD actions
   */
  useEffect(() => {
    if ((getRecordingError || recordingActionError) && manipulatedRecordingParams) {
      displayRecordingToast(getRecordingError || recordingActionError, manipulatedRecordingParams);
      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    getRecordingError,
    manipulatedRecordingParams,
    recordingActionError,
    resetRecordingActionValues,
  ]);

  /**
   * This useEffect is used after we have re-fetched recordings following a set/edit/delete recording action.
   */
  useEffect(() => {
    if (programsRecordingInfoList && manipulatedRecordingParams && isRecordingActionInProgress.current) {
      // Can't use the selectedAssetRecordingInfo as-is, need to update it with the latest recording state checks/values
      const selectedRecordingInfo = programsRecordingInfoList.find((recordingInfo) => {
        return recordingInfo.assetToRecord.id === selectedAssetRecordingInfo.assetToRecord.id;
      });
      setSelectedAssetRecordingInfo(selectedRecordingInfo);

      if (
        isMR &&
        (selectedRecordingInfo.recordingSeriesConflictCheck || selectedRecordingInfo.recordingEventConflictCheck)
      ) {
        setShowRecordingModal(true);
      } else {
        displayRecordingToast(selectedRecordingInfo, manipulatedRecordingParams);
      }

      resetRecordingActionValues();
    }
  }, [
    displayRecordingToast,
    programsRecordingInfoList,
    isMR,
    manipulatedRecordingParams,
    resetRecordingActionValues,
    selectedAssetRecordingInfo,
  ]);

  /**
   * This useEffect is used after we have attempted to schedule a recording
   */
  useEffect(() => {
    if (setRecordingResponse && isRecordingActionInProgress.current === false) {
      if (isMR) {
        if (setRecordingResponse.code) {
          // Error occurred
          setRecordingActionError(setRecordingResponse);
        } else {
          fetchRecordings(
            isRecordingPendingStateCheck(setRecordingResponse.content) ? setRecordingResponse.recordingParams : null,
            true
          );
        }
      } else {
        if (setRecordingResponse.code) {
          // Error occurred
          if (checkCPVRConflicts(setRecordingResponse)) {
            // show popup modal for recording conflict error
            showCPVRConflictModal(
              () => {
                navigateToPVRManager(true); // Redirect user to PVR manager scheduled page to manage the conflict
                setSessionStorage(
                  ANALYTICS_STORAGE_KEYS.LINK,
                  `${LINK_INFO.MANAGE_RECORDINGS};${LINK_INFO.RECORDING_PROMPT}`
                );
              },
              MODAL_TYPES.ERROR,
              showModalPopup
            );
            resetRecordingActionValues();
          } else {
            // show generic 'not set' toast notification for all other errors
            setRecordingActionError(setRecordingResponse);
          }
        } else {
          // No error in the response, refresh our recordings list
          fetchRecordings(null, true);
        }
      }
    }
  }, [setRecordingResponse, isMR, fetchRecordings, showModalPopup, resetRecordingActionValues]);

  useEffect(() => {
    let schedules = isLookbackEnabled ? schedules : currentAndUpcomingSchedules;
    if (currentAndUpcomingSchedules?.length > 0) {
      const firstSchedule = currentAndUpcomingSchedules[0];
      const isNew = firstSchedule.metadata?.extendedMetadata?.epg?.isNew;
      setHasNew(isNew);
    }
  }, [currentAndUpcomingSchedules, isLookbackEnabled]);

  /**
   * This useEffect is used after we have attempted to edit a recording
   */
  useEffect(() => {
    if (editRecordingResponse && isRecordingActionInProgress.current === false) {
      if (editRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(editRecordingResponse);
      } else {
        fetchRecordings(
          isMR && isRecordingPendingStateCheck(editRecordingResponse.content)
            ? editRecordingResponse.recordingParams
            : null,
          true
        );
      }
    }
  }, [editRecordingResponse, isMR, fetchRecordings]);

  /**
   * This useEffect is used after we have attempted to cancel a recording
   */
  useEffect(() => {
    if (deleteRecordingResponse && isRecordingActionInProgress.current === false) {
      if (deleteRecordingResponse.code) {
        // Error occurred
        setRecordingActionError(deleteRecordingResponse);
      } else {
        // Nothing useful returned in the MR delete response, so we assume its pending
        fetchRecordings(isMR ? deleteRecordingResponse.recordingParams : null, true);
      }
    }
  }, [deleteRecordingResponse, fetchRecordings, isMR]);

  useEffect(() => {
    if (userProfile?.isLoggedIn) {
      setRecordingSystemType(getRecordingSystemType(userProfile));
      if (!subscribedChannels && appProvider) {
        loadUserSubscribedChannels(appProvider, userProfile, cancelTokenSource, isUserProfilesEnabled);
      }
    }
  }, [
    appProvider,
    loadUserSubscribedChannels,
    subscribedChannels,
    userProfile,
    cancelTokenSource,
    isUserProfilesEnabled,
  ]);

  useEffect(() => {
    const fetchSeriesRoute = async () => {
      const seriesData = await getSeriesInfo(contentMetadata, appProvider, isSportsEventPage);
      if (!seriesInfo && seriesData) {
        setSeriesInfo(seriesData);
      }
    };
    if (contentMetadata) {
      fetchSeriesRoute();
    }
  }, [appProvider, contentMetadata, seriesInfo, isSportsEventPage]);

  useEffect(() => {
    if (contentMetadata && (Array.isArray(onDemandOptions) || !isUnifiedAsset || !isUserLoggedIn)) {
      setNRAttribute(NR_CUSTOM_ATTRIBUTES.PROGRAM_NAME, contentMetadata.metadata?.title);
      setDatadogViewAttribute(DD_CUSTOM_ATTRIBUTES.PROGRAM_NAME, contentMetadata.metadata?.title);

      productDetails.current = {
        ...productDetails.current,
        ...contentMetadata,
        route: window.location.hash,
      };

      // Add TAMS ID
      if (productDetails.current.metadata && !productDetails.current.metadata.externalId && onDemandOptions?.length) {
        productDetails.current.metadata.externalId = onDemandOptions[0]?.metadata?.externalId;
      }
      if (isUserLoggedIn && isPlayableOnDemand === null) {
        return;
      }
      trackPageView({
        pageName: contentMetadata.metadata?.title,
        contentId: contentMetadata?.metadata?.contentId || playbackId,
        productId: contentMetadata?.metadata?.extendedMetadata?.dlum?.umId,
        episodeInfo: getEpisodeInfo(productDetails.current),
        extraMetadataList: [
          {
            type: EXTRA_METADATA_TYPES.MEDIA_CONTENT,
            asset: {
              isItemLive,
              ...productDetails.current,
              mappedContentType: isPlayableOnDemand ? MAPPED_CONTENT_TYPES.SVOD : MAPPED_CONTENT_TYPES.TVOD,
            },
          },
          { type: EXTRA_METADATA_TYPES.COMMERCE },
        ],
      });
    }

    return () => {
      resetIsPageViewTracked();
    };
  }, [
    contentMetadata,
    isItemLive,
    isPlayableOnDemand,
    trackPageView,
    resetIsPageViewTracked,
    onDemandOptions,
    isUnifiedAsset,
    isUserLoggedIn,
    playbackId,
  ]);

  useEffect(() => {
    if (similarItems && contentMetadata?.metadata?.extendedMetadata?.dlum?.displayGenres?.length > 0) {
      setViewAllUrl(
        `${SIMILAR_ITEMS.route}/${contentMetadata.id}` +
          `?itemType=${CONTENT_ITEM_TYPES.episode}` +
          `&genres=${contentMetadata.metadata.extendedMetadata.dlum.displayGenres[0]}` +
          `&itemSubType=${contentMetadata.metadata.contentSubtype ?? contentMetadata.metadata.uaType ?? ""}`
      );
    }
    if (
      contentMetadata &&
      !areGenreBasedSimilarItemsFetched.current && // check to make api call only if not made before, to avoid loop in case of genre-based api failure
      isRecommendationEnabled &&
      similarItems?.length === 0 && // / similarItems is an empty array in case of api failure, so this checks if the recommendation API failed or returned empty
      catalogInfo?.extendedMetadata?.dlum?.displayGenres?.length > 0
    ) {
      areGenreBasedSimilarItemsFetched.current = true;
      loadSimilarItems(
        appProvider,
        catalogInfo.contentType?.toLowerCase(),
        catalogInfo.extendedMetadata.dlum.displayGenres[0],
        catalogInfo.contentSubtype || catalogInfo.uaType,
        userProfile,
        pcLevel,
        cancelTokenSource
      );
    }
  }, [
    appProvider,
    catalogInfo,
    contentMetadata,
    isRecommendationEnabled,
    loadSimilarItems,
    similarItems,
    pcLevel,
    cancelTokenSource,
    userProfile,
  ]);

  useEffect(() => {
    if (contentUserData?.entitlement) {
      !isAssetRecordable && setIsAssetRecordable(contentUserData.entitlement.isRecordable);
    }
    if (contentUserData && contentUserData.entitlement && contentUserData.entitlement.assets && !isUnifiedAsset) {
      const masterAssets = contentUserData.entitlement.assets.filter(
        (asset) =>
          asset?.assetType?.toLowerCase() === ASSET_TYPES.MASTER && asset?.rights?.toLowerCase() !== WATCH_OPTIONS.none
      );
      const modifiedMasterAssets =
        masterAssets?.length > 0 &&
        masterAssets.map((asset) => Object.assign(asset, { contentId: contentUserData.id }));
      setWatchOptions(modifiedMasterAssets);
    }
    if (contentUserData && contentUserData.metadata && contentUserData.metadata.bookmarks && !isUnifiedAsset) {
      setProgressTime(contentUserData.metadata.bookmarks[0].startDeltaTime);
    }
  }, [isAssetRecordable, isUnifiedAsset, contentUserData]);

  const getRolesFeed = () => {
    if (castRoles?.length > 0) {
      const title = translate(SWIMLANE_TITLES.CAST_AND_CREW);
      return createSwimlaneModels({
        data: castRoles.map((item) => ({ metadata: item })),
        avsComponent: { metadata: { label: title }, title },
        addParams: { DIMENSIONS: ITEM_TYPES.CHARACTER_ITEM.DIMENSIONS },
        itemsCount: castRoles.length,
        isAppLanguageFrench,
        userProfile,
      });
    }

    return null;
  };

  const linkClickHandler = () => {
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.SERIES_LINK};${LINK_INFO.SUB_NAV}`);
    window.location.href = seriesInfo?.url;
  };

  const onPlayClick = (type, contentMetadata) => {
    if (toastClickHandler(type) === false) {
      const subscribedWatchOptions = allOnDemandItems?.filter((onDemandItem) => {
        return (
          onDemandItem?.channel &&
          Object.keys(onDemandItem.channel).length > 0 &&
          onDemandItem.rights.toLowerCase() === WATCH_OPTIONS.watch
        );
      });
      if (subscribedWatchOptions.length > 1 && !progressTime) {
        setShowWaysToWatch(true);
        setSvodWatchOptions(subscribedWatchOptions);
      } else {
        setSessionStorage(
          ANALYTICS_STORAGE_KEYS.LINK,
          `${LINK_INFO.ON_DEMAND_PLAY};${LINK_INFO.SUB_NAV};${getAAVideoPlayType()}`
        );
        performPlaybackChecks(
          contentMetadata,
          PLAYBACK_TYPES.EPISODE,
          playbackId,
          PAGE_CONTENT_ITEM_TYPES.vod,
          ON_DEMAND_PLAYER.route
        );
      }
    }
  };

  const getSimilarItems = () => {
    // Filtering out any items that point to the detail page we are currently on
    const filteredSimilarItems = similarItems.filter(
      (item) => item?.actions?.find((action) => action?.key?.toLowerCase() === ACTION_KEYS.ON_CLICK)?.uri !== detailUri
    );

    if (filteredSimilarItems?.length > 0) {
      const title = translate(SWIMLANE_TITLES.SIMILAR_ITEMS);
      return createSwimlaneModels({
        data: filteredSimilarItems,
        avsComponent: { metadata: { label: title }, title },
        addParams: { DIMENSIONS: ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS, viewAllUrl },
        itemsCount: totalSimilarItems || filteredSimilarItems.length,
        isAppLanguageFrench,
        userProfile,
      });
    }

    return null;
  };

  const getTeamItems = () => {
    const title = translate(SWIMLANE_TITLES.TEAMS);
    return createSwimlaneModels({
      data: listOfTeams,
      avsComponent: { metadata: { label: title }, title },
      addParams: { DIMENSIONS: ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS, viewAllUrl },
      itemsCount: listOfTeams.length,
      isAppLanguageFrench,
      userProfile,
    });
  };

  const getEntitlementType = (entitlement, episodeType) => {
    let label, icon, className, type;
    if (entitlement?.isChannelNotSubscribed) {
      label = "available_tv_mobile";
      icon = playIcon;
      className = "restricted";
      type = "browser";
    } else if (
      (entitlement?.isContentOOHBlocked || (isItemLive && !lookbackProgram && isLiveProgramOOH.current)) &&
      !isInHome
    ) {
      label = "action_ooh";
      icon = outHomeIcon;
      className = "restricted";
      type = "outHome";
    } else if (entitlement?.isPlatformBlacklisted) {
      label = "";
      icon = playIcon;
      className = "restricted";
      type = "browser";
    } else if (entitlement?.isGeoBlocked) {
      label = episodeType === "play" ? "available_tv_mobile" : "action_live";
      icon = playIcon;
      className = episodeType === "play" ? "restricted" : "play";
      type = episodeType === "play" ? "browser" : "geoblock";
    } else if (entitlement?.isSportBlackoutBlocked) {
      label = "";
      icon = playIcon;
      className = "restricted";
      type = "browser";
    } else if (episodeType === "play") {
      if (progressTime) {
        className = "resume";
        type = "resume";
      } else {
        className = "play";
        type = "play";
      }
      if (uaEpisodeObject.isSeasonAutoGenerated || uaEpisodeObject.isEpisodeAutoGenerated) {
        label = translate(`${type}_on_demand`);
      } else {
        label =
          catalogInfo && catalogInfo.season
            ? catalogInfo.episodeNumber
              ? `${translate(type)} S${catalogInfo.season} E${catalogInfo.episodeNumber} ${translate("on_demand")}`
              : `${translate(type)} S${catalogInfo.season} ${translate("on_demand")}`
            : catalogInfo.episodeNumber
            ? `${translate(type)} E${catalogInfo.episodeNumber} ${translate("on_demand")}`
            : translate(`${type}_on_demand`);
      }
      icon = playIcon;
      type = "play";
    } else if (lookbackProgram) {
      label = "restart_live_tv";
      icon = playIcon;
      className = "play";
    } else if (episodeType === "live") {
      label = "action_live";
      icon = playIcon;
      className = "live-play";
      type = "live";
    }
    return {
      label: label,
      icon: icon,
      className: className,
      type: type,
    };
  };
  const getPlayButton = () => {
    let playAssets, playableContent;
    if (isUnifiedAsset) {
      playAssets = watchOptions;
    } else {
      const { assets } = contentUserData?.entitlement ?? { assets: [] };
      playAssets = assets;
    }
    if (playAssets?.length > 0) {
      playableContent = watchOptions.find((watchOption) => {
        return watchOption.contentId === playbackId;
      });
      if (!isPlayableAsset(playAssets)) {
        isPlayableOnDemand === null && setIsPlayableOnDemand(false);
        return null;
      }
      const entitlement = playableContent?.entitlement;
      const entitlementObj = getEntitlementType(entitlement, "play");
      if (entitlementObj.type === "play" && isPlayableOnDemand === null) {
        setIsPlayableOnDemand(true);
      }
      return (
        <OptikButton
          label={translate(entitlementObj?.label)}
          icon={entitlementObj?.icon}
          className={entitlementObj?.className}
          onClickHandler={() => onPlayClick(entitlementObj?.type, playableContent)}
        />
      );
    }
  };

  let pageTitle = catalogInfo
    ? catalogInfo.title +
      (catalogInfo.episodeTitle ? `${translate("punctuation:colon")}${catalogInfo.episodeTitle}` : "")
    : "";

  const getLiveButton = () => {
    const entitlementObj = getEntitlementType(null, "live");
    return (
      <OptikButton
        label={translate(entitlementObj?.label)}
        icon={entitlementObj?.icon}
        className={entitlementObj?.className}
        onClickHandler={() => handleLiveItemClick(entitlementObj?.type)}
      />
    );
  };

  const toastClickHandler = useCallback(
    (type) => {
      if (type === "outHome") {
        showToastNotification(translate("message_in_home"), outHomeIcon);
      } else if (type === "browser") {
        showToastNotification(translate("error_browser_restriction"), null, {
          media: { isItemLive, ...productDetails.current },
        });
      } else if (type === "geoblock") {
        showToastNotification(translate("error_program_not_available_region"));
      } else if (type === "geofence") {
        showToastNotification(translate("error_program_not_available_region"));
      } else if (type === "subscribe") {
        showToastNotification(translate("not_subscribed_restriction"), unSubscribedIcon, {
          media: { isItemLive, ...productDetails.current },
        });
      } else {
        return false;
      }
    },
    [showToastNotification, translate, isItemLive]
  );

  const handleLiveItemClick = (type) => {
    setSessionStorage(
      ANALYTICS_STORAGE_KEYS.LINK,
      `${LINK_INFO.LIVE_PLAY};${LINK_INFO.SUB_NAV};${getAAVideoPlayType()}`
    );
    if (toastClickHandler(type) === false) {
      performPlaybackChecks(
        null,
        null,
        stationId,
        PAGE_CONTENT_ITEM_TYPES.live,
        LIVE_PLAYER.route,
        null,
        null,
        lookbackProgram?.channel || null,
        null,
        lookbackProgram || null
      );
    }
  };

  /**
   * This function was created by duplicating handleLiveItemClick() on June 21, 2022.
   * A duplicate was created in order to prevent infinite re-renders in the Schedules component, but also limit the scope of the refactor work
   * that would otherwise be necessary if we fixed the issues with handleLiveItemClick() directly.
   *
   * Ideally we would have a single function and maybe one day we can get there, but today is not that day so we will likely need to keep these
   * two functions in sync with regards to their internal logic
   */
  const schedulesToastClickHandler = useCallback(
    (type) => {
      setSessionStorage(
        ANALYTICS_STORAGE_KEYS.LINK,
        `${LINK_INFO.LIVE_PLAY};${LINK_INFO.SUB_NAV};${getAAVideoPlayType()}`
      );
      if (toastClickHandler(type) === false) {
        performPlaybackChecks(null, null, stationId, PAGE_CONTENT_ITEM_TYPES.live, LIVE_PLAYER.route);
      }
    },
    [stationId, toastClickHandler, performPlaybackChecks]
  );

  const allOnDemandItems = watchOptions?.length > 0 ? getWatchOptions(watchOptions) : null;

  const upSellComponent = () => {
    const upSellLink = isAppLanguageFrench ? "telus.com/moncompte" : "telus.com/mytelus";
    const upSellText = translate("not_subscribed_restriction").split(upSellLink);
    return (
      <span>
        {upSellText[0]}
        <a href={`https://www.${upSellLink}`} target="_blank" rel="noopener noreferrer" className="up-sell-link">
          {upSellLink}
        </a>
        {upSellText[1]}
      </span>
    );
  };

  const openPurchaseModal = useCallback(
    (purchasePackage = null) => {
      if (purchasePackage) {
        trackGenericAction(ANALYTICS_EVENT_TYPES.ADD_TO_CART, {
          purchasePackage,
          asset: mediaNodeMetaData,
        });
        setSelectedPurchasePackage(purchasePackage);
      }
      setShowPurchaseModal(true);
    },
    [mediaNodeMetaData]
  );

  const closePurchaseModal = () => {
    selectedPurchasePackage && setSelectedPurchasePackage(null);
    setShowPurchaseModal(false);
  };

  const getAssetExpiryElement = () => {
    const asset = watchOptions?.length > 0 ? retrieveMasterAsset(watchOptions, WATCH_OPTIONS.watch) : null;
    return asset?.rentalEndDate ? (
      <div className="expiry-message">{getAssetExpiryString(asset.rentalEndDate)}</div>
    ) : null;
  };

  const purchaseItem = useCallback(
    (selectedPackage, pin, disallowPurchase) => {
      if (disallowPurchase) disallowPurchase(true); // disabling purchase CTA to prevent users from spamming
      const promise = makePurchase(appProvider, selectedPackage, pin, cancelTokenSource)
        .then(({ purchaseDetails }) => {
          trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE, {
            appProvider,
            asset: mediaNodeMetaData,
            purchaseDetails,
            purchasePackage: selectedPackage,
          });
          if (isConvivaAppTrackerEnabled) {
            trackConvivaCustomEvent(
              ANALYTICS_EVENT_TYPES.PURCHASE,
              getCustomContextMetadata(mediaNodeMetaData, userProfile, appProvider, isInHome)
            );
          }
          setShowPurchaseModal(false);
          showPurchaseAcknowledgement();
          if (pin) {
            trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK, mediaNodeMetaData);
            if (isConvivaAppTrackerEnabled) {
              trackConvivaCustomEvent(
                ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK,
                getCustomContextMetadata(mediaNodeMetaData, userProfile, appProvider, isInHome)
              );
            }
          }
          setSessionStorage(
            ANALYTICS_STORAGE_KEYS.LINK,
            `${LINK_INFO.PURCHASE_PLAY};${LINK_INFO.PURCHASE_PROMPT};${getAAVideoPlayType()}`
          );
          window.location.href =
            "#" +
            ON_DEMAND_PLAYER.route +
            "/episode/?playbackId=" +
            selectedPackage.contentId +
            "&contentType=" +
            PAGE_CONTENT_ITEM_TYPES.vod;
        })
        .catch((err) => {
          console.error("Failed to make purchase ", err);
          if (disallowPurchase) disallowPurchase(false); // enabling the purchase CTA again in rare case of purchase API call failing
          if (AVS_ERROR_CODES.INCORRECT_PIN_CODES.includes(err?.code) || isPinMaxLimitReach(err)) {
            throw err;
          } else {
            showToastNotification(translate("message_purchase_failed"));
          }
        });

      return promise;
    },
    [
      appProvider,
      cancelTokenSource,
      mediaNodeMetaData,
      showPurchaseAcknowledgement,
      isInHome,
      userProfile,
      isConvivaAppTrackerEnabled,
      translate,
      showToastNotification,
    ]
  );

  const toggleText = (e) => {
    let descElement = document.getElementById("desc");
    if (descElement.style.display === "") {
      descElement.style.display = "inline-block";
      e.target.innerText = translate("show_less");
    } else {
      descElement.style.display = "";
      e.target.innerText = translate("details_read_more");
    }
  };

  const getProgressPercentage = () => {
    if (progressTime > 0 && contentMetadata?.metadata?.duration) {
      return (progressTime / contentMetadata.metadata.duration) * 100;
    }
  };

  const closeWaysToWatchPrompt = () => {
    setShowWaysToWatch(false);
  };

  const closeWaysToOrderPrompt = () => {
    setShowWaysToOrder(false);
  };

  const getLookbackAvailabilityElement = () => {
    return lookbackHoursLeft ? (
      <div className="lookback-string">{getLookbackAvailabilityString(lookbackHoursLeft)}</div>
    ) : null;
  };

  return loadComponent && contentMetadata ? (
    <div className="episode-detail">
      <div className="episode-wall-wrapper">
        {seriesInfo?.seriesDetailContent && (
          <Wall
            cover={getAVSKeyArtImage(seriesInfo.seriesDetailContent, IMAGES.ASPECT_RATIOS.DIM_9x16, isSportsEventPage)}
          />
        )}
        <SeoPageTags title={pageTitle} keywords={["optik", "telus"]} />
        {catalogInfo ? (
          <div className="episode-info">
            <div className="episode-content">
              <div className="episode-poster">
                <img
                  src={`${getAVSKeyArtImage(catalogInfo, IMAGES.ASPECT_RATIOS.DIM_9x16)}?w=485`}
                  alt=""
                  onError={(e) => handleImageError(e, icon)}
                ></img>
                {progressTime && featurePlayAvailability() ? (
                  <div className="ep-progress-bar-container">
                    <div
                      className="ep-progress-bar"
                      style={{
                        width: convertToPercentageString(getProgressPercentage()),
                      }}
                    ></div>
                  </div>
                ) : null}
              </div>

              <div className="episode-desc">
                <div className="episode-header">
                  {isStandAloneEpisode ? null : (
                    <div
                      className={seriesInfo?.url ? "episode-back-btn btn-cursor" : "episode-back-btn"}
                      onClick={seriesInfo?.url ? linkClickHandler : () => {}}
                    >
                      <span>{seriesInfo?.name}</span>
                      {seriesInfo?.url ? <BackButton src={VIEW_ALL_ICON} alt="viewAll" /> : null}
                    </div>
                  )}
                  <h1 className={isStandAloneEpisode ? "no-series-header" : null}>
                    {getAutogeneratedEpisodeString(uaEpisodeObject, catalogInfo)}
                  </h1>
                </div>

                <h2>{getEpisodeInfoLine1(catalogInfo, isAppLanguageFrench, isSportsEventPage)} </h2>
                <h2 className="eps-badge-wrap">
                  {isSportsEventPage && hasNew ? (
                    <span className="new">
                      <img src={process.env.PUBLIC_URL + "/images/New.svg"} alt="new-indicator" />
                    </span>
                  ) : null}
                  {isRecordingNow ? (
                    <div className="recording-badge">
                      <img
                        src={
                          isAppLanguageFrench
                            ? process.env.PUBLIC_URL + "/images/Recording_Now_FR.svg"
                            : process.env.PUBLIC_URL + "/images/Recording_Now_EN.svg"
                        }
                        alt=""
                      />
                    </div>
                  ) : null}
                  {hasCC ? (
                    <span className="cc">
                      <img src={process.env.PUBLIC_URL + "/images/CC.svg"} alt="cc-indicator" />
                    </span>
                  ) : null}
                  {hasDV ? (
                    <span className="ad">
                      <img src={process.env.PUBLIC_URL + "/images/DV.svg"} alt="ad-indicator" />
                    </span>
                  ) : null}
                </h2>
                <p id="desc">{catalogInfo.longDescription}</p>
                {catalogInfo.longDescription && isEllipsisActive("desc") && (
                  <h3 className="show-more" onClick={(e) => toggleText(e)} id="textButton">
                    {translate("details_read_more")}
                  </h3>
                )}
              </div>
            </div>
            {!isUserLoggedIn && (
              <div className="detail-page-cta login">
                <OptikButton
                  label={translate("login")}
                  onClickHandler={() => {
                    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.LOGIN};${LINK_INFO.SUB_NAV}`);
                    handleLogin(appProvider, LOGIN_BRANDS.TELUS, isKoodoLoginEnabled);
                  }}
                />
              </div>
            )}
            <div className="clr"></div>
            <div className="wall-children">
              {userProfile.isLoggedIn &&
              isPlayableOnDemand === false &&
              isPlayableLive === false &&
              !purchasePackages ? (
                <div className="up-sell-message">
                  <img
                    src={process.env.PUBLIC_URL + "/images/key-icon.svg"}
                    className="up-sell-icon"
                    alt={translate("subscribe")}
                    data-testid="unsubscribedIcon"
                  />
                  {upSellComponent()}
                </div>
              ) : null}
              <>
                {showPlayButton && (
                  <div>
                    <div className="detail-page-cta">{getPlayButton()}</div>
                    {getAssetExpiryElement()}
                  </div>
                )}

                {lookbackProgram || isItemLive ? (
                  <div>
                    <div className="detail-page-cta live-btn">{getLiveButton()}</div>
                    {getLookbackAvailabilityElement()}
                  </div>
                ) : null}
                {isPlayableOnDemand === false && purchasePackages && (
                  <OrderCTA
                    purchasePackages={purchasePackages}
                    onClickHandler={() => {
                      if (purchasePackages?.length > 1) {
                        setShowWaysToOrder(true);
                      } else if (purchasePackages?.length === 1) {
                        openPurchaseModal(purchasePackages[0]);
                      }
                    }}
                  />
                )}
                {primaryRecordingCTA ? (
                  <div className="recording-button-wrapper">
                    {getRecordingButton()}
                    {showRecordingCTALoading ? (
                      <img
                        className="record-page-loading"
                        src={process.env.PUBLIC_URL + "/images/Rec_Progress.svg"}
                        alt=""
                      />
                    ) : null}
                  </div>
                ) : null}
              </>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {showWaysToWatch ? (
        <WaysToWatchPrompt
          watchOptions={svodWatchOptions}
          closePrompt={closeWaysToWatchPrompt}
          vodPlaybackType={CONTENT_ITEM_TYPES.episode}
        />
      ) : null}
      {showWaysToOrder && (
        <WaysToOrderPrompt
          orderOptions={purchasePackages}
          onClosePrompt={closeWaysToOrderPrompt}
          onOptionClick={(purchasePackage) => {
            setShowWaysToOrder(false);
            openPurchaseModal(purchasePackage);
          }}
        />
      )}
      {showPurchaseModal && (
        <PurchaseModal
          itemMetadata={
            onDemandOptions?.find((onDemandOption) => {
              return onDemandOption?.id === selectedPurchasePackage?.contentId;
            })?.metadata
          }
          itemImage={getAVSPosterArtImage(catalogInfo, IMAGES.ASPECT_RATIOS.DIM_2x3)}
          closeModal={closePurchaseModal}
          purchaseItem={(selectedPackage, disallowPurchase) => {
            if (userProfile?.user?.profile?.profileData?.purchasePinEnabled === "Y") {
              const modalContent = {
                pinModalMode: PIN_MODAL_MODES.ACCESS,
                pinModalType: PIN_MODAL_TYPES.PURCHASE,
                title: translate("purchase_pin_enter"),
                pinConfirmHandler: (pin) => {
                  return purchaseItem(selectedPackage, pin);
                },
                pinAnalyticsErrorEventHandler: getGenericErrorEventHandler(
                  ANALYTICS_EVENT_TYPES.PURCHASE_PIN_UNLOCK_ERROR,
                  ACTION_VALUES.PURCHASE_PIN_UNLOCK,
                  WEB_ACTION_EVENT_NAMES.PURCHASE_PIN_UNLOCK_ERROR
                ),
              };

              showModalPopup(MODAL_TYPES.PIN, modalContent);

              trackGenericAction(ANALYTICS_EVENT_TYPES.PURCHASE_PIN_LOCK, mediaNodeMetaData);
              if (isConvivaAppTrackerEnabled) {
                trackConvivaCustomEvent(
                  ANALYTICS_EVENT_TYPES.PURCHASE_PIN_LOCK,
                  getCustomContextMetadata(mediaNodeMetaData, userProfile, appProvider, isInHome)
                );
              }
            } else {
              purchaseItem(selectedPackage, undefined, disallowPurchase);
            }
          }}
          purchasePackage={selectedPurchasePackage}
        />
      )}
      {showRecordingModal && selectedAssetRecordingInfo && (
        <RecordingModal
          closeModal={closeRecordingModal}
          scheduleRecordingHandler={scheduleRecording}
          recordingInfo={selectedAssetRecordingInfo}
          editRecordingHandler={editRecordingHandler}
          openCancelRecordingModal={() => {
            if (!isMR) openCancelRecordingModal(selectedAssetRecordingInfo, selectedAssetRecordingInfo.assetToRecord);
          }}
        />
      )}
      <div>
        <Schedules
          schedules={isLookbackEnabled ? schedules : currentAndUpcomingSchedules}
          onTabSelect={setCurrentTabIndex}
          currentTabIndex={currentTabIndex}
          toastClickHandler={schedulesToastClickHandler}
          allOnDemandItems={allOnDemandItems}
          onItemClick={openPurchaseModal}
          openRecordingModal={openRecordingModal}
          isSubscribed={isSubscribed}
          vodPlaybackType={CONTENT_ITEM_TYPES.episode}
          assetDuration={contentMetadata?.metadata?.duration}
          isGeoBlocked={watchOptions?.some((userdata) => userdata?.entitlement?.isGeoBlocked === true)}
        />
      </div>
      {listOfTeams?.length > 0 && (
        <div className="episode-swimlane">
          <Swimlane
            feed={getTeamItems()}
            dimensionConfig={ITEM_TYPES.TITLE_ITEM.LANDSCAPE.DIMENSIONS}
            sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
            ThumbnailComponent={SwimlaneItem}
          />
        </div>
      )}
      {similarItems?.length > 0 && (
        <div className="episode-swimlane">
          <Swimlane
            getSwimlaneRowNum={() => getSwimlaneRowNum(SWIMLANE_TITLES.SIMILAR_ITEMS)}
            feed={getSimilarItems()}
            dimensionConfig={ITEM_TYPES.TITLE_ITEM.PORTRAIT.DIMENSIONS}
            sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
            ThumbnailComponent={SwimlaneItem}
          />
        </div>
      )}

      {castRoles?.length > 0 && (
        <div className="episode-swimlane cast">
          <Swimlane
            getSwimlaneRowNum={() => getSwimlaneRowNum(SWIMLANE_TITLES.CAST_AND_CREW, !!similarItems)}
            feed={getRolesFeed()}
            dimensionConfig={ITEM_TYPES.CHARACTER_ITEM.DIMENSIONS}
            sliderSettings={ITEM_TYPES.CHARACTER_ITEM.CONFIG}
            ThumbnailComponent={CastSwimlaneItem}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

const isPlayableAsset = (assets) => {
  return assets.some(
    (asset) =>
      asset?.assetType?.toLowerCase() === ASSET_TYPES.MASTER && asset?.rights?.toLowerCase() === WATCH_OPTIONS.watch
  );
};

EpisodeDetailPage.propTypes = {
  appProvider: PropTypes.object,
  programContent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  resetAction: PropTypes.func.isRequired,
  loadSimilarItems: PropTypes.func.isRequired,
  similarItems: PropTypes.array,
  featureToggles: PropTypes.object,
};

function mapStateToProps({ app, episodeDetail, recording }) {
  return {
    userProfile: app.userProfile,
    appProvider: app.provider,
    similarItems: episodeDetail.similarItems,
    channelMapInfo: app.channelMapInfo,
    seriesDetails: episodeDetail.seriesContent,
    isInHome: app.isInHome,
    contentUserData: episodeDetail.contentUserData,
    onDemandOptions: episodeDetail.onDemandOptions,
    subscribedChannels: app.subscribedChannels,
    schedules: episodeDetail.schedules,
    recordingsList: recording.recordingsList,
    getRecordingError: recording.getRecordingError,
    setRecordingResponse: recording.setRecordingResponse,
    editRecordingResponse: recording.editRecordingResponse,
    deleteRecordingResponse: recording.deleteRecordingResponse,
    manipulatedRecordingParams: recording.manipulatedRecordingParams,
    totalSimilarItems: episodeDetail.totalSimilarItems,
    featureToggles: app.featureToggles,
    listOfTeams: episodeDetail.listOfTeams,
  };
}

const mapDispatchToProps = {
  resetAction,
  loadSimilarItems,
  loadEpisodeDetailItems,
  loadChannels,
  showToastNotification,
  loadSeriesContent,
  loadPlayOptions,
  loadUserSubscribedChannels,
  loadSchedules,
  showPurchaseAcknowledgement,
  showModalPopup,
  setRecordingAction,
  getRecordingAction,
  showRecordingSettingsPanel,
  setConvivaContentSubType,
  toggleSettingsPanelAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeDetailPage);
