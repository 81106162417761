import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import moment from "moment";
import axios from "axios";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { sanitize } from "dompurify";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";

import {
  showModalPopup,
  toggleSpinningLoaderAction,
  showToastNotification,
  setVideoPlaying,
} from "../../App/state/actions";

import {
  formatSecondsToTimeDisplay,
  showOrHideFullScreen,
  isFullScreen,
  getProgress,
  isPPVEventPurchasable,
} from "../../shared/utils/playerUtils";
import { formatTrackingValue } from "../../shared/utils/analytics";
import VideoPlayerButtonComponent from "../VideoPlayerButton";
import PlayerConstants from "../../shared/constants/player";
import errorConstants from "../../shared/constants/error";
import Document from "../../shared/document";
import middleware from "../../shared/middleware";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../shared/utils/localStorage";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../../shared/utils/sessionStorage";
import { getRecordingInfo, getRecordingCTAs } from "../../shared/utils/recordingHelper";
import { handleImageError, getAVSPosterArtImage } from "../../shared/utils/image";
import useOutsideClickIndicator from "../../shared/hooks/useOutsideClickIndicator";
import useAppLanguage from "../../shared/hooks/useAppLanguage";
import { useReducers } from "../../shared/hooks/useReducer";
import { IsSafari, getAutoGeneratedObject, getAutogeneratedEpisodeString } from "../../shared/utils/index";
import { PlayerEvent, HttpRequestType } from "bitmovin-player";
import ProgressBar from "../ProgressBar";
import VideoSettings from "../VideoSettings";
import VolumeSlideBar from "../VolumeSlideBar";
import { trapFocus, getRandomInt } from "../../shared/utils";
import constants from "../../shared/constants";
import Button from "../Button";
import PlaybackError from "../PlaybackError";
import ToastNotification from "../ToastNotification";
import { createPlayer, loadPlayback } from "../../shared/utils/bitmovinPlayer";

import {
  trackMediaBitrateChange,
  trackMediaBufferComplete,
  trackMediaBufferStart,
  trackMediaComplete,
  trackMediaEnd,
  trackMediaPause,
  trackMediaPlay,
  trackMediaSeek,
  trackMediaSeeked,
  trackMediaError,
  trackPlayerMute,
  trackPlayerClosedCaption,
  trackPlayheadChange,
  getTotalPlayedTimeInSeconds,
  trackConvivaCustomEvent,
  getCustomContextMetadata,
} from "../../shared/analytics/media";
import { trackGenericAction } from "../../shared/analytics/dataLayer";
import { ANALYTICS_EVENT_TYPES, ANALYTICS_STORAGE_KEYS, LINK_INFO } from "../../shared/constants/analytics";
import StillWatchingCard from "./StillWatchingCard";
import "./style.scss";
import telusConvivaAnalytics from "../../shared/utils/convivaAnalytics";
import useSetInterval from "../../shared/hooks/useSetInterval";
import { logNREvent } from "../../shared/analytics/newRelic";
import { logDatadogEvent } from "../../shared/analytics/datadog";
import { NR_CUSTOM_ATTRIBUTES, NR_PAGE_ACTIONS } from "../../shared/constants/newRelic";
import { DD_CUSTOM_ATTRIBUTES, DD_PAGE_ACTIONS } from "../../shared/constants/datadog";
import { reloadApp, isPastProgram } from "../../shared/utils";
import { getPlaybackErrorMessageInfo, isTimestampFromPastDay, isTimestamp } from "../../shared/utils/playbackHelpers";
import storageConstants from "../../shared/constants/storage";
import { getPauseLiveProperties, getFormattedPrice, getPackagePrice } from "../../shared/utils/feedHelper";
import { checkIsStartOverSupported, checkIsLookbackSupported } from "../../shared/utils/epg";

const { VOD, LIVE, RECORDING } = PlayerConstants.PLAYER_TYPE;
const {
  PLAYER_CONTROLS,
  PLAYBACK_TYPES,
  BINGE_WATCH_TIMER,
  BINGE_WATCH_CARD_THRESHOLD,
  DRM_LICENSE_FAIRPLAY,
  BOOKMARK_UPDATE_INTERVAL_TIMER,
  BOOKMARK_TITLE,
  DRM_LICENSE_WIDEVINE,
  PLAYBACK_FORWARD_BUFFER,
} = PlayerConstants;
const { ERROR_TYPES } = errorConstants;
const { fetchVideoUrl, createBookmark, updateBookmark, bookmarkAVSVideo, fetchVideoToken } = middleware;
const { SETTINGS_CONFIG, PAGE_CONTENT_ITEM_TYPES, MODAL_TYPES, IMAGES, REDUCER_TYPE } = constants;

const isSafari = IsSafari();
const placeholder = process.env.PUBLIC_URL + "/images/swimlane-landscape-324px.jpg";
const defaultChannelLogoIcon = process.env.PUBLIC_URL + "/images/default-channel-logo.png";
const { USER_HAS_CLICK, IS_FRESH_LOGIN, VIDEO_AUTH_TOKEN, PLAYER_LOADED } = storageConstants;

/**
 * Video player that streams AMC content for VOD and Live streams. Works with AMC's ericsson DRM
 * @component
 * @param {object} props - StreamInfo, MetadataInfo, style and type ( VOD or Live )
 */
function VideoPlayer(props) {
  const {
    className,
    type,
    currentContentId,
    playbackType,
    recordingButtonClickHandler,
    isMR,
    onPlaybackStateChange,
    control,
    liveProgramDetails,
    onFullScreenClick,
    showListButton,
    handleListIconClick,
    resumeTimeSeconds,
    isSideNavOpen,
    isReadyCallback,
    setIsReadyToChangeStreams,
    showStillWatchingPrompt,
    restartUserInactivityTimer,
    updateVodProgress,
    streamInfo,
    hideListChannel,
    getMediaNodeMetaData = () => null,
    triggerAnalyticsCalls = () => {},
    isProgramChangedRef,
    liveVideoPlayheadFuncRef,
    toggleSpinningLoaderAction,
    setVideoPlaying,
    contentType,
    contentLockedOverlay = null,
    parentalPin = null,
    showToastNotification,
    bookmark,
    livePlaybackCanStart,
    setLiveProgramCanUpdate,
    liveProgramCanUpdate,
    triggerRestart,
    isRestartedStream,
    switchLive,
    isReadyToChangeStreams,
    transitionProgram,
    isNextProgramNonRestartable,
    isLookbackStream,
    resetPlayerPage,
    exitLookbackMode,
    changeLookbackStream,
    ppvPurchasePackage,
    purchaseCallback,
    showModalPopup,
    updateSeasonEntitlements,
    initPlaybackTimestamp,
    nrContentType,
    userProfile,
    convivaContentSubType,
    isPlayerControlsDisable,
    PCBlocked,
    isPlaybackInPanic,
    fetchNextLiveStream,
    triggerLookback,
    exitLookback,
    updatePrevLiveProgramDetails,
    prevLiveProgramDetails,
  } = props;
  const { assetId, externalContentId } = streamInfo;
  const {
    provider: appProvider,
    isInHome,
    toastData,
    playbackError,
    spinningLoaderParams,
    reloadAppFlag,
    featureToggles,
  } = useReducers(REDUCER_TYPE.APP);
  const { recordingsList, manipulatedRecordingParams } = useReducers(REDUCER_TYPE.RECORDING);
  const { onDemandPlayerData } = useReducers(REDUCER_TYPE.ON_DEMAND_PLAYER);
  const seriesDetails = type !== LIVE ? onDemandPlayerData?.seriesDetails : null;
  const { title, subTitle, image, channelNumber, bgImage, extendedMetadata, recordingDeltaTime } = props.metadataInfo;
  const { nextProgram, nextEpisodePoster, loadNextProgram } = props.nextProgramInfo;
  const {
    isChannelTuneTimeEnabled,
    isPauseLiveTVEnabled,
    isRestartLiveTVEnabled,
    isLookbackEnabled,
    isRecordingEnabled,
    isConvivaAppTrackerEnabled,
    isPlaybackRetry,
  } = featureToggles;

  const theme = useTheme();
  const history = useHistory();
  const { appLanguage } = useAppLanguage();

  const [settingsDropdownVisibility, setSettingsDropdownVisibility] = useState(false);
  const [volumeVisibility, setVolumeVisibility] = useState(false);
  const [videoControlsStyle, setVideoControlsStyle] = useState({});
  const [playPauseIcon, setPlayPauseIcon] = useState("/images/playback-pause.svg");
  const [progressStyle, setProgressStyle] = useState({});
  const [elapsedTime, setElapsedTime] = useState("");
  const [remainingTime, setRemainingTime] = useState(null);
  const [showNextEpisodeCard, setShowNextEpisodeCard] = useState(false);
  const [playNextProgram, setPlayNextProgram] = useState(true);
  const [startTime, setStartTime] = useState(props.metadataInfo?.startTime?.programStartTime);
  const [endTime, setEndTime] = useState(
    props.metadataInfo?.startTime?.programStartTime + props.metadataInfo?.duration * 1000
  );
  const [isADAvailable, setADAvailable] = useState(false);
  const [isFFEnabled, setFFEnabled] = useState(false);
  const [isRWEnabled, setRWEnabled] = useState(false);
  const [ffErrorStatus, setFFErrorStatus] = useState(true);
  const [bingeWatchInProgress, setBingeWatchInProgress] = useState(false);
  const [bingeWatchTimer, setBingeWatchTimer] = useState(0);
  const [showBgImage, setShowBgImage] = useState(true);
  const [ccList, setCCList] = useState([]);
  const [isCCAvailable, setIsCCAvailable] = useState(false);
  const [userHasClick, setUserHasClick] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const [isSourceLoaded, setIsSourceLoaded] = useState(false);
  const [programCurrentlyAiring, setProgramCurrentlyAiring] = useState(isLookbackStream ? false : true);
  /*
    recordingAssetElapsedTime and recordingAssetRemainingTime are state variables to store user specific recording asset elapsed time and remaining time.
    They are useful for user specific recording playback progress calculation,
    relative to user specific recording playback startDeltaTime and duration(recordingStopDeltaTime - recordingStartDeltaTime)
    distinguished from the actual elapsedTime/remainingTime relative to full recording playback startTime(recordingStartTime) and duration
  */
  const [recordingAssetElapsedTime, setRecordingAssetElapsedTime] = useState("");
  const [recordingAssetRemainingTime, setRecordingAssetRemainingTime] = useState(null);

  // Adobe analytics based variables for XDM
  const ffCount = useRef(0);
  const rwCount = useRef(0);
  const isRestart = useRef(isRestartedStream ? 1 : 0);
  const chromecast = useRef(0);
  const upNext = useRef(0);
  const closedCaption = useRef(0);
  const hasBookmark = useRef(bookmark ? 1 : 0);
  const describedVideo = useRef(0);
  const hasLookback = useRef(isLookbackStream ? 1 : 0);
  const goLive = useRef(0);

  const isClosedCaptionEnabled = useRef(getLocalStorage(SETTINGS_CONFIG.CLOSED_CAPTION) ?? false);
  const isDescribedVideoEnabled = useRef(getLocalStorage(SETTINGS_CONFIG.DESCRIBED_VIDEO) ?? false);
  const ccRef = useRef([]);
  const playerMgr = useRef(null);
  const videoRef = useRef(null);
  const volumeRef = useRef(null);
  const settingsRef = useRef(null);
  const videoCCContainerRef = useRef(null);
  const videoWrapper = useRef(null);
  const videoControlsTimer = useRef(null);
  const autoPlayTimer = useRef(null);
  const bingeWatchCard = useRef(null);
  const stillWatchingCard = useRef(null);
  const playButtonRef = useRef(null);
  const volumeButtonRef = useRef(null);
  const volumeBarRef = useRef(null);
  const bookmarkTimer = useRef(null);
  const { t: translate } = useTranslation();
  const playbackDeltaTimer = useRef(null);
  const playbackTimeDelta = useRef(0); // how many seconds of actual playback has occurred in a player session
  const activeContentId = useRef(currentContentId);
  const isTriggerAnalyticsCalled = useRef(false);
  const propsRef = useRef(props); // In order to get current props from bitmovin event handler
  const isLive = useRef(false);
  const hasCalledStopContent = useRef(false);
  const landTime = useRef(null);
  const leftMargin = useRef(null);
  const showLiveProgress = useRef(false);
  const autoPlayLiveTimer = useRef(null);
  const hasJumpedToLive = useRef(false);
  const hasNextProgramStarted = useRef(false); // In order to capture PLTV program change event
  const prevEndTime = useRef(null); // Captures endTime state before update
  const prevLiveBufferProgressPercentage = useRef(0); // Locks liveBufferProgressPercentage when current program is ended and playback is paused in current program
  const shouldReloadApp = useRef(false);
  const isTimeShiftAllowed = useRef(false); // boolean ref to act as a check for pltv
  const isStartOverAllowed = useRef(false); // boolean ref to act as a check for restart
  const isCatchUpAllowed = useRef(false); // boolean ref to act as check for lookback
  const isRestartTrickPlayAllowed = useRef(false); // boolean ref to act as a check for restart and lookback trickplay (specifically ability to skip forward)
  const isClosingPlayer = useRef(false); // boolean ref to act as a check if user has opted to close the video player, need this to avoid unnecessary stream transitions
  const startingOverStream = useRef(false); // boolean ref to act as a check if restarting of a live stream is in progress
  const isTimeShifting = useRef(false); // boolean ref to track if a live stream or a start over stream timeshift is in progress
  const isSeeking = useRef(false); // boolean ref to track if a stream seek is in progress
  const blockPlayback = useRef(false);
  const streamPlaybackType = useRef(null); // ref to store the playback type of the loaded stream
  const cancelTokenSource = useRef(axios.CancelToken.source()); // cancelTokenSource ref for requests unmount clean up
  const bookmarkCreated = useRef(bookmark);
  const activeExtendedMetadata = useRef(extendedMetadata);
  const retryTimer = useRef(null);
  const retryMsGenerated = useRef(0);
  const isNextLiveStreamFetched = useRef(false); // ref to track if the details of next live stream in queue are fetched
  const nextLiveProgramDetails = useRef(null); // ref to preserve next live stream details across re-renders
  const liveStreamTransitionInProgress = useRef(false); // ref to indicate if a live stream transition is in progress
  const keyRotationTS = useRef(null);
  const keyRotationJWT = useRef(null);
  const videoProgressMade = useRef(false);
  const playbackTS = useRef(0);
  const pauseTS = useRef(0);
  const pausedDuration = useRef(0);

  const spinnerToggle = useMemo(() => spinningLoaderParams?.toggle, [spinningLoaderParams]);
  const playerLoaderClassName = useMemo(() => `loader-wrapper ${className}`, [className]);

  /* Memoized variable for pause live properties. */
  const pauseLiveProperties = useMemo(
    () => isPauseLiveTVEnabled && getPauseLiveProperties(appProvider),
    [isPauseLiveTVEnabled, appProvider]
  );

  const [showPurchaseCTA, setShowPurchaseCTA] = useState(
    isPPVEventPurchasable(appProvider, ppvPurchasePackage, endTime)
  );

  const videoClassName = className + " videoPlayer";
  const videoControlsName = className + " videoControls";
  const isFreshLogin = getLocalStorage(IS_FRESH_LOGIN);
  const playerLoaded = getSessionStorage(PLAYER_LOADED);
  const initialVolume = getLocalStorage(PLAYER_CONTROLS.VOLUME);
  const [volumeLevel, setVolumeLevel] = useState(initialVolume ? initialVolume : 0);
  const intervalTicker = useSetInterval(1000);

  const isVOD = type === VOD;
  const isRecording = type === RECORDING;
  const videoExitThreshold = isRecording ? 3 : 1; // Due to the unpredictable nature of recordings we need to increase this threshold value to allow player exit
  const isPlayheadAvailable = isFFEnabled && isRWEnabled;
  const isPlayerMute = getLocalStorage(PLAYER_CONTROLS.MUTE) === "true" ? true : false;
  const [isMuted, updateMute] = useState(isPlayerMute);
  const cdnUrl = useRef(null);
  const getPlayheadCallback = useMemo(() => {
    return isPlayheadAvailable ? () => playerMgr.current?.getCurrentTime() : getTotalPlayedTimeInSeconds;
  }, [isPlayheadAvailable]);

  let triggerMediaStop;

  // Calculating remaining time when stream is past 98 percent of it's duration
  const bingeWatchCountdownTime =
    isVOD || isRecording ? ((100 - BINGE_WATCH_CARD_THRESHOLD) * playerMgr?.current?.getDuration()) / 100 : null;

  // Using 98 percent or at minimum of 20 secs (whichever is greater) before the end of stream for displaying binge watch panel
  const bingeWatchThresholdTime =
    bingeWatchCountdownTime && Math.floor(bingeWatchCountdownTime) > BINGE_WATCH_TIMER
      ? bingeWatchCountdownTime
      : BINGE_WATCH_TIMER;

  // Const used to determine true/false if content type is VOD or RECORDING
  const isOnDemandType = type === VOD || type === RECORDING;

  // Const used to determine true/false if content type is fast channel
  const isFast = liveProgramDetails?.channel?.metadata?.extendedMetadata?.isFast === "Y" || false;

  // Const used to determine true/false if content type is LIVE and time shift is allowed
  const checkIfTimeShiftAllowed = useCallback(() => {
    return type === LIVE && isTimeShiftAllowed.current;
  }, [type]);

  // Const used to determine true/false if content type is LIVE and start over is allowed
  const checkIfStartOverAllowed = () => {
    return isRestartLiveTVEnabled ? type === LIVE && isStartOverAllowed.current : false;
  };

  // Const used to determine true/false if content type is LIVE and catch up is allowed
  const checkIfCatchUpAllowed = () => {
    return type === LIVE && isLookbackStream && isCatchUpAllowed.current;
  };

  const isDynamicAsset = () => {
    return playerMgr?.current?.getDuration() === Infinity;
  };

  const isDynamicRecordingType = type === RECORDING && isLive.current ? true : false;

  /* Memoized recordingStartDeltaTime. This variable is to determine recording playback's intial playhead, calculated using startDeltaTime property of metadata from milliseconds to seconds */
  const recordingStartDeltaTime = useMemo(() => {
    if (isRecording && recordingDeltaTime) {
      return recordingDeltaTime.startDeltaTime > 0 ? recordingDeltaTime.startDeltaTime / 1000 : 1; // startOffset in Bitmovin player SourceConfigOptions is required to be positive, so playback shifts/seeks to at least 1 second into user recorded portion. This is acceptable as per discussion with architect.
    }
    return null;
  }, [recordingDeltaTime, isRecording]);

  /* Memoized recordingStopDeltaTime. This variable is to determine recording playback's actual duration, calculated using stopDeltaTime property of metadata from milliseconds to seconds */
  const recordingStopDeltaTime = useMemo(() => {
    if (isRecording && recordingDeltaTime) {
      return recordingDeltaTime.stopDeltaTime / 1000;
    }
    return null;
  }, [recordingDeltaTime, isRecording]);

  /* Memoized user specific recording start time. */
  const userRecordingStartTime = useMemo(() => {
    if (isRecording && recordingDeltaTime) {
      return (
        recordingDeltaTime.recordingStartTime +
        (recordingDeltaTime.startDeltaTime > 0 ? recordingDeltaTime.startDeltaTime : 1000)
      ); // minimum 1 second from user's recording start delta, see recordingStartDeltaTime variable definition
    }
    return null;
  }, [recordingDeltaTime, isRecording]);

  /* Memoized user specific recording stop time. */
  const userRecordingStopTime = useMemo(() => {
    if (isRecording && recordingDeltaTime) {
      return recordingDeltaTime.recordingStartTime + recordingDeltaTime.stopDeltaTime;
    }
    return null;
  }, [recordingDeltaTime, isRecording]);

  const recordingDuration = useMemo(() => {
    return recordingStopDeltaTime - recordingStartDeltaTime;
  }, [recordingStartDeltaTime, recordingStopDeltaTime]);

  // Due to the nature of live stream segments and recording prepadding, the duration calculated from recordingStartDeltaTime and recordingStopDeltaTime is not accurate. This variable is to determine the actual duration of the recording playback.
  const recordingPlaybackDuration = useRef(recordingDuration);

  const hasRecordingDeltaTime = () => {
    return recordingStartDeltaTime !== null && recordingStopDeltaTime !== null;
  };

  const getRecordingStartOffset = () => {
    let startOffset = 0; // init offset to 0
    if (hasRecordingDeltaTime() && playerMgr.current) {
      // deltaTime is set so this is playing recording asset
      startOffset =
        -(moment().valueOf() - (recordingDeltaTime.recordingStartTime + recordingDeltaTime.startDeltaTime)) / 1000; // set offset to user specific recording startDeltaTime
      const maxTimeShift = playerMgr.current.getMaxTimeShift(); // For dynamic asset, each live stream playback has a max limit in seconds for time shift. In our case (PLTV feature), current limit is set to 3560 seconds, this could change in the future
      startOffset = Math.abs(startOffset) < Math.abs(maxTimeShift) ? startOffset : maxTimeShift; // if the user's specfic recording offset value is greater than max shift limit, we have to set offset to the max shift limit instead
    }
    return startOffset;
  };

  /* const used to check if the current playback time of the live stream differs from the on now live position. */
  const isNotAtLivePosition = useCallback(() => {
    // variable to store current playback time
    const playHead = playerMgr.current?.getCurrentTime();

    /*
      For unknown reason, HLS stream returns non-unix-timestamp random numbers at the beginning of segments loading, which causes "jump to live" button to display for a split second.
      So a check by invoking isTimestampFromPastDay function will ensure the jump-to-live button won't show up in Safari for a normal PLTV live stream.(Addresses TSQA-20704)
    */
    if (isTimestampFromPastDay(playHead) && !isRestartedStream) return false;

    return Math.abs(playHead - (moment().valueOf() - PLAYBACK_FORWARD_BUFFER) / 1000) > 45;
  }, [isRestartedStream]);

  /**
   * Builds the bookmark object that should be passed in the bookmark & stop content API payloads
   *
   * @param {Boolean} isUpdatingBookmark Flag used to differentiate between create & update bookmark structures
   * @returns {Object} object with details to create/update a bookmark
   */
  const buildBookmark = useCallback(
    (isUpdatingBookmark = false) => {
      let bookmarkInfo;
      if (isOnDemandType && playerMgr?.current) {
        const currentTime = playerMgr.current.getCurrentTime();
        if (currentTime) {
          const finishedThreshold =
            recordingStopDeltaTime !== null
              ? recordingStopDeltaTime - videoExitThreshold
              : (BINGE_WATCH_CARD_THRESHOLD / 100) * playerMgr.current.getDuration();
          // Previously, the bookmark startDeltaTime is relative to beginning of playback segments for both VOD and recording assets. Currently, the recording bookmark startDeltaTime needs to be relative to recordingStartDeltaTime.
          bookmarkInfo = {
            bookmarkTitle: BOOKMARK_TITLE,
            startDeltaTime:
              recordingStartDeltaTime !== null
                ? Math.round(currentTime - recordingStartDeltaTime)
                : Math.round(currentTime),
            isComplete: currentTime > finishedThreshold,
          };

          if (isUpdatingBookmark) {
            // payload will be used for PUT BOOKMARKS call
            if (bookmarkCreated.current) {
              bookmarkInfo.bookmarkId = bookmarkCreated.current.bookmarkId;
              bookmarkInfo.bookmarkTitle = bookmarkCreated.current.bookmarkTitle || BOOKMARK_TITLE;
            } else {
              console.error("Attempting to update a bookmark, but no existing bookmark was found");
            }
          } else {
            // payload will either be used for stop content or POST BOOKMARKS call
            if (activeExtendedMetadata.current?.dlum) {
              bookmarkInfo.uaId = activeExtendedMetadata.current.dlum.uaId;
              if (playbackType === PLAYBACK_TYPES.EPISODE) {
                bookmarkInfo.uaSeriesId =
                  activeExtendedMetadata.current.dlum.series?.uaId || activeExtendedMetadata.current.dlum.uaGroupId;
                bookmarkInfo.season = parseInt(activeExtendedMetadata.current.season) || undefined;
                bookmarkInfo.episodeNumber = parseInt(activeExtendedMetadata.current.episodeNumber) || undefined;
              }
            } else {
              console.error("Attempting to create a new bookmark, but no DLUM data is available for the content");
            }

            //This is specific to cPVR where we're required to pass the externalContentId to bookmark call
            if (type === RECORDING) {
              bookmarkInfo.externalContentId = externalContentId;
            }
          }
        } else {
          console.error("Could not determine the current playback position for bookmark payload");
        }
      }

      return bookmarkInfo;
    },
    [
      isOnDemandType,
      recordingStopDeltaTime,
      videoExitThreshold,
      recordingStartDeltaTime,
      type,
      playbackType,
      externalContentId,
    ]
  );

  /**
   * Function to make the Stop Content call for AVS backend. If current content is VOD,
   * save bookmark info too.
   */
  const handleAVSStopContent = useCallback(
    (playerError = false, newContentId = null) => {
      if (appProvider && activeContentId.current && !hasCalledStopContent.current) {
        hasCalledStopContent.current = true;
        const contentId = activeContentId.current;
        activeContentId.current = newContentId;
        if (playbackType !== PLAYBACK_TYPES.TRAILER) {
          const requestBody = {
            deltaThreshold: playbackTimeDelta.current,
            bookmark: buildBookmark(),
          };

          toggleSpinningLoaderAction(true, playerLoaderClassName);

          if ((isRestartedStream || isLookbackStream) && liveProgramDetails) {
            const requestBody = {
              deltaThreshold: null,
            };
            return bookmarkAVSVideo(
              appProvider,
              liveProgramDetails.channel?.id,
              PAGE_CONTENT_ITEM_TYPES.program,
              requestBody
            ).finally(() => {
              // Allow loading a different stream when the stop content call has completed
              setIsReadyToChangeStreams && setIsReadyToChangeStreams(true);
              if (!playerError && !startingOverStream.current && !isClosingPlayer.current) {
                if (isLookbackStream && !exitLookbackMode) {
                  changeLookbackStream(liveProgramDetails);
                } else {
                  switchLive();
                }
              }
              hasCalledStopContent.current = true;
            });
          } else {
            // NOTE: Bookmarking also does stopContent AKA reducing concurrent stream count
            return bookmarkAVSVideo(appProvider, contentId, type, requestBody)
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                hasCalledStopContent.current = false;
                // Allow loading a different stream when the stop content call has completed
                setIsReadyToChangeStreams && setIsReadyToChangeStreams(true);
                if (type === VOD && playbackType === PLAYBACK_TYPES.EPISODE) updateSeasonEntitlements();
                hasCalledStopContent.current = true;
              });
          }
        }
      }
      return Promise.resolve();
    },
    [
      appProvider,
      playerLoaderClassName,
      isRestartedStream,
      liveProgramDetails,
      playbackType,
      switchLive,
      setIsReadyToChangeStreams,
      toggleSpinningLoaderAction,
      type,
      buildBookmark,
      isLookbackStream,
      changeLookbackStream,
      exitLookbackMode,
      updateSeasonEntitlements,
    ]
  );

  // function to stop the current dynamic stream and direct player to restart the stream
  const startOverStream = useCallback(
    (transitionLookbackStream = false) => {
      if (type === VOD || (isLookbackStream && !transitionLookbackStream)) {
        // for on demand or catch up streams
        playerMgr.current.seek(0);
      } else if (type === RECORDING && !playerMgr.current?.isLive()) {
        // for already recorded streams
        playerMgr.current.seek(recordingStartDeltaTime);
      } else {
        setIsReadyToChangeStreams(false);
        handleAVSStopContent(); // release stream count if user restarts the stream, to avoid concurrent stream errors
        startingOverStream.current = true;
        triggerRestart(liveProgramDetails);
      }
    },
    [
      handleAVSStopContent,
      setIsReadyToChangeStreams,
      triggerRestart,
      liveProgramDetails,
      isLookbackStream,
      type,
      recordingStartDeltaTime,
    ]
  );

  /** Dynamic partial recording playback start flag */
  let recordingLiveAssetStarted = false;

  useEffect(() => {
    if (!telusConvivaAnalytics.isConvivaAnalyticsInitialized()) {
      telusConvivaAnalytics.initConvivaAnalytics(appProvider, userProfile);
    }

    telusConvivaAnalytics.reportParentalPinSuccess();

    telusConvivaAnalytics.initVideoSession(props, convivaContentSubType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (recordingDuration && recordingDuration > 0) {
      setRecordingAssetRemainingTime(recordingDuration);
    }
  }, [recordingDuration]);

  useEffect(() => {
    if (liveProgramDetails && playerMgr.current) {
      propsRef.current = { ...props, cdnUrl: cdnUrl.current };
      telusConvivaAnalytics.onPlayNextLive(propsRef.current, playerMgr.current);
    }
    // onPlayNextLive should only be triggered when liveProgramDetails changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveProgramDetails]);

  useEffect(() => {
    if (liveVideoPlayheadFuncRef && !liveVideoPlayheadFuncRef.current) {
      liveVideoPlayheadFuncRef.current = getTotalPlayedTimeInSeconds;
    }
  }, [liveVideoPlayheadFuncRef]);

  useEffect(() => {
    if (!isTriggerAnalyticsCalled.current) {
      isTriggerAnalyticsCalled.current = true;
      triggerAnalyticsCalls();
    }
  }, [triggerAnalyticsCalls]);

  useEffect(() => {
    if (isOnDemandType) {
      playbackType !== "episode" && setPlayNextProgram(false);
      playButtonRef.current && playButtonRef.current.focus();
    } else {
      volumeButtonRef.current && volumeButtonRef.current.focus();
    }
    if (playerLoaded && playerLoaded === "true") {
      if (window.location.hash.includes("player")) {
        if (isFreshLogin && isFreshLogin === "true") {
          history.push("/");
        } else {
          history.goBack();
        }
        removeSessionStorage(PLAYER_LOADED);
      }
    } else {
      setSessionStorage(PLAYER_LOADED, "true");
    }
    const unmountTokenSource = cancelTokenSource.current;
    return () => {
      setVideoPlaying({ playing: false });
      if (bookmarkTimer.current) {
        clearInterval(bookmarkTimer.current);
      }
      if (videoControlsTimer.current) {
        clearTimeout(videoControlsTimer.current);
      }

      if (autoPlayTimer.current) {
        clearTimeout(autoPlayTimer.current);
      }

      trackMediaEnd();

      // make the stop content call when component is unmounted
      controlPlaybackDeltaTimer(false);
      handleAVSStopContent();

      playerMgr.current.destroy();
      removeSessionStorage(PLAYER_LOADED);
      setCCList([]);
      toggleSpinningLoaderAction(false);
      unmountTokenSource.cancel(); // silent cancellation of middleware requests
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    shouldReloadApp.current = reloadAppFlag;
    return () => {
      if (shouldReloadApp.current) {
        reloadApp(appProvider);
      }
    };
  }, [appProvider, reloadAppFlag]);

  // update live stream transition indicator when live stream details are updated
  useEffect(() => {
    if (
      prevLiveProgramDetails !== null &&
      (prevLiveProgramDetails.isLookback || prevLiveProgramDetails.isRestarted) &&
      liveProgramDetails.id !== prevLiveProgramDetails.prevLiveProgram.id
    ) {
      liveStreamTransitionInProgress.current = true;
    }
  }, [liveProgramDetails, prevLiveProgramDetails]);

  useEffect(() => {
    if (liveProgramDetails) {
      if (liveStreamTransitionInProgress.current) {
        if (isPastProgram(liveProgramDetails.metadata?.airingEndTime)) {
          handleAVSStopContent(null, currentContentId);
        } else {
          const isNextProgramRestartable = checkIsStartOverSupported(
            liveProgramDetails.metadata,
            liveProgramDetails.channel,
            isInHome
          );
          transitionProgram(isNextProgramRestartable);
          if (isNextProgramRestartable) {
            startOverStream(true);
          }
        }
        liveStreamTransitionInProgress.current = false;
      }
      // We attempt to set hasNextProgramStarted.current = true to update the leftMargin but
      // issue with this is that we can't ensure this is called in a timely manner that lines up with the endtime.
      // Endtime could be updated before we reach the end of the current endTime.
      if (!isOnDemandType && playerMgr?.current?.getCurrentTime() >= endTime / 1000)
        hasNextProgramStarted.current = true; // Current playhead passes program end time
      if (videoStarted) {
        triggerMediaStop();
      }
      setStartTime(liveProgramDetails.metadata?.airingStartTime);
      setEndTime(liveProgramDetails.metadata?.airingEndTime);
    }
    trapFocus(videoWrapper.current);
  }, [
    liveProgramDetails,
    startOverStream,
    transitionProgram,
    isInHome,
    handleAVSStopContent,
    currentContentId,
    endTime,
    isOnDemandType,
    triggerMediaStop,
    videoStarted,
  ]);

  const recordingInfo = useMemo(() => {
    if (type === LIVE && isRecordingEnabled && recordingsList && liveProgramDetails?.channel?.metadata?.isRecordable) {
      return getRecordingInfo(isMR, liveProgramDetails, recordingsList, appProvider?.channelMapId);
    }

    return null;
  }, [appProvider, isRecordingEnabled, isMR, liveProgramDetails, recordingsList, type]);

  // Used to display the loading spinner on the player recording button, as appropriate
  const showRecordingCTALoading =
    manipulatedRecordingParams &&
    recordingInfo &&
    manipulatedRecordingParams.targetAssetInfo.id === recordingInfo.assetToRecord.id;

  const handleOutsideVolumeClick = () => {
    setVolumeVisibility(false);
  };
  useOutsideClickIndicator(volumeRef, handleOutsideVolumeClick);

  const handleOutsideSettingsClick = () => {
    setSettingsDropdownVisibility(false);
  };
  useOutsideClickIndicator(settingsRef, handleOutsideSettingsClick);

  // Make the stop content call any time the current content ID changes
  useEffect(() => {
    controlPlaybackDeltaTimer(false);
    //When currentContentId changes, we need to perform a AVSStopContent call and update the activeContentId
    if (!hasCalledStopContent.current && activeContentId.current !== currentContentId) {
      handleAVSStopContent(false, currentContentId);
    }

    if (playerMgr.current) {
      playerMgr.current.unload();
    }

    if ((isSafari && getSessionStorage(USER_HAS_CLICK) === true) || !isSafari) {
      setUserHasClick(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContentId]);

  // Code relaying to cpix and removal of pssh boxes
  const extractAsciiString = (dataView, position, length) => {
    const to = position + length;
    let str = "";
    for (; position < to; position++) {
      str += String.fromCharCode(dataView.getUint8(position));
    }
    return str;
  };

  // Code relaying to cpix and removal of pssh boxes
  const parseBoxes = (dataView) => {
    const boxes = [];
    let offset = 0;

    while (offset + 8 < dataView.byteLength) {
      const size = dataView.getUint32(offset);
      const type = extractAsciiString(dataView, offset + 4, 4);
      const data = new DataView(dataView.buffer, dataView.byteOffset + offset, size); // including box size and type (8 bytes)
      const payload = new DataView(dataView.buffer, dataView.byteOffset + offset + 8, size - 8);
      boxes.push({ type, data, payload });
      offset += size;
    }

    return boxes;
  };

  // Player retry for fetching a new JWT and manifest
  const performRetry = async () => {
    const response = await fetchVideoUrl(appProvider, contentType, currentContentId, assetId, parentalPin);
    retryTimer.current = null;
    if (response.src && response.token) {
      toggleSpinningLoaderAction(false);
      initializePlayer(response.src, response.token);
    } else {
      createRetryTimer();
    }
  };

  // Player retry logic base on offset interval
  const createRetryTimer = () => {
    if (retryTimer.current === null && retryMsGenerated.current < isPlaybackRetry?.retry_threshold_ms) {
      const timerVal = getRandomInt(isPlaybackRetry?.start_offset_ms, isPlaybackRetry?.end_offset_ms);
      retryMsGenerated.current += timerVal;
      retryTimer.current = setTimeout(performRetry, timerVal);
    }
  };

  // Used to prevent playback when PC check fails
  useEffect(() => {
    if (!PCBlocked) {
      blockPlayback.current = false;
    } else {
      blockPlayback.current = true;
    }
  }, [PCBlocked]);

  const initializePlayer = (manifest = null, jwtToken = null) => {
    videoProgressMade.current = false;
    const manifestURL = manifest ? manifest : streamInfo.manifestURL;
    const authToken = jwtToken ? jwtToken : streamInfo.authToken;
    const { playbackType, drmType, LA_URL, LA_CERT_URL } = streamInfo;
    const source = {};
    const drm = {};

    drm[drmType] = {
      LA_URL,
      headers: {
        authorization: authToken,
        "Content-Type": "application/json",
      },
      withCredentials: true,
      prepareContentId: null,
      prepareMessage: null,
      prepareLicense: null,
    };

    if (LA_CERT_URL) {
      drm[drmType].certificateURL = LA_CERT_URL;
    }

    if (drmType === "fairplay") {
      drm[drmType].prepareContentId = (contentId) => {
        return contentId.slice(8);
      };
      drm[drmType].prepareMessage = (event, session) => {
        return JSON.stringify({
          spc: event.messageBase64Encoded,
        });
      };
      drm[drmType].prepareLicense = (data) => {
        let parsed = JSON.parse(data);
        return parsed.ckc;
      };
      drm[drmType].useUint16InitData = true;

      source["playback"] = {
        audioCodecPriority: ["ac-3", "ec-3", "mp4a.a6", "mp4a.a5", "mp4a.40"],
        autoplay: true,
      };
    }

    source[playbackType] = manifestURL;
    source["drm"] = drm;
    const preprocessHttpRequestFn = async (type, request) => {
      if ((type === DRM_LICENSE_WIDEVINE || type === DRM_LICENSE_FAIRPLAY) && playerMgr?.current?.isPlaying()) {
        let allowFetch = false;
        let fetchTS;
        if (keyRotationTS.current === null) {
          keyRotationTS.current = moment().unix();
          allowFetch = true;
        }
        fetchTS = moment().unix();

        // Prevent a second JWT fetch if the request is close to the previous one
        if (fetchTS - keyRotationTS.current > 20) allowFetch = true;

        if (allowFetch) {
          keyRotationTS.current = fetchTS;
          // for LIVE content, we use faster VIDEOURL/TOKEN API to get JWT token
          let response;
          try {
            response =
              contentType === "LIVE" && isChannelTuneTimeEnabled
                ? await fetchVideoToken(appProvider, contentType, currentContentId, cancelTokenSource)
                : await fetchVideoUrl(appProvider, contentType, currentContentId, assetId, parentalPin, true);
          } catch (err) {
            console.error("Failed to get auth token, error = ", err);
          }

          keyRotationJWT.current = response?.token;
          request.credentials = "include";
          request.headers["authorization"] = response?.token;
          console.log("Key rotation attempt was made");
        } else if (keyRotationJWT.current) {
          // This is a work around fix to address a bitmovin bug where it's making double dash key rotations
          request.credentials = "include";
          request.headers["authorization"] = keyRotationJWT.current;
        }
      }
      if (
        (type === DRM_LICENSE_WIDEVINE || type === DRM_LICENSE_FAIRPLAY) &&
        contentType === "LIVE" &&
        !playerMgr?.current?.isPlaying()
      ) {
        const cachedAuthToken = getLocalStorage(VIDEO_AUTH_TOKEN); // For LIVE content, check if cached JWT token is available
        if (cachedAuthToken) {
          request.credentials = "include";
          request.headers["authorization"] = cachedAuthToken;
        } else if (isChannelTuneTimeEnabled) {
          // if cached JWT token is unavailable at the moment of license request, send a VIDEOURL/TOKEN call to get new token
          let response;
          try {
            response = await fetchVideoToken(appProvider, contentType, currentContentId, cancelTokenSource);
          } catch (err) {
            console.error("Failed to get auth token, error = ", err);
          }
          request.credentials = "include";
          request.headers["authorization"] = response?.token;
        }
      }
      return Promise.resolve(request);
    };

    const prepprpcessHttpResponseFn = async (type, response) => {
      if (!isSafari && isFast) {
        return Promise.resolve(response);
      }
      // The CPIX remove pssh boxes code causes non-DRM HLS content to fail to play in Chrome, so we need to disable it for FAST channel HSL stream playback
      if (type === "media/audio" || type === "media/video") {
        const buffer = response.body;
        const moov = parseBoxes(new DataView(buffer)).find((box) => box.type === "moov");

        if (moov && !manifestURL?.includes("dashclr") && (contentType === "LIVE" || contentType === "RECORDING")) {
          parseBoxes(moov.payload)
            .filter((box) => box.type === "pssh")
            .forEach(({ data }) => {
              // rewrite 'pssh' box type to 'free' box so it will be ignored
              data.setUint8(4, "f".charCodeAt(0));
              data.setUint8(5, "r".charCodeAt(0));
              data.setUint8(6, "e".charCodeAt(0));
              data.setUint8(7, "e".charCodeAt(0));
            });
        }
      }

      if (type === HttpRequestType.MANIFEST_DASH) {
        cdnUrl.current = response.url;
      }
      return Promise.resolve(response);
    };

    const networkConfig = {
      preprocessHttpRequest: preprocessHttpRequestFn,
      preprocessHttpResponse: prepprpcessHttpResponseFn,
    };

    /** Updated partial recording playback start POS manipulation,
     * see https://bitmovin.com/docs/player/api-reference/web/web-sdk-v8-api-source-configuration#/player/web/8/docs/interfaces/core_config.sourceconfigoptions.html#startoffset
     */
    if (isRecording && recordingDeltaTime) {
      source["options"] = {
        startOffset: recordingStartDeltaTime,
        startOffsetTimelineReference: "start",
      };
    }
    setIsSourceLoaded(false);

    if (isRestartedStream) {
      source["options"] = {
        startOffset: 2,
        startOffsetTimelineReference: "start",
      };
    }

    loadPlayerSource(source, networkConfig);
  };

  useEffect(() => {
    if (!streamInfo) return;
    bookmarkCreated.current = bookmark;
    activeExtendedMetadata.current = extendedMetadata;
    streamPlaybackType.current = playbackType;

    toggleSpinningLoaderAction(true, playerLoaderClassName);
    setBingeWatchInProgress(false);
    setBingeWatchTimer(0);
    setShowBgImage(true);
    autoPlayTimer.current = null;
    autoPlayLiveTimer.current = null;
    clearTimeout(autoPlayLiveTimer.current);
    setProgressStyle(0);
    if (updateVodProgress) updateVodProgress(0);
    setElapsedTime("");
    setCCList([]);
    setPlayNextProgram(true);
    ccRef.current = [];
    playbackTimeDelta.current = 0;
    isNextLiveStreamFetched.current = false;
    if (liveProgramDetails) {
      updatePrevLiveProgramDetails(liveProgramDetails, isLookbackStream, isRestartedStream);
    }
    if (isRestartedStream) {
      landTime.current = liveProgramDetails?.metadata?.airingStartTime;
    } else {
      landTime.current = null;
    }
    showLiveProgress.current = false;

    initializePlayer();

    hasCalledStopContent.current = false;

    if (isOnDemandType || checkIfTimeShiftAllowed()) {
      setPlayPauseIcon("/images/playback-pause.svg");
    }

    if (isMuted) {
      playerMgr.current.setVolume(0);
      updateMute(true);
      setLocalStorage(PLAYER_CONTROLS.MUTE, "true");
    } else if (volumeLevel) {
      playerMgr.current.setVolume(volumeLevel >= 100 ? 100 : volumeLevel);
    } else if (isMuted || getLocalStorage(PLAYER_CONTROLS.MUTE) === "true") {
      playerMgr.current.setVolume(0);
    } else {
      playerMgr.current.setVolume(100);
    }

    setLocalStorage(PLAYER_CONTROLS.VOLUME, playerMgr.current.getVolume());
    setVolumeLevel(playerMgr.current.getVolume());
    !isLookbackStream && setProgramCurrentlyAiring(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamInfo]);

  // Side effect to control live playback a/v output
  useEffect(() => {
    if (contentType === "LIVE" && isSourceLoaded) {
      if (!livePlaybackCanStart || playbackError?.code || playbackError?.message) {
        if (playerMgr.current && !playerMgr.current.destroyed) {
          playerMgr.current.pause();
        }
      } else {
        if (playerMgr.current && !playerMgr.current.destroyed) {
          playerMgr.current.play().catch((error) => {
            // This error handler was added to handle the promise rejection from this issue: https://developer.chrome.com/blog/play-request-was-interrupted/
            // TODO: Handle the pause/play and load/play race conditions properly when refactoring the player
            console.error(error);
          });
        }
      }
    }
  }, [contentType, livePlaybackCanStart, isSourceLoaded, playbackError]);

  // Handle External Player Controls for Stop, Play and Pause of the video player
  useEffect(() => {
    setVideoPlayerControlState(playerMgr.current, control);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control]);

  useEffect(() => {
    if (type === LIVE) {
      return;
    }
    if (
      playNextProgram &&
      nextProgram &&
      !bingeWatchInProgress &&
      remainingTime > 0 &&
      remainingTime <= bingeWatchThresholdTime &&
      !bingeWatchTimer
    ) {
      remainingTime < BINGE_WATCH_TIMER ? setBingeWatchTimer(remainingTime - 1) : setBingeWatchTimer(BINGE_WATCH_TIMER);
      setShowNextEpisodeCard(true);
    }
    if (
      (!playNextProgram || (playNextProgram && !nextProgram)) &&
      remainingTime &&
      remainingTime < videoExitThreshold
    ) {
      history.goBack();
    }

    if (
      playNextProgram &&
      nextProgram &&
      ((remainingTime && remainingTime < 1) || (recordingAssetRemainingTime && recordingAssetRemainingTime < 1))
    ) {
      telusConvivaAnalytics.onPlayNextProgram();
      loadNextProgram && loadNextProgram(true, () => loadNextEpisodeCallback(true));
    }
    if (remainingTime > bingeWatchThresholdTime) {
      if (autoPlayTimer.current) {
        clearTimeout(autoPlayTimer.current);
        autoPlayTimer.current = null;
      }
      setBingeWatchTimer(0);
      setShowNextEpisodeCard(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  useEffect(() => {
    if (playNextProgram && bingeWatchTimer) {
      if (!autoPlayTimer.current) {
        autoPlayTimer.current = setTimeout(() => {
          telusConvivaAnalytics.onPlayNextProgram();
          loadNextProgram && loadNextProgram(true, () => loadNextEpisodeCallback(true));
          setShowNextEpisodeCard(false);
        }, bingeWatchTimer * 1000);
      }
    }
    if (!playNextProgram && autoPlayTimer.current) {
      clearTimeout(autoPlayTimer.current);
    }
    if (bingeWatchInProgress) {
      clearTimeout(autoPlayTimer.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playNextProgram, bingeWatchInProgress, bingeWatchTimer]);

  useEffect(() => {
    videoWrapper.current.focus();
  }, [isSideNavOpen]);

  useEffect(() => {
    if (showNextEpisodeCard && bingeWatchCard && bingeWatchCard.current) {
      setVideoControlsStyle({ opacity: 0, visibility: "hidden" });
      trapFocus(bingeWatchCard.current);
      bingeWatchCard.current.focus();
    }
  }, [showNextEpisodeCard]);

  useEffect(() => {
    if (showStillWatchingPrompt) {
      // cancel binge watch
      setShowNextEpisodeCard(false);
      if (autoPlayTimer.current) {
        clearTimeout(autoPlayTimer.current);
      }

      // when showing the still watching prompt, hide controls and focus the prompt
      if (stillWatchingCard.current) {
        setVideoControlsStyle({ opacity: 0, visibility: "hidden" });
        trapFocus(stillWatchingCard.current);
        stillWatchingCard.current.focus();
      }
    }
  }, [showStillWatchingPrompt]);

  useEffect(() => {
    if (intervalTicker > 0 && playerMgr.current) {
      setShowPurchaseCTA(isPPVEventPurchasable(appProvider, ppvPurchasePackage, endTime));
      trackPlayheadChange(isPlayheadAvailable, getPlayheadCallback);
    }
  }, [appProvider, endTime, getPlayheadCallback, intervalTicker, isPlayheadAvailable, ppvPurchasePackage]);

  const currentPlaybackTime = playerMgr.current?.getCurrentTime() * 1000;

  useEffect(() => {
    if (checkIfTimeShiftAllowed()) {
      if (landTime.current === null && currentPlaybackTime > 0 && !isTimestampFromPastDay(currentPlaybackTime)) {
        const initialLandTime = Math.max(currentPlaybackTime, startTime);
        landTime.current = initialLandTime;
        /**
         * When live program changes, we need to make sure the new program progress is initiated at 0. This will prevent progress toolkit from shifting back
         */
        leftMargin.current = hasNextProgramStarted.current
          ? 0
          : ((currentPlaybackTime - startTime) / (endTime - startTime)) * 100;
      } else {
        /**
         * Same as above comment, to prevent progress toolkit from shifting back
         */

        // This address the potential issue where end time and start time is update before we can actually update the landTime
        if (landTime.current !== null && landTime.current < startTime) {
          landTime.current = startTime;
        }

        leftMargin.current = hasNextProgramStarted.current
          ? 0
          : ((landTime.current - startTime) / (endTime - startTime)) * 100;
      }
      hasNextProgramStarted.current = false;
      if (!playerMgr.current.destroyed && playerMgr.current?.isPaused() && currentPlaybackTime <= landTime.current) {
        landTime.current = currentPlaybackTime;
        setVideoPlayerControlState(playerMgr.current, PLAYER_CONTROLS.RESUME);
        setPlayPauseIcon("/images/playback-pause.svg");
      }
    }
  }, [endTime, startTime, currentPlaybackTime, checkIfTimeShiftAllowed, isOnDemandType]);

  useEffect(() => {
    if (hasJumpedToLive.current) {
      if (!playerMgr.current.destroyed && playerMgr.current?.isPaused()) {
        showLiveProgress.current = false;
      }
      landTime.current = startTime;
      hasJumpedToLive.current = false;
      hasNextProgramStarted.current = false;
    }
  }, [startTime]);

  useEffect(() => {
    if (liveProgramDetails) {
      if (isPauseLiveTVEnabled && liveProgramDetails.channel?.metadata?.isTimeshift) {
        isTimeShiftAllowed.current = true;
      } else {
        isTimeShiftAllowed.current = false;
      }
    }
  }, [liveProgramDetails, isPauseLiveTVEnabled]);

  useEffect(() => {
    if (liveProgramDetails && (isRestartLiveTVEnabled || isLookbackEnabled)) {
      if (isLookbackStream) {
        if (checkIsLookbackSupported(liveProgramDetails.metadata, liveProgramDetails.channel, isInHome)) {
          isCatchUpAllowed.current = true;
          if (liveProgramDetails.channel?.metadata?.isSOTVTrickplayEnabled) {
            isRestartTrickPlayAllowed.current = true;
          }
        }
      } else if (checkIsStartOverSupported(liveProgramDetails.metadata, liveProgramDetails.channel, isInHome)) {
        isStartOverAllowed.current = true;
        if (liveProgramDetails.channel?.metadata?.isSOTVTrickplayEnabled) {
          isRestartTrickPlayAllowed.current = true;
        }
      } else {
        isStartOverAllowed.current = false;
        isCatchUpAllowed.current = false;
        isRestartTrickPlayAllowed.current = false;
      }
    }
  }, [liveProgramDetails, isRestartLiveTVEnabled, isInHome, isLookbackEnabled, isLookbackStream]);

  /**
   * make stop content call when exiting lookback mode,
   * to close the current lookback stream and avoid concurrent stream errors
   */
  useEffect(() => {
    if (exitLookbackMode) {
      if (hasCalledStopContent.current) hasCalledStopContent.current = false;
      handleAVSStopContent();
    }
  }, [exitLookbackMode, handleAVSStopContent]);

  /**
   * a callback function that will be triggered once next episode has been loaded
   * @param {Boolean} isSessionCompleted if true, the current media has been completely viewed
   * @param {String} linkName a link(button) that was clicked to load next episode
   */
  const loadNextEpisodeCallback = (isSessionCompleted = false, linkName = null) => {
    if (isSessionCompleted) trackMediaComplete();
    else trackMediaEnd();

    // set link to be an empty string when next episode was auto-played
    setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, linkName ? `${linkName};${LINK_INFO.ON_DEMAND_PLAY}` : "");
  };

  const updateBookmarkInfo = useCallback(() => {
    // Update bookmark API
    return updateBookmark(
      appProvider,
      type,
      activeContentId.current,
      bookmarkCreated.current?.bookmarkSet?.bookmarkSetId,
      {
        bookmark: buildBookmark(true),
      }
    ).catch((err) => {
      console.error(err);
    });
  }, [appProvider, type, buildBookmark]);

  const createBookmarkInfo = useCallback(() => {
    // Create bookmark API
    return createBookmark(appProvider, type, activeContentId.current, { bookmark: buildBookmark() }).catch((err) => {
      console.error(err);
    });
  }, [appProvider, buildBookmark, type]);

  useEffect(() => {
    if (playbackError?.code || playbackError?.message) {
      const { errorTitle, errorMessage } = getPlaybackErrorMessageInfo(playbackError);
      const message = errorMessage ? translate(errorMessage) : "";
      const errorDetails = errorTitle ? translate(errorTitle) : "";

      if (message && errorDetails) {
        trackMediaError(playbackError.code, errorTitle);
        setTimeout(() => {
          trackGenericAction(ANALYTICS_EVENT_TYPES.PLAYBACK_ERROR, {
            errorCode: playbackError.code,
            errorDetails,
            errorMessage: message,
            media: getMediaNodeMetaData(),
          });
        }, 5000);

        if (isConvivaAppTrackerEnabled) {
          trackConvivaCustomEvent(
            ANALYTICS_EVENT_TYPES.PLAYBACK_ERROR,
            getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
          );
        }
        if (appProvider && activeContentId.current && !hasCalledStopContent.current) {
          const contentId = activeContentId.current;
          const requestBody = {
            deltaThreshold: null,
          };
          bookmarkAVSVideo(appProvider, contentId, type, requestBody)
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              hasCalledStopContent.current = true;
            });
        }
      }
    }
  }, [
    playbackError,
    translate,
    getMediaNodeMetaData,
    appProvider,
    type,
    isInHome,
    userProfile,
    isConvivaAppTrackerEnabled,
  ]);

  let initPlayerTimestamp = null;

  const loadPlayerSource = (source, networkConfig) => {
    const isMuted = isSafari ?? false;
    initPlayerTimestamp = moment().valueOf();
    if (!playerMgr.current) {
      playerMgr.current = createPlayer(
        videoRef.current,
        isMuted,
        playerEventsHandler,
        networkConfig,
        contentType === "LIVE" ? false : true
      ); // live player has autoplay disabled
    }
    if (isReadyToChangeStreams) {
      loadPlayback(playerMgr.current, source)
        .then(() => {
          setIsSourceLoaded(true);
          // VOD/Recording playback autoplay
          if (contentType !== "LIVE") {
            playerMgr.current.play().catch((error) => {
              // This error handler was added to handle the promise rejection from this issue: https://developer.chrome.com/blog/play-request-was-interrupted/
              // TODO: Handle the pause/play and load/play race conditions properly when refactoring the player
              console.error(error);
            });
          }
          let initPlayHead = 0;

          // Bookmark resume - VOD playback resume point is relative to 0 point of playback,recording playback resume point is relative to user's recordingStartDeltaTime
          if (resumeTimeSeconds && resumeTimeSeconds > 0)
            initPlayHead =
              recordingStartDeltaTime !== null ? recordingStartDeltaTime + resumeTimeSeconds : resumeTimeSeconds;

          if (initPlayHead > 0) playerMgr.current.seek(initPlayHead); // Note: for live stream, seek is ignored, so this does nothing

          // Dynamic partial recording playback start flag
          if (isRecording && playerMgr.current.isLive()) {
            // For dynamic partial recording playback, we need to shift the live stream to the user's recording start time
            playerMgr.current.timeShift(userRecordingStartTime / 1000);
          }

          if (isRecording && playerMgr.current.isLive()) {
            isLive.current = true;
            goLive.current = 1;
          }
          propsRef.current = { ...props, cdnUrl: cdnUrl.current };
          telusConvivaAnalytics.onLoadPlayerSuccessful(propsRef.current, playerMgr.current);
        })
        .catch((e) => {
          propsRef.current = { ...props, cdnUrl: cdnUrl.current };
          telusConvivaAnalytics.onLoadPlaybackFailed(propsRef.current, playerMgr.current);
          if (e && e?.code && e.code !== 1203) {
            handleAVSStopContent(true, activeContentId.current);
            isReadyCallback && isReadyCallback();
          }
        });
    }
  };
  // constant to store dynamic recording playback's actual start time. This can be different from userRecordingStartTime due to the segment boundary and recording prepadding
  const recordingLiveAssetStartTime = useRef(0);

  const playerEventsHandler = function (eventObj) {
    switch (eventObj.type) {
      case PlayerEvent.Ready:
        if (playbackType !== PLAYBACK_TYPES.TRAILER && isOnDemandType) {
          if (!bookmarkTimer.current) {
            bookmarkTimer.current = setInterval(() => {
              hasBookmark.current = 1;
              if (bookmarkCreated.current) {
                updateBookmarkInfo();
              } else {
                createBookmarkInfo().then((res) => {
                  bookmarkCreated.current = res?.bookmark;
                });
              }
            }, BOOKMARK_UPDATE_INTERVAL_TIMER);
          }
        }

        // Set Initial FF Status Settings
        if (isOnDemandType || checkIfCatchUpAllowed()) {
          setVideoStarted(true);
          if (isOnDemandType && activeExtendedMetadata.current) {
            setFFEnabled(!activeExtendedMetadata.current.fwdBlocked);
          } else if (checkIfCatchUpAllowed()) {
            setFFEnabled(isRestartTrickPlayAllowed.current);
          } else {
            setFFEnabled(true);
          }
        }
        setFFErrorStatus(!isFFEnabled);

        // Set Initial RW Status Settings
        if (isOnDemandType) {
          if (activeExtendedMetadata.current) {
            setRWEnabled(!activeExtendedMetadata.current.rwdBlocked);
          } else {
            setRWEnabled(true);
          }
        } else if (checkIfCatchUpAllowed()) {
          setRWEnabled(true);
        }

        if (isRestartedStream || isLookbackStream) {
          startingOverStream.current = false;
        }

        break;
      case PlayerEvent.Error:
        if (isPlaybackRetry?.enabled && !isPlaybackInPanic && videoProgressMade.current) {
          toggleSpinningLoaderAction(true, playerLoaderClassName);

          // Remove loaded content
          if (playerMgr.current) {
            playerMgr.current.unload();
          }

          // Attempt to fetch a new JWT and manifest
          createRetryTimer();
        } else {
          setShowBgImage(false);
          controlPlaybackDeltaTimer(false);
          if (ffErrorStatus && eventObj.code === 226) {
            setFFErrorStatus(false);
            break;
          }
          toggleSpinningLoaderAction(false);

          if (onPlaybackStateChange)
            onPlaybackStateChange({
              errorType: ERROR_TYPES.ERROR,
              message: eventObj.name,
              code: eventObj.code,
            });
        }
        break;

      case PlayerEvent.Playing:
        videoProgressMade.current = true;
        setVideoPlaying({
          playing: true,
          type: type.toUpperCase(),
          currentContentId: currentContentId,
          assetId: assetId,
        });
        /** At the beginning of dynamic partial recording playback, for "on now" recording asset, we capture the start POS and calculate the playback actual duration, this is a one time calculation so set live asset started flag to true after the first playing event trigger */
        if (hasRecordingDeltaTime() && isDynamicAsset() && !recordingLiveAssetStarted) {
          const currentPlayhead = playerMgr.current?.getCurrentTime(); // Warning: DO NOT use eventObj.time to calculate dynamic playhead. In Playing event, eventObj.time is not accurate for HLS dynamic partial recording playback so we need call getCurrentTime() to get the accurate playhead
          recordingPlaybackDuration.current =
            userRecordingStopTime / 1000 - Number.parseFloat(currentPlayhead).toFixed(3);
          recordingLiveAssetStartTime.current = Number.parseFloat(currentPlayhead).toFixed(3);
          setRecordingAssetElapsedTime("");
          recordingLiveAssetStarted = true;
        }
        if (playerMgr.current?.isLive() && showLiveProgress.current === false) showLiveProgress.current = true;
        if (isSafari) {
          playerMgr.current.unmute();
        }
        setShowBgImage(false);
        toggleSpinningLoaderAction(false);

        controlPlaybackDeltaTimer(true);

        const availableAudioTracks = playerMgr.current.getAvailableAudio();
        if (availableAudioTracks) {
          playerMgr.current.setAudio(getAudioTrackId(availableAudioTracks, isDescribedVideoEnabled.current));
          setADAvailable(hasADAudioTrack(availableAudioTracks));
        }

        const availableCaptions = playerMgr.current.subtitles.list();
        if (availableCaptions && availableCaptions.length > 0) {
          setIsCCAvailable(true);
        } else {
          setIsCCAvailable(false);
        }

        clearTimeout(autoPlayLiveTimer.current);
        autoPlayLiveTimer.current = null;
        if (type === LIVE && showLiveProgress.current === false) {
          showLiveProgress.current = true;
        }

        if (!playbackTS.current) playbackTS.current = moment().valueOf();
        if (pauseTS.current) {
          pausedDuration.current = pauseTS.current - moment().valueOf();
        }

        const firstFrameTimestamp = moment().valueOf();
        if (initPlaybackTimestamp.current && initPlayerTimestamp) {
          // track player start event only if playback initiated successfully, this excludes playback error scenarios
          const nrPlayStartedEventAttr = {
            timeToPlaybackStartMs: firstFrameTimestamp - initPlaybackTimestamp.current,
            timeToPlaybackStartPlayerOnlyMs: firstFrameTimestamp - initPlayerTimestamp,
            contentType: nrContentType,
            isAutomationBuild: appProvider?.isAutomationBuild,
          };
          trackMediaPlay(nrPlayStartedEventAttr);
          initPlayerTimestamp = null; // we need to reset this value to null to prevent user pause and resume events from being tracked as player playback start events
        }

        break;

      case PlayerEvent.Paused:
        pauseTS.current = moment().valueOf();
        toggleSpinningLoaderAction(false);
        controlPlaybackDeltaTimer(false);
        trackMediaPause();
        if (
          isTimeShiftAllowed.current &&
          !isRestartedStream &&
          !isLookbackStream &&
          pauseLiveProperties?.pausePeriodMins
        ) {
          autoPlayLiveTimer.current = setTimeout(() => {
            setVideoPlayerControlState(playerMgr.current, PLAYER_CONTROLS.RESUME);
            setPlayPauseIcon("/images/playback-pause.svg");
            showToastNotification(translate("pltv_resume_text"));
            videoControlsMouseMove();
          }, pauseLiveProperties.pausePeriodMins * 60 * 1000);
        }
        break;
      case PlayerEvent.PlaybackFinished:
        if (isRestartedStream || isLookbackStream) {
          if (isPastProgram(nextLiveProgramDetails.current?.metadata?.airingEndTime)) {
            triggerLookback(nextLiveProgramDetails.current); // transition into lookback mode if next program is already aired
          } else {
            exitLookback(); // transition out of lookback mode for a stream currently live
            setLiveProgramCanUpdate(true);
            onPlaybackStateChange(PLAYER_CONTROLS.GET_NEXT_LIVE_PROGRAM);
          }
        }
        controlPlaybackDeltaTimer(false);
        removeSessionStorage(PLAYER_LOADED);

        trackMediaComplete();
        break;
      case PlayerEvent.Seek:
        controlPlaybackDeltaTimer(false);
        toggleSpinningLoaderAction(true, playerLoaderClassName);
        if (eventObj && eventObj.seekTarget) {
          isSeeking.current = true;
          let progress = getProgress(parseInt(eventObj.seekTarget), playerMgr.current.getDuration());
          /*
          When playing the user specific portion of recording playback, the progress calculation is relative to recordingStartDeltaTime && recordingStopDeltaTime.
          This is distinguished from player playback progress.
          */
          if (hasRecordingDeltaTime()) {
            const recordingPlaybackSeekTarget = parseInt(eventObj.seekTarget - recordingStartDeltaTime);
            progress = getProgress(recordingPlaybackSeekTarget, recordingPlaybackDuration.current);
            setRecordingAssetElapsedTime(formatSecondsToTimeDisplay(recordingPlaybackSeekTarget));
            setRecordingAssetRemainingTime(recordingPlaybackDuration.current - recordingPlaybackSeekTarget);
          }
          setProgressStyle(progress);
          if (updateVodProgress) {
            updateVodProgress(progress);
          }
          !isRestartedStream && setElapsedTime(formatSecondsToTimeDisplay(eventObj.seekTarget));
          !isRestartedStream && setRemainingTime(playerMgr.current.getDuration() - eventObj.time);
        }

        trackMediaSeek();

        break;

      case PlayerEvent.Seeked:
        controlPlaybackDeltaTimer(playerMgr.current.isPlaying());
        toggleSpinningLoaderAction(false);
        isSeeking.current = false;

        trackMediaSeeked();

        break;
      case PlayerEvent.TimeChanged:
        if (blockPlayback.current) {
          setVideoPlayerControlState(playerMgr.current, PLAYER_CONTROLS.PAUSE);
        }
        let progress = getProgress(
          isDynamicAsset() ? props.metadataInfo?.duration - (endTime / 1000 - eventObj.time) : eventObj.time,
          isDynamicAsset() ? props.metadataInfo?.duration : playerMgr.current.getDuration()
        );
        /**
         * Modifying restarted live stream transition to fetch the next stream towards the end of the stream instead of the beginning,
         * to avoid all the edge cases that can come up due to stale information in case user spends long time on one stream using pause and play.
         * Using the binge watch timer value for the transition, next stream to be fetched during last 20 seconds of the current restarted stream.
         */
        if (isRestartedStream || isLookbackStream) {
          /**
           * bitmovin player returns different values for playback time in chrome and safari for a restarted stream that has finished airing
           * therefore need to use different values for comparison depending on if the value returned from the player is timestamp or not
           */
          const programEndTime = isTimestamp(eventObj.time)
            ? liveProgramDetails.metadata.airingEndTime / 1000
            : playerMgr.current.getDuration();
          if (isNextLiveStreamFetched.current === false && programEndTime - eventObj.time < BINGE_WATCH_TIMER) {
            fetchNextLiveStream(liveProgramDetails).then((nextLiveProgram) => {
              nextLiveProgramDetails.current = nextLiveProgram;
            });
            isNextLiveStreamFetched.current = true;
          }
        }
        /*
          Similar to Seek event, for user specific portion of recording playback, the progress calculation is relative to recordingStartDeltaTime && recordingStopDeltaTime.
          This is distinguished from player playback progress.
        */
        if (hasRecordingDeltaTime()) {
          const recordingPlaybackProgress = !isDynamicAsset()
            ? eventObj.time - recordingStartDeltaTime
            : Math.floor(eventObj.time) - recordingLiveAssetStartTime.current;

          progress = getProgress(
            recordingPlaybackProgress > 0 ? recordingPlaybackProgress : 0,
            recordingPlaybackDuration.current
          );

          /* If the recording asset is static, recording player needs to stop playback when playback position reaches recordingStopDeltaTime */
          if (
            (!isDynamicAsset() && eventObj.time > recordingStopDeltaTime) ||
            (isDynamicAsset() && eventObj.time > userRecordingStopTime / 1000)
          ) {
            if (playerMgr.current.isPlaying()) {
              setVideoPlayerControlState(playerMgr.current, PLAYER_CONTROLS.STOP);
              setPlayPauseIcon("/images/playback-play.svg");
              removeSessionStorage(PLAYER_LOADED);
              trackMediaComplete();
              if (1 < history.length) {
                history.goBack();
              } else {
                history.push("/");
              }
            }
          }
          // For dynamic recording playback, we need to prevent tooltips value update when time shifting, this will prevent tooltips value updated to a previous timestamp
          if (isRecording && isTimeShifting.current) {
            // Silence is golden
          } else {
            // update tooltip values
            setRecordingAssetElapsedTime(formatSecondsToTimeDisplay(recordingPlaybackProgress));
            setRecordingAssetRemainingTime(recordingPlaybackDuration.current - recordingPlaybackProgress);
          }
        }
        // For dynamic recording playback, we need to prevent progress update when time shifting, this will prevent progress toolkit from shifting back
        if (isRecording && isTimeShifting.current) {
          // Silence is golden
        } else {
          // update progress
          setProgressStyle(progress);
          if (updateVodProgress) {
            updateVodProgress(progress);
          }
        }
        !isRestartedStream &&
          setElapsedTime(
            formatSecondsToTimeDisplay(
              isDynamicAsset() ? props.metadataInfo?.duration - (endTime / 1000 - eventObj.time) : eventObj.time
            )
          );
        !isRestartedStream &&
          setRemainingTime((isDynamicAsset() ? endTime / 1000 : playerMgr.current.getDuration()) - eventObj.time);
        break;
      case PlayerEvent.SubtitleAdded:
        if (playerMgr.current.subtitles) {
          const availableCaptions = playerMgr.current.subtitles.list();
          setIsCCAvailable(availableCaptions && availableCaptions.length > 0);

          if (availableCaptions[0]) {
            const subTitleTrackId = availableCaptions[0].id;
            if (isClosedCaptionEnabled.current) {
              closedCaption.current = 1;
              playerMgr.current.subtitles.enable(subTitleTrackId);
            } else {
              playerMgr.current.subtitles.disable(subTitleTrackId);
            }
          }
        }

        break;
      case PlayerEvent.CueEnter:
        ccRef.current = [...ccRef.current, eventObj];
        setCCList(ccRef.current);
        break;
      case PlayerEvent.CueExit:
        ccRef.current = ccRef.current.filter((ccObj) => ccObj.start !== eventObj.start);
        setCCList(ccRef.current);
        break;
      case PlayerEvent.SourceLoaded:
        playerMgr.current?.preload();
        if (isNextProgramNonRestartable) {
          showToastNotification(translate("playback_from_live_point"));
        }
        isReadyCallback && isReadyCallback();
        break;
      case PlayerEvent.StallStarted:
        toggleSpinningLoaderAction(true, playerLoaderClassName);
        trackMediaBufferStart();
        break;
      case PlayerEvent.StallEnded:
        toggleSpinningLoaderAction(false);
        trackMediaBufferComplete();
        break;
      case PlayerEvent.VideoPlaybackQualityChanged:
      case PlayerEvent.VideoQualityChanged:
        if (!eventObj.sourceQuality || !eventObj.targetQuality) {
          break;
        }
        if (eventObj.sourceQuality?.bitrate !== eventObj.targetQuality?.bitrate) {
          trackMediaBitrateChange({
            bitrate: eventObj.targetQuality.bitrate,
            fps: eventObj.targetQuality.frameRate,
            droppedFrames: playerMgr.current.getDroppedVideoFrames(),
          });
        }
        break;
      case PlayerEvent.TimeShift:
        isTimeShifting.current = true;
        if (eventObj && eventObj.target) {
          /*
          if an "on now" recording playback is in time shifting, we need to calculate the playback progress relative to user's recordingStartDeltaTime, and se the progress accordingly. When this happens, we also disable the progress calculation in TimeChanged event to prevent progress bar from shifting back
          */
          if (hasRecordingDeltaTime()) {
            const recordingPlaybackProgress = Math.floor(eventObj.target) - recordingLiveAssetStartTime.current;
            const progress = getProgress(recordingPlaybackProgress, recordingPlaybackDuration.current);
            if (progress > 0 && progress < 100) {
              setRecordingAssetElapsedTime(formatSecondsToTimeDisplay(recordingPlaybackProgress));
              setRecordingAssetRemainingTime(recordingPlaybackDuration.current - recordingPlaybackProgress);
              setProgressStyle(progress);
            }
          }
        }
        toggleSpinningLoaderAction(true, playerLoaderClassName);
        break;
      case PlayerEvent.TimeShifted:
        isTimeShifting.current = false;
        toggleSpinningLoaderAction(false);
        break;
      default:
        break;
    }
    telusConvivaAnalytics.onPlaybackStateChanged(propsRef, playerMgr.current, eventObj, playbackError, resetPlayerPage);
  };

  // DOM Event Handlers
  const videoControlsMouseMove = (e) => {
    setVideoControlsStyle({ opacity: 0.9, visibility: "visible" });

    if (videoControlsTimer && videoControlsTimer.current) clearTimeout(videoControlsTimer.current);

    videoControlsTimer.current = setTimeout(() => {
      setVolumeVisibility(false);
      setSettingsDropdownVisibility(false);
      videoWrapper.current && videoWrapper.current.focus();
      setVideoControlsStyle({ opacity: 0, visibility: "hidden" });
    }, 5000);
  };
  //Click Events Handler
  const onClickToggleState = (e) => {
    const className = e.target.className;

    if (
      className.includes("playbackControl-top") ||
      className.includes("info") ||
      className.includes("playbackControl-metaData") ||
      className.includes("playbackControl-bottom") ||
      className.includes("videoControls")
    ) {
      if (e.detail === 1) {
        if ((type === VOD || checkIfTimeShiftAllowed()) && !spinnerToggle) playPauseButtonHandler(e);
      } else if (e.detail === 2) {
        onFullScreenButtonHandler(e);
        if ((type === VOD || checkIfTimeShiftAllowed()) && !spinnerToggle) playPauseButtonHandler(e);
      }
    }
    signalUserActivity();
  };
  const videoControlsKeyUp = (e) => {
    if (!spinnerToggle && !showNextEpisodeCard && !showStillWatchingPrompt) {
      videoControlsMouseMove();
      if (isOnDemandType || checkIfTimeShiftAllowed()) {
        switch (e.keyCode) {
          case 37:
            if (isRWEnabled || checkIfTimeShiftAllowed()) {
              volumeVisibility && setVolumeVisibility(false);
              rewindButtonHandler(e);
            }
            break;
          case 39:
            if (isFFEnabled || checkIfTimeShiftAllowed() || (checkIfStartOverAllowed() && isRestartedStream)) {
              volumeVisibility && setVolumeVisibility(false);
              forwardButtonHandler(e);
            }
            break;
          case 32:
            e.preventDefault();
            playPauseButtonHandler(e);
            break;
          default:
            break;
        }
      }
    }
  };

  const handleVolume = (e) => {
    if (!showNextEpisodeCard && !showStillWatchingPrompt) {
      videoControlsMouseMove();
      switch (e.keyCode) {
        case 38:
          volumeBarRef.current && volumeBarRef.current.focus();
          !volumeVisibility && setVolumeVisibility(true);
          settingsDropdownVisibility && setSettingsDropdownVisibility(false);
          break;
        case 40:
          volumeBarRef.current && volumeBarRef.current.focus();
          !volumeVisibility && setVolumeVisibility(true);
          settingsDropdownVisibility && setSettingsDropdownVisibility(false);
          break;
        case 70:
          onFullScreenButtonHandler(e);
          break;
        case 77:
          volumeButtonHandler(e);
          break;
        default:
          break;
      }
    }
  };

  const playPauseButtonHandler = (e) => {
    if (playerMgr.current.isPlaying()) {
      setVideoPlayerControlState(playerMgr.current, PLAYER_CONTROLS.PAUSE);
      setPlayPauseIcon("/images/playback-play.svg");
    } else {
      setVideoPlayerControlState(playerMgr.current, PLAYER_CONTROLS.RESUME);

      setPlayPauseIcon("/images/playback-pause.svg");
    }
    signalUserActivity();
  };

  const rewindButtonHandler = (e) => {
    videoWrapper.current && videoWrapper.current.focus();
    toggleSpinningLoaderAction(true, playerLoaderClassName);
    seekPlayback(-10);
    signalUserActivity();
    if (isConvivaAppTrackerEnabled) {
      trackConvivaCustomEvent(
        ANALYTICS_EVENT_TYPES.REWIND,
        getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
      );
    }
    rwCount.current++;
  };

  const forwardButtonHandler = (e) => {
    videoWrapper.current && videoWrapper.current.focus();
    if ((isRestartedStream || checkIfCatchUpAllowed()) && !isRestartTrickPlayAllowed.current) {
      showToastNotification(translate("message_fast_forward_disabled"));
    } else {
      toggleSpinningLoaderAction(true, playerLoaderClassName);
      seekPlayback(isVOD ? 10 : 30);
    }

    signalUserActivity();
    if (isConvivaAppTrackerEnabled) {
      trackConvivaCustomEvent(
        ANALYTICS_EVENT_TYPES.FAST_FORWARD,
        getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
      );
    }
    ffCount.current++;
  };
  const settingButtonHandler = (e) => {
    volumeVisibility && setVolumeVisibility(false);
    setSettingsDropdownVisibility(!settingsDropdownVisibility);
    signalUserActivity();
  };

  const volumeButtonHandler = (e) => {
    if (!userHasClick) {
      setSessionStorage(USER_HAS_CLICK, true);
      setUserHasClick(true);
    }

    settingsDropdownVisibility && setSettingsDropdownVisibility(false);
    setVolumeVisibility(true);
    e.stopPropagation();

    if (!isMuted) {
      playerMgr.current.setVolume(0);
      setVolumeLevel(0);
      setLocalStorage(PLAYER_CONTROLS.MUTE, "true");
      trackPlayerMute(true);
    } else {
      setLocalStorage(PLAYER_CONTROLS.MUTE, "false");
      let prevVol = getLocalStorage(PLAYER_CONTROLS.VOLUME);
      let currVol = prevVol ? prevVol : 100;
      playerMgr.current.setVolume(currVol);
      setVolumeLevel(currVol);
      trackPlayerMute(false);
    }

    // will handle volume button here

    updateMute(!isMuted);
    signalUserActivity();
  };

  const volumeButtonHoverHandler = (e) => {
    settingsDropdownVisibility && setSettingsDropdownVisibility(false);
    setVolumeVisibility(true);
    volumeBarRef.current && volumeBarRef.current.focus();
  };

  const onVolumeSlideChange = (input) => {
    input = parseInt(input);

    setLocalStorage(PLAYER_CONTROLS.MUTE, "false");
    setLocalStorage(PLAYER_CONTROLS.VOLUME, input);
    setVolumeLevel(input);
    updateMute(false);
    playerMgr.current.setVolume(input);
    if (input === 0) {
      updateMute(true);
      setLocalStorage(PLAYER_CONTROLS.MUTE, "true");
      setLocalStorage(PLAYER_CONTROLS.VOLUME, 10);
    }

    signalUserActivity();
  };

  /**
   * A handler which will be triggered when VideoPlyer is closed
   * @param {Object} e event
   * @param {Boolean} isNotStillWatching 'true' when no response occurs after "Still Watching" prompt is ignored
   */
  const closeClickHandler = (e, isNotStillWatching = false) => {
    isClosingPlayer.current = true;
    isFreshLogin && removeLocalStorage(IS_FRESH_LOGIN);
    playerLoaded && removeSessionStorage(PLAYER_LOADED);

    trackMediaEnd(() => {
      if (isConvivaAppTrackerEnabled) {
        trackConvivaCustomEvent(
          ANALYTICS_EVENT_TYPES.BOOKMARK,
          getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
        );
      }
    });

    if (!isNotStillWatching) {
      setSessionStorage(ANALYTICS_STORAGE_KEYS.LINK, `${LINK_INFO.CLOSE};${LINK_INFO.PLAYER}`);
    }

    triggerMediaStop();

    telusConvivaAnalytics.onPlayerClosed();

    if (hasCalledStopContent.current) hasCalledStopContent.current = false;
    handleAVSStopContent().finally(() => {
      if (1 < history.length) {
        history.goBack();
      } else {
        history.push("/");
      }
    });
  };

  const onFullScreenButtonHandler = (e) => {
    onFullScreenClick && onFullScreenClick(isFullScreen(Document));
    showOrHideFullScreen(isFullScreen(Document), Document, videoWrapper.current);
    signalUserActivity();
  };

  /**
   * Set trick play on click
   * @param {*} event
   */
  const setProgress = (progress) => {
    if ((isOnDemandType && !playerMgr.current.isLive()) || checkIfCatchUpAllowed()) {
      const videoDuration = playerMgr.current.getDuration();
      let videoPos = Math.floor((videoDuration / 100) * progress);
      /*
      When set progress for user specific portion of recording playback, the progress calculation is relative to recordingStartDeltaTime && recordingStopDeltaTime.
      So we need to involve user specific portion duration(recordingStopDeltaTime - recordingStartDeltaTim) add recordingStartDeltaTime to calculate the actual videoPos
      of recording playback
      */
      if (hasRecordingDeltaTime()) {
        videoPos = Math.floor((recordingDuration / 100) * progress) + recordingStartDeltaTime;
      }
      if (formatSecondsToTimeDisplay(videoPos) > elapsedTime) {
        if (isFFEnabled) {
          setProgressStyle(progress);
          if (updateVodProgress) {
            updateVodProgress(progress);
          }
          seekPlayback(videoPos, false);
          if (isConvivaAppTrackerEnabled) {
            trackConvivaCustomEvent(
              ANALYTICS_EVENT_TYPES.FAST_FORWARD,
              getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
            );
          }
        } else {
          if (videoStarted) {
            onPlaybackStateChange({
              errorType: ERROR_TYPES.ERROR,
              message: translate("message_fast_forward_disabled"),
              code: 226,
            });
          }
        }
      } else {
        if (isRWEnabled) {
          setProgressStyle(progress);
          if (updateVodProgress) {
            updateVodProgress(progress);
          }
          seekPlayback(videoPos, false);
          if (isConvivaAppTrackerEnabled) {
            trackConvivaCustomEvent(
              ANALYTICS_EVENT_TYPES.REWIND,
              getCustomContextMetadata(getMediaNodeMetaData(), userProfile, appProvider, isInHome)
            );
          }
        } else {
          if (videoStarted) {
            onPlaybackStateChange({
              errorType: ERROR_TYPES.ERROR,
              message: translate("message_rewind_disabled"),
              code: 226,
            });
          }
        }
      }
    }
    if (isRestartedStream) {
      if (playerMgr.current?.isLive()) {
        if (progress > playerMgr.current.getCurrentTime() && !isRestartTrickPlayAllowed.current) {
          showToastNotification(translate("message_fast_forward_disabled"));
        } else {
          if (isTimestampFromPastDay(progress)) {
            playerMgr.current.timeShift(playerMgr.current.getMaxTimeShift());
          } else {
            if (isSafari) {
              // For HLS streams while time shifting, bitmovin player applies a 40 seconds buffer every time, therefore compensating it here as well
              playerMgr.current.timeShift(progress + PLAYBACK_FORWARD_BUFFER);
            } else {
              playerMgr.current.timeShift(progress);
            }
          }
        }
      } else {
        const videoDuration = playerMgr.current.getDuration();
        let videoPos = Math.floor((videoDuration / 100) * progress);
        if (videoPos > playerMgr.current?.getCurrentTime() && !isRestartTrickPlayAllowed.current) {
          showToastNotification(translate("message_fast_forward_disabled"));
        } else {
          setProgressStyle(progress);
          seekPlayback(videoPos, false);
        }
      }
    } else if (playerMgr.current.isLive()) {
      if (type === LIVE) {
        // Live player logic
        if (isSafari) {
          // For HLS streams while time shifting, bitmovin player applies a 40 seconds buffer every time, therefore compensating it here as well
          playerMgr.current.timeShift(progress + PLAYBACK_FORWARD_BUFFER);
        } else {
          playerMgr.current.timeShift(progress);
        }
      } else {
        // Recording player timeShift for dynamic partial recording playback
        const progressPOS =
          parseInt(recordingLiveAssetStartTime.current) +
          Math.round((parseInt(recordingPlaybackDuration.current) * parseInt(progress)) / 100);

        if (progressPOS > parseInt((moment().valueOf() - PLAYBACK_FORWARD_BUFFER) / 1000)) {
          showToastNotification(translate("live_position_reached"));
        }
        if (isSafari) {
          // For HLS streams while time shifting, bitmovin player applies a 40 seconds buffer every time, therefore compensating it here as well
          playerMgr.current.timeShift(progressPOS + PLAYBACK_FORWARD_BUFFER);
        } else {
          playerMgr.current.timeShift(progressPOS);
        }
      }
    }
    signalUserActivity();
  };

  // Playback Helpers

  /*
    Seeks forward or backward to the set time
  */
  const seekPlayback = (duration, appendCurrentPos = true) => {
    let newPos = duration;

    let videoPlayerRef = playerMgr.current;
    const currentPos = videoPlayerRef?.getCurrentTime();
    const videoDuration = videoPlayerRef?.getDuration();

    if (appendCurrentPos) newPos = currentPos + duration > 0 ? currentPos + duration : 0;

    if (newPos >= videoDuration) {
      if (isRestartedStream && !videoPlayerRef.isLive() && !isTimestampFromPastDay(currentPos)) {
        const currentPlaybackSeconds = currentPos - startTime / 1000;
        newPos = currentPlaybackSeconds + duration;
      } else {
        newPos = videoDuration - 1;
      }
    }
    /* For user specific recording playback, we do not allow the user to seek to a position before recording.startDeltaTime */
    if (recordingStartDeltaTime !== null && !videoPlayerRef.isLive()) {
      newPos = newPos <= recordingStartDeltaTime ? recordingStartDeltaTime : newPos;
    }
    /* For user specific recording, we do not allow the user to seek to a position after recording.stopDeltaTime*/
    if (recordingStopDeltaTime !== null && !videoPlayerRef.isLive()) {
      newPos = newPos >= recordingStopDeltaTime ? recordingStopDeltaTime - 1 : newPos;
    }

    if (recordingLiveAssetStartTime.current && newPos < recordingLiveAssetStartTime.current) {
      newPos = recordingLiveAssetStartTime.current;
    }

    if (videoPlayerRef.isLive()) {
      if (appProvider.panicMode) {
        onPlaybackStateChange({
          errorType: ERROR_TYPES.ERROR,
          message: translate("trickplays_unavailable"),
          code: 99999,
        });
      } else {
        if (duration > 0 && Math.abs(newPos - (moment().valueOf() - PLAYBACK_FORWARD_BUFFER) / 1000) < 50) {
          videoPlayerRef.timeShift(0);
          showToastNotification(translate("live_position_reached"));
        } else if (newPos < landTime.current / 1000) {
          isSafari
            ? videoPlayerRef.timeShift(landTime.current / 1000 + PLAYBACK_FORWARD_BUFFER) // hls streams add the buffer by default, so we need this adjustment
            : videoPlayerRef.timeShift(landTime.current / 1000);
          showToastNotification(translate("beginning_of_available_stream_reached"));
        } else {
          /**
           * According to Bitmovin reference - https://bitmovin.com/docs/player/api-reference/web/web-sdk-v8-api-methods#/player/web/8/docs/interfaces/core.playerapi.html#timeshift
           * The offset passed to player.timeShift() method can be positive and is then interpreted as a UNIX timestamp in seconds.
           * However, there was a bug noticed in HLS stream -
           * In Safari, calling timeShift(), with a timestamp passed, would shift current playback time to 40 seconds before the timestamp.
           * This caused a big problem for PLTV feature on Safari. So we have to change it to use negative offset.
           *
           * Calling getTimeShift() returns the current time shift offset to the live edge in seconds - https://bitmovin.com/docs/player/api-reference/web/web-sdk-v8-api-methods#/player/web/8/docs/interfaces/core.playerapi.html#gettimeshift
           * We then calculate the relative offset we would like to shift from live edge.
           */
          if (appendCurrentPos) {
            if (isSafari) {
              videoPlayerRef.timeShift(videoPlayerRef.getTimeShift() + duration);
            } else {
              videoPlayerRef.timeShift(newPos);
            }
          } else {
            videoPlayerRef.timeShift(duration - moment().valueOf());
          }
        }
      }
      toggleSpinningLoaderAction(false);
    } else {
      videoPlayerRef.seek(newPos);
    }
  };

  const handleEpisodeCardClick = () => {
    telusConvivaAnalytics.onPlayNextProgram();
    if (isConvivaAppTrackerEnabled) {
      trackConvivaCustomEvent(
        ANALYTICS_EVENT_TYPES.UP_NEXT_START,
        getCustomContextMetadata(nextProgram?.metadata, userProfile, appProvider, isInHome)
      );
    }
    loadNextProgram && loadNextProgram(false, () => loadNextEpisodeCallback(false, LINK_INFO.PLAY));
    setBingeWatchInProgress(true);
    setShowNextEpisodeCard(false);
    signalUserActivity();
    upNext.current = 1;
  };

  const skipToNextEpisode = () => {
    if (typeof isProgramChangedRef.current === "boolean") {
      isProgramChangedRef.current = true;
    }

    telusConvivaAnalytics.onPlayNextProgram();
    loadNextProgram && loadNextProgram(false, () => loadNextEpisodeCallback(false, LINK_INFO.NEXT_EPISODE));
    setBingeWatchInProgress(true);
    setShowNextEpisodeCard(false);
    signalUserActivity();
  };

  const cancelAutoplay = () => {
    if (autoPlayTimer.current) {
      clearTimeout(autoPlayTimer.current);
      autoPlayTimer.current = null;
    }
    setShowNextEpisodeCard(false);
    signalUserActivity();
  };
  const onSubTitleChange = (e) => {
    isClosedCaptionEnabled.current = e;
    setLocalStorage(SETTINGS_CONFIG.CLOSED_CAPTION, e);
    if (playerMgr.current.subtitles && playerMgr.current.subtitles.list()[0]) {
      var subTitleTrackId = playerMgr.current.subtitles.list()[0].id;
      if (subTitleTrackId) {
        if (e) {
          playerMgr.current.subtitles.enable(subTitleTrackId);
          trackPlayerClosedCaption(true);
        } else {
          playerMgr.current.subtitles.disable(subTitleTrackId);
          ccRef.current = [];
          setCCList(ccRef.current);
          trackPlayerClosedCaption(false);
        }
      }
    }
    signalUserActivity();
  };

  const onADToggleChange = (isADEnabled) => {
    isDescribedVideoEnabled.current = isADEnabled;
    setLocalStorage(SETTINGS_CONFIG.DESCRIBED_VIDEO, isADEnabled);

    const availableAudioTracks = playerMgr.current.getAvailableAudio();
    if (availableAudioTracks) {
      describedVideo.current = 1;
      playerMgr.current.setAudio(getAudioTrackId(availableAudioTracks, isADEnabled));
      logNREvent(NR_PAGE_ACTIONS.TOGGLE_DV, { [NR_CUSTOM_ATTRIBUTES.TOGGLE_ON]: isADEnabled });
      logDatadogEvent(DD_PAGE_ACTIONS.TOGGLE_DV, { [DD_CUSTOM_ATTRIBUTES.TOGGLE_ON]: isADEnabled });
    }
    signalUserActivity();
  };

  const updateLandTime = () => {
    landTime.current =
      ((moment().valueOf() - PLAYBACK_FORWARD_BUFFER * 1000) / 1000 - pauseLiveProperties?.bufferThresholdMins * 60) *
      1000;
  };

  const liveProgress = () => {
    let liveProgressPercentage;
    if (isOnDemandType || (type === LIVE && !isTimeShiftAllowed.current) || isDynamicRecordingType) {
      liveProgressPercentage =
        ((moment().valueOf() + getRecordingStartOffset() * 1000 - startTime) / (endTime - startTime)) * 100; // When recording asset is still live, calculate percentage factoring startOffset for user specific recording startDeltaTime
    } else if (landTime.current !== null && !isTimestampFromPastDay(playerMgr?.current?.getCurrentTime() * 1000)) {
      liveProgressPercentage =
        ((playerMgr?.current?.getCurrentTime() * 1000 - landTime.current) / (endTime - startTime)) * 100;
    } else {
      if (
        isRestartedStream &&
        !playerMgr.current?.isLive() &&
        currentPlaybackTime > 0 &&
        isTimestampFromPastDay(currentPlaybackTime)
      ) {
        liveProgressPercentage = (currentPlaybackTime / 1000 / playerMgr.current?.getDuration()) * 100;
      } else {
        liveProgressPercentage =
          ((playerMgr?.current?.getCurrentTime() * 1000 - landTime.current) / (endTime - startTime)) * 100;
      }
    }
    // When a user resumes a paused live playback, liveProgramDetails should be locked until current playback ends. Unlock when playhead has been jumped to live edge, or playhead passes current program end time.
    // TO DO: Investigate as to why this is crashing for fast channels related TSQA-35406
    if (type !== RECORDING && !isFast && liveProgramCanUpdate && playerMgr?.current?.getTimeShift() < 0) {
      setLiveProgramCanUpdate(false);
    } else if (
      !liveProgramCanUpdate &&
      ((playerMgr.current?.isLive() && playerMgr.current?.getTimeShift() === 0) ||
        playerMgr.current?.getCurrentTime() * 1000 >= endTime)
    )
      setLiveProgramCanUpdate(true);

    if ((isOnDemandType || (type === LIVE && !isTimeShiftAllowed.current)) && liveProgressPercentage >= 100) {
      onPlaybackStateChange(PLAYER_CONTROLS.GET_NEXT_LIVE_PROGRAM);
      liveProgressPercentage = 0;
    } else if (!isOnDemandType && playerMgr?.current?.getCurrentTime() >= endTime / 1000) {
      onPlaybackStateChange(PLAYER_CONTROLS.GET_NEXT_LIVE_PROGRAM);
      leftMargin.current = 0;
      landTime.current = (playerMgr.current?.getCurrentTime() || 0) * 1000;
      liveProgressPercentage = ((endTime - landTime.current) / (endTime - startTime)) * 100;
    }
    if (!Number.isNaN(liveProgressPercentage)) {
      return Math.max(liveProgressPercentage, 0);
    }
    return 0; // default live progress percentage to 0 for unhandled error situation
  };

  const liveBufferProgress = () => {
    const currentTime = moment()?.valueOf() || 0;
    // In Safari, sometimes there is a premature call to set bufferIndicatorWidth before the landTime is set. This usually happens on first player load. So we need to make sure we don't return liveBufferProgressPercentage when the landTime is not available.
    if (landTime.current === null) {
      return 0;
    }
    // Keep prev end time to lock PLTV progress buffer for ended program. This would prevent PLTV progress buffer to keep extending at the end of a program.
    if (!prevEndTime || currentTime >= prevEndTime.current) {
      prevEndTime.current = endTime;
    }
    let liveBufferProgressPercentage =
      ((currentTime - PLAYBACK_FORWARD_BUFFER * 1000 - landTime.current) / (endTime - startTime)) * 100;
    if (prevEndTime.current === endTime) {
      prevLiveBufferProgressPercentage.current = liveBufferProgressPercentage;
    } else {
      liveBufferProgressPercentage = prevLiveBufferProgressPercentage.current;
    }
    if (currentTime - PLAYBACK_FORWARD_BUFFER * 1000 >= endTime) {
      programCurrentlyAiring && setProgramCurrentlyAiring(false);
      liveBufferProgressPercentage = ((endTime - landTime.current) / (endTime - startTime)) * 100;
    } else {
      !programCurrentlyAiring && setProgramCurrentlyAiring(true);
    }
    return Math.min(liveBufferProgressPercentage, 100);
  };

  const getEpisodeInfo = () => {
    if (!liveProgramDetails.metadata?.genres?.includes("News")) {
      // We don't display second line for news episodes as per TCDWC-2425
      const uaEpisodeObject = getAutoGeneratedObject(liveProgramDetails.metadata);
      return getAutogeneratedEpisodeString(uaEpisodeObject, liveProgramDetails.metadata);
    }
  };

  /**
   * Helper function to start or stop the playback delta timer.
   * The playback delta timer is used to track the number of playback seconds that occur
   * in a player session.
   * @param {Boolean} shouldStart
   */
  const controlPlaybackDeltaTimer = (shouldStart) => {
    if (shouldStart) {
      if (!playbackDeltaTimer.current) {
        playbackDeltaTimer.current = setInterval(() => {
          playbackTimeDelta.current += 1;
        }, 1000);
      }
    } else {
      if (playbackDeltaTimer.current) {
        clearInterval(playbackDeltaTimer.current);
        playbackDeltaTimer.current = null;
      }
    }
  };

  const recordingCTA = useMemo(() => {
    if (recordingInfo) {
      let isRecordingScheduled, isRecordingConflicted, isSeriesRecordingScheduled, isSeriesRecordingConflicted;
      if (isMR) {
        isRecordingScheduled = recordingInfo.recordingEventScheduledCheck;
        isSeriesRecordingScheduled = recordingInfo.recordingSeriesScheduledCheck;
        isRecordingConflicted = recordingInfo.recordingEventConflictCheck;
        isSeriesRecordingConflicted = recordingInfo.recordingSeriesConflictCheck;
      }

      return getRecordingCTAs(
        isMR,
        recordingInfo,
        liveProgramDetails,
        isRecordingConflicted,
        isRecordingScheduled,
        isSeriesRecordingConflicted,
        isSeriesRecordingScheduled,
        false,
        true
      );
    }

    return null;
  }, [isMR, liveProgramDetails, recordingInfo]);

  const signalUserActivity = useCallback(() => {
    if (restartUserInactivityTimer) {
      restartUserInactivityTimer();
    }
  }, [restartUserInactivityTimer]);

  const getRecordingButton = useCallback(() => {
    if (recordingCTA && programCurrentlyAiring) {
      return (
        <VideoPlayerButtonComponent
          src={recordingCTA.recordingIcon}
          buttonClassName="live-player-recording-icon"
          $hoverBackgroundColor={theme.colours.translucentWhite}
          onClickHandler={(event) => {
            event.stopPropagation();
            signalUserActivity();
            if (isNotAtLivePosition()) {
              showToastNotification(translate("recordings_restriction_from_live"));
            } else {
              recordingButtonClickHandler(recordingInfo);
            }
          }}
          testID="livePlayerRecordingIcon"
          buttonContainerStyles="button-container"
          alt={recordingCTA.altHead}
          tooltipDirection="top"
        />
      );
    }

    return null;
  }, [
    recordingCTA,
    programCurrentlyAiring,
    theme,
    signalUserActivity,
    isNotAtLivePosition,
    showToastNotification,
    translate,
    recordingButtonClickHandler,
    recordingInfo,
  ]);

  /**
   * Opens the purchase modal using data from liveProgramDetails.
   * This is currently only applicable for PPV.
   */
  const openPurchaseModal = useCallback(() => {
    if (liveProgramDetails) {
      const modalContent = {
        channel: liveProgramDetails.channel,
        itemMetadata: liveProgramDetails.metadata,
        itemImage: getAVSPosterArtImage(liveProgramDetails.metadata, IMAGES.ASPECT_RATIOS.DIM_2x3),
        purchaseCallback,
        purchasePackage: { ...ppvPurchasePackage, programId: liveProgramDetails.metadata.contentId },
      };
      showModalPopup(MODAL_TYPES.PURCHASE, modalContent);
    }
  }, [showModalPopup, liveProgramDetails, ppvPurchasePackage, purchaseCallback]);

  const mouseEventHandler =
    !(showNextEpisodeCard && !isSideNavOpen) && !showStillWatchingPrompt ? videoControlsMouseMove : undefined;

  // For user specific recording playback, we use its recording portion start time, for all other playbacks, we use program start time.
  const startTimeEpoch = userRecordingStartTime ? userRecordingStartTime : startTime;

  // Duration variable
  let duration = isDynamicAsset() ? endTime / 1000 : playerMgr?.current?.getDuration();
  if (hasRecordingDeltaTime()) {
    // For recording asset, the duration is calculated by recordingStartDeltaTime and recordingStopDeltaTime
    duration = recordingDuration;
  }

  const jumpToLivePoint = () => {
    if (isRestartedStream) {
      handleAVSStopContent();
      landTime.current = null;
      switchLive();
    } else {
      hasJumpedToLive.current = true;
      if (!playerMgr.destroyed && playerMgr.current?.isPaused()) {
        setPlayPauseIcon("/images/playback-pause.svg");
        setVideoPlayerControlState(playerMgr.current, PLAYER_CONTROLS.RESUME);
      }
      playerMgr.current.timeShift(0);
    }
  };

  const shouldDisplayForwardButton = () => {
    if (isPlayerControlsDisable || isFast || ((isOnDemandType || checkIfCatchUpAllowed()) && !isFFEnabled)) {
      return false;
    }
    return true;
  };

  const shouldDisplayRewindButton = () => {
    if (isPlayerControlsDisable) return false;
    if ((isRWEnabled && isOnDemandType) || isDynamicRecordingType || checkIfTimeShiftAllowed()) return true;
  };

  const episodeInfoString = () => {
    const uaEpisodeObject = getAutoGeneratedObject(nextProgram.metadata);
    const episodeInfo = getAutogeneratedEpisodeString(uaEpisodeObject, nextProgram.metadata);
    return episodeInfo;
  };

  const shouldDisplayRestartButton = () => {
    if (isPlayerControlsDisable || (isOnDemandType && !videoStarted)) {
      // do not display the restart icon for live player in panic mode, or if on-demand player's stream is not started
      return false;
    }

    if (isOnDemandType && !isDynamicRecordingType && playbackType !== PLAYBACK_TYPES.TRAILER) {
      return true;
    }

    if (type === LIVE) {
      const canStartOver = !isRestartedStream && checkIfStartOverAllowed() && programCurrentlyAiring;
      const canCatchUp = checkIfCatchUpAllowed();
      return canStartOver || canCatchUp;
    }

    return false;
  };

  triggerMediaStop = useCallback(() => {
    let mediaNode = getMediaNodeMetaData();
    mediaNode.sessionID = formatTrackingValue(telusConvivaAnalytics.getSessionID());

    // Adobe XDM media stop event
    trackGenericAction(ANALYTICS_EVENT_TYPES.MEDIA_STOP, {
      extraData: {
        timePlayedSeconds: (moment().valueOf() - playbackTS.current - pausedDuration.current) / 1000,
        isRecording: isRecording,
        assetId,
      },
      media: mediaNode,
      videoStates: {
        fastForward: ffCount.current,
        rewind: rwCount.current,
        restart: isRestart.current,
        airPlay: 0,
        chromeCast: chromecast.current,
        upNext: upNext.current,
        closedCaption: closedCaption.current,
        bookmark: hasBookmark.current,
        describedVideo: describedVideo.current,
        lookBack: hasLookback.current,
        goLive: goLive.current,
      },
    });
  }, [getMediaNodeMetaData, isRecording, assetId]);

  return (
    <>
      <div
        ref={videoWrapper}
        onMouseMove={mouseEventHandler}
        onMouseDown={mouseEventHandler}
        onMouseLeave={mouseEventHandler}
        onKeyUp={videoControlsKeyUp}
        onKeyDown={handleVolume}
        id="videoWrapper"
        onClick={!playerMgr.current?.isStalled() ? onClickToggleState : undefined}
        tabIndex="0"
        className="video-wrapper"
      >
        <PlaybackError isSideNavOpen={isSideNavOpen} />
        {isFullScreen(document) && toastData && Object.values(toastData).some((value) => value !== null) && (
          <ToastNotification /> // rendering inside browser's top layer when app is in full screen mode
        )}
        {showBgImage && bgImage ? (
          <img
            className={videoClassName}
            alt=" "
            src={bgImage}
            style={{
              objectFit: "cover",
              objectPosition: "center center",
              opacity: "0.4",
              position: "absolute",
            }}
          />
        ) : null}
        <div ref={videoRef} className={videoClassName}></div>

        <div
          className="gradient-bg"
          style={
            (showNextEpisodeCard && !isSideNavOpen) || showStillWatchingPrompt
              ? { opacity: 0.9, visibility: "visible" }
              : !showBgImage
              ? videoControlsStyle
              : null
          }
        ></div>

        {ccList && ccList.length > 0 ? (
          <div className={`cc-container ${isSideNavOpen ? "shrink" : ""}`}>
            {ccList.map((ccObj, index) => (
              <p
                key={index}
                dangerouslySetInnerHTML={{
                  __html: sanitize(ccObj.text, {
                    USE_PROFILES: { html: true },
                  }),
                }}
              />
            ))}
          </div>
        ) : null}

        {(showNextEpisodeCard && isSideNavOpen) || showStillWatchingPrompt ? (
          <div className="binge-watch-close-icon">
            <VideoPlayerButtonComponent
              src={process.env.PUBLIC_URL + "/images/playback-close.svg"}
              alt={translate("close")}
              onClickHandler={closeClickHandler}
              $hoverBackgroundColor={theme.colours.mineShaftGray}
              tooltipDirection="bottom"
              className="playbackClose"
            />
          </div>
        ) : null}

        {(!showNextEpisodeCard || isSideNavOpen) && (
          <div className={videoControlsName} ref={videoCCContainerRef} style={videoControlsStyle}>
            <div className="playbackControl-top dis-flex">
              <div className="dis-flex align-right">
                <VideoPlayerButtonComponent
                  src={
                    isFullScreen(Document)
                      ? process.env.PUBLIC_URL + "/images/playback-view-minimize.svg"
                      : process.env.PUBLIC_URL + "/images/playback-full-screen.svg"
                  }
                  alt={isFullScreen(Document) ? translate("exit_full_screen") : translate("full_screen")}
                  onClickHandler={onFullScreenButtonHandler}
                  $hoverBackgroundColor={theme.colours.mineShaftGray}
                  tooltipDirection="bottom"
                  className={isFullScreen(Document) ? "playbackMinimizeScreen" : "playbackFullScreen"}
                />
                {hideListChannel !== true
                  ? !isFullScreen(Document) && (
                      <Button
                        buttonStyles="list-button show-side-panel"
                        label={
                          isOnDemandType
                            ? showListButton
                              ? translate(isRecording ? "recorded_episodes" : "episode_list")
                              : translate("details")
                            : translate("live_guide")
                        }
                        onClickHandler={() => handleListIconClick(telusConvivaAnalytics.onSwitchProgram)}
                        tabIndex="-1"
                      />
                    )
                  : null}
              </div>

              <div className="dis-flex">
                <VideoPlayerButtonComponent
                  src={process.env.PUBLIC_URL + "/images/playback-close.svg"}
                  alt={translate("close")}
                  onClickHandler={closeClickHandler}
                  $hoverBackgroundColor={theme.colours.mineShaftGray}
                  tooltipDirection="bottom"
                />
              </div>
            </div>
            <div className="playbackControl-metaData dis-flex">
              <div className="metadata">
                <div
                  className={
                    image ? "info dis-flex align-item-start justify-item-start" : "info dis-flex justify-item-start"
                  }
                >
                  <div className="channel-info">
                    {channelNumber ? <div className="live-channel-number">{channelNumber}</div> : null}
                    {type === LIVE ? (
                      <img
                        src={image + "?w=100"}
                        alt="Channel Logo"
                        onError={(e) => handleImageError(e, defaultChannelLogoIcon)}
                      />
                    ) : null}
                  </div>
                  <div className="details">
                    {title && title !== "" ? <span className="title"> {title} </span> : null}
                    {subTitle && <span className="description"> {subTitle} </span>}
                    {type === LIVE && liveProgramDetails && <div className="description">{getEpisodeInfo()}</div>}
                  </div>
                  <div className="dis-flex align-right pos-rel">
                    <div
                      ref={volumeRef}
                      className="player-popup-container"
                      onMouseEnter={() => {
                        if (userHasClick) volumeButtonHoverHandler();
                      }}
                      onMouseLeave={() => {
                        volumeVisibility && setVolumeVisibility(false);
                      }}
                    >
                      <VideoPlayerButtonComponent
                        src={
                          isMuted
                            ? process.env.PUBLIC_URL + "/images/volume-mute.svg"
                            : volumeLevel && volumeLevel >= 50
                            ? process.env.PUBLIC_URL + "/images/volume-high.svg"
                            : process.env.PUBLIC_URL + "/images/volume-low.svg"
                        }
                        alt={translate("volume")}
                        className="volumeButton"
                        ref={volumeButtonRef}
                        onClickHandler={volumeButtonHandler}
                        $hoverBackgroundColor={theme.colours.mineShaftGray}
                      />
                      {volumeVisibility ? (
                        <div className="volume-slider-container">
                          <VolumeSlideBar
                            playerMgr={playerMgr.current}
                            audioLevel={volumeLevel}
                            onChange={onVolumeSlideChange}
                            isMuted={isMuted}
                            ref={volumeBarRef}
                          ></VolumeSlideBar>
                        </div>
                      ) : null}
                    </div>
                    <div ref={settingsRef} className="player-popup-container">
                      {isCCAvailable || isADAvailable ? (
                        <VideoPlayerButtonComponent
                          src={process.env.PUBLIC_URL + "/images/gear-icon.svg"}
                          alt={translate("settings")}
                          onClickHandler={settingButtonHandler}
                          disabled={spinnerToggle}
                          $hoverBackgroundColor={theme.colours.mineShaftGray}
                        />
                      ) : null}
                      {settingsDropdownVisibility ? (
                        <VideoSettings
                          closeCaption={isClosedCaptionEnabled.current}
                          onCloseCaptionChange={onSubTitleChange}
                          onAudioDescVideoChange={onADToggleChange}
                          audioDescribeVideo={isDescribedVideoEnabled.current}
                          playerMgr={playerMgr.current}
                          isADEnabled={isADAvailable}
                          isCCAvailable={isCCAvailable}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="progressBar-wrap">
                  <ProgressBar
                    progress={isOnDemandType || checkIfCatchUpAllowed() ? progressStyle : liveProgress()}
                    bufferProgress={
                      isTimeShiftAllowed.current && !isLookbackStream && showLiveProgress.current
                        ? liveBufferProgress()
                        : 0
                    }
                    onProgressClick={setProgress}
                    disabled={spinnerToggle}
                    playerMgr={playerMgr.current}
                    playerType={type}
                    elapsedTime={
                      !Number.isNaN(recordingAssetElapsedTime ? recordingAssetElapsedTime : elapsedTime) &&
                      (isOnDemandType || checkIfCatchUpAllowed())
                        ? recordingAssetElapsedTime
                          ? recordingAssetElapsedTime
                          : elapsedTime
                        : ""
                    }
                    duration={duration}
                    leftMargin={leftMargin.current}
                    showLiveProgress={showLiveProgress.current}
                    currentPlaybackTime={currentPlaybackTime}
                    endTime={endTime}
                    startTime={startTime}
                    showToastNotification={showToastNotification}
                    updateLandTime={updateLandTime}
                    isTimeShiftAllowed={isTimeShiftAllowed.current}
                    pauseLiveProperties={pauseLiveProperties}
                    isOnDemandType={isOnDemandType}
                    checkIfTimeShiftAllowed={checkIfTimeShiftAllowed}
                    isRestartedStream={isRestartedStream}
                    isLookbackStream={isLookbackStream}
                    isTimeShifting={isTimeShifting.current}
                    isSeeking={isSeeking.current}
                    isRestartTrickPlayAllowed={isRestartTrickPlayAllowed.current}
                    isPlayerControlsDisable={isPlayerControlsDisable}
                    startTimeEpoch={startTimeEpoch}
                    recordingAssetRemainingTime={recordingAssetRemainingTime}
                    remainingTime={remainingTime}
                    userRecordingStopTime={userRecordingStopTime}
                    isRecording={isRecording}
                  />
                </div>
              </div>
            </div>
            <div className="playbackControl-bottom dis-flex">
              <React.Fragment>
                <div className="dis-flex">
                  {shouldDisplayRewindButton() ? (
                    <VideoPlayerButtonComponent
                      src={process.env.PUBLIC_URL + "/images/playback-skip-back.svg"}
                      alt={translate("rewind")}
                      className="playbackButton"
                      onClickHandler={rewindButtonHandler}
                      $hoverBackgroundColor={theme.colours.mineShaftGray}
                      disabled={spinnerToggle}
                      tooltipDirection="top"
                    />
                  ) : null}
                  {!isPlayerControlsDisable &&
                  (isOnDemandType || isDynamicRecordingType || checkIfTimeShiftAllowed()) ? (
                    <VideoPlayerButtonComponent
                      src={process.env.PUBLIC_URL + playPauseIcon}
                      alt={playPauseIcon === "/images/playback-play.svg" ? translate("play") : translate("pause")}
                      className="playbackButton"
                      onClickHandler={playPauseButtonHandler}
                      ref={playButtonRef}
                      $hoverBackgroundColor={theme.colours.mineShaftGray}
                      disabled={spinnerToggle}
                      tooltipDirection="top"
                    />
                  ) : null}
                  {shouldDisplayForwardButton() ? (
                    <VideoPlayerButtonComponent
                      src={
                        isVOD
                          ? process.env.PUBLIC_URL + "/images/playback-skip-forward-10.svg"
                          : process.env.PUBLIC_URL + "/images/playback-skip-forward-30.svg"
                      }
                      alt={translate("forward")}
                      className="playbackButton"
                      onClickHandler={forwardButtonHandler}
                      $hoverBackgroundColor={theme.colours.mineShaftGray}
                      disabled={spinnerToggle}
                      tooltipDirection="top"
                    />
                  ) : null}
                </div>
                <div className="dis-flex align-right">
                  {shouldDisplayRestartButton() ? (
                    <VideoPlayerButtonComponent
                      src={process.env.PUBLIC_URL + "/images/Restart.svg"}
                      alt={translate("restart")}
                      className="playbackButton"
                      onClickHandler={() => startOverStream(false)}
                      $hoverBackgroundColor={theme.colours.mineShaftGray}
                      disabled={spinnerToggle}
                      tooltipDirection="top"
                    />
                  ) : null}
                  {!isPlayerControlsDisable &&
                  !isFast &&
                  (playerMgr.current?.isLive() || isRestartedStream) &&
                  type === LIVE &&
                  isNotAtLivePosition() ? (
                    <VideoPlayerButtonComponent
                      src={process.env.PUBLIC_URL + "/images/jump-to-live.svg"}
                      alt={translate("jump_to_live")}
                      className="playbackButton"
                      onClickHandler={jumpToLivePoint}
                      $hoverBackgroundColor={theme.colours.mineShaftGray}
                      disabled={spinnerToggle}
                      tooltipDirection="top"
                    />
                  ) : null}
                  {!isPlayerControlsDisable && getRecordingButton()}
                  {showRecordingCTALoading && (
                    <img
                      src={process.env.PUBLIC_URL + "/images/Rec_Progress_Small.svg"}
                      alt=""
                      className="record-player-loading"
                    />
                  )}
                  {nextProgram ? (
                    <VideoPlayerButtonComponent
                      src={process.env.PUBLIC_URL + "/images/cta-next-ep.svg"}
                      alt={translate("next_episode")}
                      className="playbackButton"
                      onClickHandler={skipToNextEpisode}
                      $hoverBackgroundColor={theme.colours.mineShaftGray}
                      disabled={spinnerToggle}
                      tooltipDirection="top"
                    />
                  ) : null}
                  {showPurchaseCTA ? (
                    <VideoPlayerButtonComponent
                      src={process.env.PUBLIC_URL + "/images/order.svg"}
                      $hoverBackgroundColor={theme.colours.mineShaftGray}
                      alt={translate("order_for_stb").replace(
                        "%s",
                        getFormattedPrice(getPackagePrice(ppvPurchasePackage), appLanguage) ?? ""
                      )}
                      onClickHandler={openPurchaseModal}
                      disabled={spinnerToggle}
                      tooltipDirection="top"
                    />
                  ) : null}
                </div>
              </React.Fragment>
            </div>
          </div>
        )}

        {contentLockedOverlay}

        {showNextEpisodeCard && nextProgram && (
          <div className="next-episode" style={isSideNavOpen ? { visibility: "hidden" } : { visibility: "visible" }}>
            <div
              className="episode-card"
              style={{
                backgroundImage: `url(${nextEpisodePoster}?w=230), url(${placeholder}`,
              }}
            ></div>
            <div className="card-text">
              <div className="label">{translate("next_episode")}</div>
              <div className="show-name">
                {seriesDetails?.title ||
                  nextProgram.metadata?.extendedMetadata?.dlum?.sortTitle ||
                  nextProgram.metadata?.title ||
                  ""}
              </div>
              <div className="episode-info-container">
                <div className="episode-info">
                  <span>{episodeInfoString()}</span>
                </div>
                {nextProgram.userData?.metadata?.bookmarks?.length > 0 ? (
                  <img src={process.env.PUBLIC_URL + "/images/tick-icon.svg"} alt="Channel Logo" />
                ) : null}
              </div>
            </div>
            <div className="binge-watch-button-container" ref={bingeWatchCard}>
              <button
                className="binge-watch-buttons timer-bg"
                style={{ animation: `timer ${bingeWatchTimer}s` }}
                onClick={handleEpisodeCardClick}
              >
                {translate("play")}
              </button>
              <button className="binge-watch-buttons dismiss-bg" onClick={cancelAutoplay}>
                {translate("dismiss")}
              </button>
            </div>
          </div>
        )}
        {showStillWatchingPrompt && (
          <StillWatchingCard
            ref={stillWatchingCard}
            className={`still-watching ${isSideNavOpen ? "shrink" : ""}`}
            onButtonClick={(_) => {
              signalUserActivity();
              trackGenericAction(ANALYTICS_EVENT_TYPES.STILL_WATCHING, getMediaNodeMetaData());
            }}
            onCountdownComplete={() => closeClickHandler(null, true)}
          />
        )}
      </div>
    </>
  );
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  streamInfo: PropTypes.shape({
    streamMode: PropTypes.string,
    manifestURL: PropTypes.string,
    playbackType: PropTypes.string,
    drmType: PropTypes.string,
    LA_URL: PropTypes.string,
    LA_CERT_URL: PropTypes.string,
    authToken: PropTypes.string,
  }),
  metadataInfo: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    image: PropTypes.string,
    duration: PropTypes.number,
    startTime: PropTypes.object,
  }),
  control: PropTypes.string,
  onPlaybackStateChange: PropTypes.func,
  nextProgramInfo: PropTypes.shape({
    nextProgram: PropTypes.object,
    nextEpisodePoster: PropTypes.string,
    loadNextProgram: PropTypes.func,
  }),
  showStillWatchingPrompt: PropTypes.bool,
  restartUserInactivityTimer: PropTypes.func,
  updateVodProgress: PropTypes.func,
  isReadyCallback: PropTypes.func,
  setIsReadyToChangeStreams: PropTypes.func,
  contentLockedOverlay: PropTypes.element,
};

VideoPlayer.defaultProps = {
  className: "",
  nextProgramInfo: {},
  updateVodProgress: () => null,
};

const mapDispatchToProps = {
  showModalPopup,
  toggleSpinningLoaderAction,
  showToastNotification,
  setVideoPlaying,
};

export default connect(null, mapDispatchToProps)(VideoPlayer);

/**
 * Helper Method that sets the video players playback control state
 * @param Object Bitmovin Instance
 * @param String Playback control state
 */
function setVideoPlayerControlState(homeVideoPlayer, controlState) {
  if (homeVideoPlayer && controlState) {
    switch (controlState.toLowerCase()) {
      case PLAYER_CONTROLS.STOP:
        if (homeVideoPlayer && homeVideoPlayer?.pause) {
          homeVideoPlayer.pause();
        }
        break;
      case PLAYER_CONTROLS.PAUSE:
        if (homeVideoPlayer && homeVideoPlayer?.pause) {
          homeVideoPlayer.pause();
        }
        break;
      case PLAYER_CONTROLS.RESUME:
        if (homeVideoPlayer && !homeVideoPlayer.destroyed && homeVideoPlayer?.isPaused()) {
          homeVideoPlayer.play().catch((error) => {
            // This error handler was added to handle the promise rejection from this issue: https://developer.chrome.com/blog/play-request-was-interrupted/
            // TODO: Handle the pause/play and load/play race conditions properly when refactoring the player
            console.error(error);
          });
        }
        break;
      case PLAYER_CONTROLS.CLOSE:
        if (homeVideoPlayer && !homeVideoPlayer.destroyed) {
          homeVideoPlayer.destroy();
        }
        break;
      default:
        break;
    }
  }
}
/**
 * Gets an audio track id. If AD is enabled, return the AD track ID, otherwise default
 * to the first, non-AD track.
 * @param {Array} audioTracks
 * @param {Boolean} ADEnabled
 * @returns {string} audio track ID
 */
const getAudioTrackId = (audioTracks, ADEnabled) => {
  let selectedTrackId = getDefaultAudioTrackId(audioTracks);

  if (audioTracks && ADEnabled) {
    let describedAudioTrack = audioTracks.find((track) => {
      return isEstonianLanguageCode(track.label) || isEstonianLanguageCode(track.lang);
    });

    if (describedAudioTrack) selectedTrackId = describedAudioTrack.id;
  }

  return selectedTrackId;
};

const hasADAudioTrack = (audioTracks) => {
  if (audioTracks) {
    for (let i = 0; i < audioTracks.length; i++) {
      const track = audioTracks[i];
      if (isEstonianLanguageCode(track.label) || isEstonianLanguageCode(track.lang)) {
        return true;
      }
    }
  }
  return false;
};
/**
 * return first available track that is not using the Estonian language code.
 * @param {Array} audioTracks
 */
const getDefaultAudioTrackId = (audioTracks) => {
  let defaultAudioTrackId = "";
  if (audioTracks) {
    const nonADTrack = audioTracks.find((track) => {
      return !isEstonianLanguageCode(track.label) && !isEstonianLanguageCode(track.lang);
    });
    if (nonADTrack) {
      defaultAudioTrackId = nonADTrack.id;
    }
  }
  return defaultAudioTrackId;
};

/**
 * Telus currently uses Estonian to represent described audio tracks
 * @param {String} languageCode
 * @returns {Boolean} true if language code matches Estonian language codes
 */
const isEstonianLanguageCode = (languageCode) => {
  if (languageCode) {
    languageCode = languageCode.toLowerCase();
    return languageCode === "et" || languageCode === "est";
  }

  return false;
};
